<form
  #form="ngForm"
  (focusout)="!form.invalid && onChangeResult()"
  *ngIf="result.fieldValue"
  class="detail-result-number-field-value">
  <form-control
    *ngIf="result.fieldValue.inspectionElementFieldType === fieldTypes.Number"
    invalidKey="InvalidNumber">
    <input
      name="numberValue"
      [(ngModel)]="result.fieldValue.numberValue"
      type="number"
      [disabled]="readonly" />
    <app-detail-result-remark
      *ngIf="displayRemark"
      class="btn-ellipsis-link-after-dropdown"
      [result]="result"
      (remarkChange)="onChangeRemark()"
      [readonly]="readonly"></app-detail-result-remark>
  </form-control>
</form>
