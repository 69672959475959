import { Injectable } from '@angular/core';
import { InspectionDBService } from '../db/inspection-db-service';
import { InspectionCategoryDBService } from '../db/inspection-category-db-service';
import { forkJoin, Observable } from 'rxjs';
import { InspectionStyleDBService } from '../db/inspection-style-db-service';
import { InspectionStatusDBService } from '../db/inspection-status-db-service';
import {
  InspectionService,
  InspectionCategoryService,
  InspectionStyleService,
  InspectionStatusService,
} from '../api';
import { InspectionElementDto } from '../api/model/inspectionElementDto';
import { InspectionStatusDto } from '../api/model/inspectionStatusDto';
import { InspectionStyleDto } from '../api/model/inspectionStyleDto';
import { InspectionWithResultsDto, UpdateInspectionWithResultsDto } from '../api/model/models';
import { concatMap, mergeMap } from 'rxjs/operators';
import { InspectionStatus } from '../api/model/inspectionStatus';

@Injectable()
export class SyncService {
  public uploadOkWithoutDelete = 'OK_NODELETE';
  public uploadOkWithDelete = 'OK_DELETE';

  public constructor(
    private inspectionService: InspectionService,
    private inspectionCategoryService: InspectionCategoryService,
    private inspectionStyleService: InspectionStyleService,
    private inspectionStatusService: InspectionStatusService,
    private inspectionDBService: InspectionDBService,
    private inspectionCategoryDBService: InspectionCategoryDBService,
    private inspectionStyleDBService: InspectionStyleDBService,
    private inspectionStatusDBService: InspectionStatusDBService
  ) {}

  /**
   * Reads the following data from intermediary and saves them into the local database:
   * - inspections with results
   * - related distinct categories (inspection elements)
   * - inspection styles
   * - inspection states
   *
   * @param inspectionKeys Keys of inspections.
   * @returns an observable which completes when sync is completed.
   */
  public syncDown(inspectionKeys: string[]): Observable<string> {
    return new Observable((observer: any) => {
      const apiCallObservables = inspectionKeys.map((inspectionKey: string) =>
        this.inspectionService.getInspectionWithResults(inspectionKey)
      );
      let inspectionsWithResultsDto;
      forkJoin(apiCallObservables)
        .pipe(
          mergeMap((inspections: InspectionWithResultsDto[]) => {
            inspectionsWithResultsDto = inspections;
            const dbCallObservables = inspections.map((inspection: InspectionWithResultsDto) =>
              this.inspectionDBService.putInspectionWithResults(inspection)
            );
            return forkJoin(dbCallObservables);
          }),
          mergeMap((success: string[]) => {
            console.log(success);
            return this.syncDownCategories(inspectionsWithResultsDto);
          }),
          mergeMap((successSyncDownCategories: string[]) => {
            console.log(successSyncDownCategories);
            return this.syncDownEnums();
          })
        )
        .subscribe(
          (successSyncDownEnums: string) => {
            console.log(successSyncDownEnums);
            observer.next('Finished downsync.');
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  /**
   * Uploads the inspection to the intermediary.
   * If the status was updated to "Entered", the inspection is delete from the local database.
   */
  public syncUp(inspection: UpdateInspectionWithResultsDto): Observable<string> {
    return new Observable((observer: any) => {
      this.inspectionService
        .updateInspectionWithResults(inspection.inspectionKey, inspection)
        .pipe(
          concatMap(() => {
            if (inspection.inspectionStatus === InspectionStatus.Entered) {
              return this.inspectionDBService.deleteInspectionWithRelatedData(
                inspection.inspectionKey
              );
            }
            observer.next(this.uploadOkWithoutDelete);
            observer.complete();
          })
        )
        .subscribe(
          () => {
            observer.next(this.uploadOkWithDelete);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  private syncDownCategories(inspections: InspectionWithResultsDto[]): Observable<string[]> {
    return new Observable((observer: any) => {
      const distinctCategoryNumbers = this.getDistinctCategoryNumbers(inspections);
      const apiCallObservables = distinctCategoryNumbers.map((categoryNumber: string) =>
        this.inspectionCategoryService.getInspectionCategory(categoryNumber)
      );
      forkJoin(apiCallObservables)
        .pipe(
          mergeMap((categories: InspectionElementDto[]) => {
            const dbCallObservables = categories.map((category: InspectionElementDto) =>
              this.inspectionCategoryDBService.putInspectionCategory(category)
            );
            return forkJoin(dbCallObservables);
          })
        )
        .subscribe(
          (success: string[]) => {
            observer.next(success);
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  private syncDownEnums(): Observable<string> {
    return new Observable((observer: any) => {
      this.syncDownInspectionStyles()
        .pipe(
          concatMap((successSyncDownInspectionStyles: string) => {
            console.log(successSyncDownInspectionStyles);
            return this.syncDownInspectionStates();
          })
        )
        .subscribe(
          (successSyncDownInspectionStates: string) => {
            console.log(successSyncDownInspectionStates);
            observer.next('Finished downsync of all enums.');
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  private syncDownInspectionStyles(): Observable<string> {
    return new Observable((observer: any) => {
      this.inspectionStyleService
        .getInspectionStyles()
        .pipe(
          concatMap((inspectionStyles: InspectionStyleDto[]) =>
            this.inspectionStyleDBService.putInspectionStyles(inspectionStyles)
          )
        )
        .subscribe(
          (success: string) => {
            observer.next(success);
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  private syncDownInspectionStates(): Observable<string> {
    return new Observable((observer: any) => {
      this.inspectionStatusService
        .getInspectionStates()
        .pipe(
          concatMap((inspectionStates: InspectionStatusDto[]) =>
            this.inspectionStatusDBService.putInspectionStates(inspectionStates)
          )
        )
        .subscribe(
          (success: string) => {
            observer.next(success);
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  private getDistinctCategoryNumbers(inspections: InspectionWithResultsDto[]): string[] {
    const categoryNumbers: Set<string> = new Set<string>();
    for (const inspection of inspections) {
      if (inspection.categoryResults) {
        for (const categoryResult of inspection.categoryResults) {
          categoryNumbers.add(categoryResult.categoryNumber);
        }
      }
    }
    return Array.from(categoryNumbers.values());
  }
}
