import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class DefectTableParams {
  public deadlineText: string;
  public includeHeaderDefect = true;
  public defectCorrectionTableHeaderCorrected = 'ProtocolPdf.DefectCorrectionTableHeaderCorrected';
  public labelRemarks: string[] = ['ProtocolPdf.LabelDefectCorrectionRemarks'];
  public maxLinesRemarks = -1;
  public addCheckboxPhotos = false;
  public addControlledArea = false;
  public defectCorrectionOnOddNumberedPage = false;
  public tvdIdOnHeader = false;
  public ktIdOnHeader = false;
  public defectTableRubricsFontType = ProtocolPdfConstants.boldFontType;
  public defectTableContentFontType = ProtocolPdfConstants.defaultFontType;
  public defectCorrectionTitleFontSize = ProtocolPdfConstants.defaultFontSize;
  public defaultTablesContentFontSize = ProtocolPdfConstants.defaultFontSize;
  public extraMarginColumnDeadline = 0;
  public showUnitNameOnFooter = false;
  public showStatementTitle = false;
  public confirmationDefectCorrectionWithDate = false;
  public inspectionDetailsOnHeader = true;
  public inspectionDateOnHeader = true;
  public useAcroFormsOnProtocol = false;
}
