import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileDBService } from '../core/db/file-db-service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, from } from 'rxjs';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import * as _ from 'underscore';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss'],
})
export class FileDialogComponent implements OnInit {
  @Input()
  public inspectionKey: string;

  @ViewChild('fileInput', { static: true })
  public fileInputElement: ElementRef;

  public fileNames: string[];
  public alertMessage: string;
  public showAlert: boolean;
  public isSavingDocuments = false;

  private fileListTrigger$: Subject<any> = new Subject<any>();

  public constructor(
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private fileDBService: FileDBService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.fileListTrigger$.subscribe(() => {
      this.fileDBService.getFileNames(this.inspectionKey).subscribe(
        (fileNames: string[]) => {
          this.fileNames = _.sortBy(fileNames, (fileName: string) => fileName.toLowerCase());
        },
        (err: any) => {
          console.log(err);
          this.alertMessage = this.translateService.instant(
            'Die Dokumente konnten nicht geladen werden.'
          );
          this.showAlert = true;
        }
      );
    });
    this.fileListTrigger$.next(null);
  }

  public onFilesSelected(): void {
    this.isSavingDocuments = true;
    // Serially write to db (avoid update conflicts)
    const dbCallObservables = from(this.getSelectedFiles()).pipe(
      concatMap((file) => this.fileDBService.putFile(file, this.inspectionKey))
    );

    dbCallObservables.subscribe(
      (success: string) => {
        console.log(success);
        this.fileListTrigger$.next(null);
      },
      (err: any) => {
        console.log(err);
        this.alertMessage = this.translateService.instant(
          'Das Dokument konnte nicht hinzugefügt werden.'
        );
        this.showAlert = true;
      },
      () => {
        this.isSavingDocuments = false;
      }
    );
  }

  public openFile(fileName: string) {
    this.fileDBService.getFile(fileName, this.inspectionKey).subscribe(
      (file: File) => {
        const element = document.createElement('a');
        element.setAttribute('href', URL.createObjectURL(file));
        element.setAttribute('download', fileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      },
      (err: any) => {
        console.log(err);
        this.alertMessage = this.translateService.instant(
          'Das Dokument konnte nicht geöffnet werden.'
        );
        this.showAlert = true;
      }
    );
  }

  public openDeleteConfirmation(fileName: string): void {
    const modal = this.modalService.open(ConfirmDeleteDialogComponent, {
      size: 'md',
      centered: true,
    });
    modal.componentInstance.title = this.translateService.instant('Entfernen bestätigen.');
    modal.componentInstance.message = this.translateService.instant(
      `Wollen Sie das Dokument entfernen?`
    );
    modal.result.then(
      () => this.deleteFile(fileName),
      () => {}
    );
  }

  private getSelectedFiles(): File[] {
    const selectedFiles = new Array<File>();
    const fileInputElementContents: { [key: string]: File } =
      this.fileInputElement.nativeElement.files;
    for (const key in fileInputElementContents) {
      if (!isNaN(parseInt(key, 10))) {
        selectedFiles.push(fileInputElementContents[key]);
      }
    }
    return selectedFiles;
  }

  private deleteFile(fileName: string): void {
    this.fileDBService.deleteFile(fileName, this.inspectionKey).subscribe(
      (success: string) => {
        console.log(success);
        this.fileListTrigger$.next(null);
      },
      (err: any) => {
        console.log(err);
        this.alertMessage = this.translateService.instant(
          'Das Dokument konnte nicht entfernt werden.'
        );
        this.showAlert = true;
      }
    );
  }
}
