import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

/**
 * Since NgbDatepicker only comes with an ISO 8601 formatter, which our users probably would not like,
 * we have to write one ourselves ...
 */
@Injectable()
export class BedagDateParserFormatter extends NgbDateParserFormatter {
  public parse(value: string): NgbDateStruct {
    const dotOrWhitespace = /\.|\s/;
    const tokens = value.split(dotOrWhitespace);
    // the contract asks us to do our best to parse partial dates
    // by reversing, we ensure that the most optional part is last in the array
    // so tokens[2] will return undefined and be converted to null
    tokens.reverse();
    return {
      day: this.toInt(tokens[2] != null ? tokens[2] : null),
      month: this.toInt(tokens[1] != null ? tokens[1] : null),
      year: this.toYear(tokens[0]),
    };
  }

  public format(date?: NgbDateStruct): string {
    return date
      ? [date.day, date.month, date.year]
          .filter((n) => n)
          .map(this.leftPad)
          .join('.')
      : '';
  }

  private toInt(s?: string) {
    return s != null ? parseInt(s, 10) : null;
  }

  private toYear(s?: string) {
    const y = this.toInt(s);
    if (!y) {
      return new Date().getFullYear();
    } else if (y < 100) {
      return y < 50 ? 2000 + y : 1900 + y;
    } else {
      return y;
    }
  }

  // no, we aren't going to import an npm package for this ;-)
  private leftPad(n: number) {
    return n < 10 ? '0' + n : '' + n;
  }
}
