import { Injectable } from '@angular/core';
import { I18nTextPipe } from '../core/pipes/i18n-text.pipe';
import { I18nTextListPipe } from '../core/pipes/i18n-text-list.pipe';
import { InspectionListItemDto } from '../dtos/inspectionListItemDto';
import * as _ from 'underscore';

@Injectable({ providedIn: 'root' })
export class InspectionListSorter {
  public constructor(
    private i18nTextPipe: I18nTextPipe,
    private i18nTextListPipe: I18nTextListPipe
  ) {}

  public sort(inspections: InspectionListItemDto[], fieldName: string): InspectionListItemDto[] {
    let sortedInspections: InspectionListItemDto[];
    switch (fieldName) {
      case 'name':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.name ? this.replaceSpecialCharacters(inspection.name.toLowerCase()) : ''
        );
        break;
      case 'address':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.address ? this.replaceSpecialCharacters(inspection.address.toLowerCase()) : ''
        );
        break;
      case 'town':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.town ? this.replaceSpecialCharacters(inspection.town.toLowerCase()) : ''
        );
        break;
      case 'postalZone':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.postalZone
            ? this.replaceSpecialCharacters(inspection.postalZone.toLowerCase())
            : ''
        );
        break;
      case 'cantonId':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.cantonId
            ? this.replaceSpecialCharacters(inspection.cantonId.toLowerCase())
            : ''
        );
        break;
      case 'inspectionType':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) => {
          if (inspection.inspectionType) {
            const type = this.i18nTextPipe.transform(inspection.inspectionType);
            return type ? this.replaceSpecialCharacters(type.toLowerCase()) : '';
          } else {
            const type = this.i18nTextListPipe.transform(inspection.categoryShortNames);
            return type ? this.replaceSpecialCharacters(type.toLowerCase()) : '';
          }
        });
        break;
      case 'inspectionStatus':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) => {
          const status = this.i18nTextPipe.transform(inspection.inspectionStatus);
          return status ? this.replaceSpecialCharacters(status.toLowerCase()) : '';
        });
        break;
      case 'inspectorName':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.inspectorName
            ? this.replaceSpecialCharacters(inspection.inspectorName.toLowerCase())
            : ''
        );
        break;
      case 'burNumber':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.burNumber
            ? this.replaceSpecialCharacters(inspection.burNumber.toLowerCase())
            : ''
        );
        break;
      case 'tvdNumber':
        sortedInspections = _.sortBy(inspections, (inspection: InspectionListItemDto) =>
          inspection.tvdNumber
            ? this.replaceSpecialCharacters(inspection.tvdNumber.toLowerCase())
            : ''
        );
        break;
      default:
        sortedInspections = _.sortBy(inspections, fieldName);
        break;
    }

    return sortedInspections;
  }

  private replaceSpecialCharacters(value: string): string {
    let newValue: string = value;
    if (newValue) {
      newValue = newValue.replace(/[à|ä|â|á]/gi, 'a');
      newValue = newValue.replace(/[é|è|ê|ë]/gi, 'e');
      newValue = newValue.replace(/[ï|î|ì|í]/gi, 'i');
      newValue = newValue.replace(/ñ/gi, 'n');
      newValue = newValue.replace(/[ö|ò|ó|ô]/gi, 'o');
      newValue = newValue.replace(/[ü|û|ù|ú]/gi, 'u');
    }
    return newValue;
  }
}
