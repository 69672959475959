import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable } from 'rxjs';
import { InspectionStyleDto } from '../api/model/models';

@Injectable()
export class InspectionStyleDBService {
  private readonly idInspectionStyles = 'inspection_styles';

  public constructor(private dbService: DBService) {}

  public getInspectionStyles(): Observable<InspectionStyleDto[]> {
    return this.dbService.getById<InspectionStyleDto[]>(this.idInspectionStyles);
  }

  public putInspectionStyles(inspectionStyles: InspectionStyleDto[]): Observable<string> {
    return this.dbService.put<InspectionStyleDto[]>(inspectionStyles, this.idInspectionStyles);
  }
}
