import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import {
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormLayout } from './form-layout';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessageService } from './validation-message.service';
import { FormRowDirective } from './form-row.directive';
import { RxjsSubscriber } from './rxjs-subscriber';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

/**
 * This components decorates the enclosed form control as a bootstrap form control, adding labels, help texts,
 * and validation messages in the style of the enclosing FormLayout.
 *
 * Usage example:
 * <pre>
 * <form-control labelKey="Subject" helpKey="A brief summary of your issue" invalidKey="A subject is required">
 *   <input name="subject" [(ngModel)]="issue.subject" type="text" required>
 * </form-control>
 * </pre>
 *
 * The projected content may contain additional markup, such as a <div class="input-group-text">.
 *
 * Supports input, select, textarea, bsg-datepicker, and custom components. Each form-control must contain exactly one
 * such component. Other controls can coexist in the same form, but you'll have to decorate them manually.
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'form-control',
  host: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '[class]': 'groupClasses',
  },
  template: `
    <ng-container formControlViewContainer></ng-container>
    <ng-template formControlSubTemplate>
      <div [class]="labelWrapperClasses" [class.visually-hidden]="!labelKey" *ngIf="!checkboxLabel">
        <label
          formControlLabel
          class="bsg-label"
          [ngClass]="labelClasses"
          [class.is-invalid]="invalid"
          [class.is-read-only]="readonly && labelReadonly">
          {{ labelKey || placeholderKey | translate }}
        </label>
      </div>
      <div
        [class]="controlWrapperClasses"
        [ngbPopover]="popoverTemplate"
        placement="bottom"
        #popover="ngbPopover"
        container="body"
        triggers="manual"
        (focusin)="active = true"
        (focusout)="active = false"
        (mouseenter)="active = true"
        (mouseleave)="active = false">
        <div class="form-control form-control-checkbox mt-2" *ngIf="checkboxLabel">
          <ng-container *ngTemplateOutlet="input"></ng-container>
          <label formControlLabel class="form-label form-control-label">{{
            labelKey | translate
          }}</label>
        </div>
        <div class="input-group" *ngIf="!checkboxLabel">
          <ng-container *ngTemplateOutlet="input"></ng-container>
          <ng-content select=".input-group-text"></ng-content>
          <div class="input-group-text" *ngIf="invalid">
            <div class="input-group-text invalid-icon">
              <span class="fas fa-exclamation-circle"></span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #popoverTemplate>
      <ul class="popover-list">
        <li formControlHelp *ngIf="helpKey">{{ helpKey | translate }}</li>
        <ng-container *ngIf="invalid">
          <li *ngIf="helpKey">
            <hr class="my-1" />
          </li>
          <li class="text-danger fw-bold" *ngFor="let m of invalidMessages">
            <span class="fas fa-exclamation-circle fa-fw me-2"></span>{{ m }}
          </li>
        </ng-container>
      </ul>
    </ng-template>
    <ng-template #input>
      <!--
        for some arcane reason, only the first occurrence of ng-content projects content,
        meaning ng-content still consumes the content inside an *ngIf="false".
        By wrapping it into a template, we ensure there is only a single ng-content,
        that we can splice it into the appropriate wrapper element.
        See also https://stackoverflow.com/questions/41593973/how-to-conditionally-wrap-a-div-around-ng-content
      -->
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class FormControlComponent extends RxjsSubscriber implements OnInit, AfterViewInit, DoCheck {
  @ContentChild(NgControl, { read: ElementRef, static: true })
  public inputRefFromContent: ElementRef;
  @ContentChild(NgControl, { static: true }) public control: NgControl;
  @ViewChild('popover', { static: true }) public popover: NgbPopover;

  @Input() public labelReadonly = true;
  @Input() public displayAsCheckboxLabel = true;

  @Input() public extraLabelWrapperClasses: string;
  @Input() public labelKey: string;
  // translation key for a placeholder. Will be accessible even if the screen reader does not support placeholders.
  @Input() public placeholderKey: string;
  @Input() public helpKey: string;
  /** Translation key to override the default validation messages */
  @Input() public invalidKey: string;

  public inputRefOverride: ElementRef;

  /* the view container we should render to */
  public viewContainerRef: ViewContainerRef;
  public label: HTMLLabelElement;
  public help: HTMLElement;
  public invalid$ = new EventEmitter<boolean>();
  public readonly$ = new EventEmitter<boolean>();
  /** whether the user is currently interacting with this form control */
  public active = false;
  public supressPopover = false;
  public checkboxLabel = false;
  public labelClasses: string;
  public groupClasses: string;

  private labelWrapperClasses: string;
  private controlWrapperClasses: string;
  private inputClasses: string[];

  public constructor(
    private layout: FormLayout,
    viewContainerRef: ViewContainerRef,
    private validationMessageService: ValidationMessageService,
    private translate: TranslateService
  ) {
    super();
    if (layout instanceof FormRowDirective) {
      // render to siblings of <form-component>
      // so cols are immediate children of rows
      // as needed by bootstrap (flexbox)
      this.viewContainerRef = viewContainerRef;
    } else {
      // render into <form-component>
    }
  }

  public get invalid() {
    return !!(this.control && this.control.touched && this.control.errors);
  }

  public get readonly() {
    return this.inputRef.nativeElement.disabled;
  }

  public get invalidMessages() {
    const invalidKey = this.invalidKey;
    if (invalidKey) {
      return [this.translate.instant(invalidKey)];
    } else {
      const labelKey = this.labelKey || this.placeholderKey;
      const label = this.translate.instant(labelKey);
      return this.validationMessageService.messagesFor(label, this.control.errors);
    }
  }

  private get inputRef() {
    return this.inputRefFromContent || this.inputRefOverride;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('column-classes')
  public set columnClasses(s: string) {
    const tokens = s.split(/,/);
    this.labelWrapperClasses = tokens[0] + ' bsg-label-wrapper';
    this.controlWrapperClasses = (tokens[1] || tokens[0]) + ' bsg-input-wrapper';
  }

  public ngOnInit() {
    // the defaults
    this.groupClasses = this.layout.groupClasses;
    this.labelWrapperClasses = this.labelWrapperClasses || this.layout.labelWrapperClasses;
    this.controlWrapperClasses = this.controlWrapperClasses || this.layout.controlWrapperClasses;
    this.labelClasses = this.layout.labelClasses;
    if (typeof this.extraLabelWrapperClasses !== 'undefined') {
      this.labelWrapperClasses += ' ' + this.extraLabelWrapperClasses;
    }

    if (!this.displayAsCheckboxLabel) {
      this.inputClasses = ['bsg-form-control'];
    } else {
      this.inputClasses = ['form-control', 'bsg-form-control'];
    }

    // ... and the overrides for special cases
    const input = this.inputRef.nativeElement;
    if (input.type === 'checkbox' && this.displayAsCheckboxLabel) {
      this.checkboxLabel = true;
      this.inputClasses = ['form-check-input'];
    } else {
      this.groupClasses += ' bsg-form-group';
    }

    if (input instanceof HTMLTextAreaElement) {
      this.labelClasses += ' h-100';
    }
  }

  public ngAfterViewInit() {
    const label = this.label;
    const input = this.inputRef.nativeElement as HTMLInputElement;
    const help = this.help;

    if (label) {
      if (!input.id && this.control.name != null) {
        input.id = this.control.name as string;
      }
      label.setAttribute('for', input.id);
    }

    if (this.placeholderKey) {
      const key = this.placeholderKey;
      this.translate
        .stream(key)
        .pipe(takeUntil(this.destroyed))
        .subscribe((translated) => {
          input.setAttribute('placeholder', translated);
        });
    }

    if (help) {
      help.id = input.id + 'Help';
      input.setAttribute('aria-describedby', help.id);
    }

    for (const c of this.inputClasses) {
      input.classList.add(c); // we need the loop because IE 11 only accepts a single argument to classList.add
    }
    this.invalid$.pipe(distinctUntilChanged()).subscribe(this.classSetter(input, 'is-invalid'));
    this.readonly$.pipe(distinctUntilChanged()).subscribe(this.classSetter(input, 'is-read-only'));
  }

  public ngDoCheck() {
    this.invalid$.emit(this.invalid); // we detect changes manually because control does not publish when it is touched :-(
    this.readonly$.emit(this.readonly); // nor when it becomes read-only ...

    const showPopover = (this.helpKey || this.invalid) && this.active && !this.supressPopover;
    if (this.popover && this.popover.isOpen() !== showPopover) {
      if (showPopover) {
        this.popover.open();
      } else {
        this.popover.close();
      }
    }
  }

  /** invoke this if the child is not found by the content query (for instance because it is in a nested component) */
  public registerChild(inputRef: ElementRef, control?: NgControl) {
    this.inputRefOverride = inputRef;
    if (control) {
      this.control = control;
    }
  }

  public d() {}

  /**
   * A subscriber function to toggle a CSS class on an element.
   */
  private classSetter(input: HTMLElement, className: string) {
    return (value) => {
      if (value) {
        input.classList.add(className);
      } else {
        input.classList.remove(className);
      }
    };
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlViewContainer]',
})
export class FormControlViewContainerDirective {
  public constructor(c: FormControlComponent, viewContainerRef: ViewContainerRef) {
    c.viewContainerRef = c.viewContainerRef || viewContainerRef;
  }
}

// we can't use ViewChild, because that gets resolved after view creation,
// which causes an ExpressionChangedAfterItHasBeenCheckedError when we instantiate the template in FormRowDirective
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlSubTemplate]',
})
export class FormControlSubTemplateDirective {
  public constructor(c: FormControlComponent, templateRef: TemplateRef<void>) {
    c.viewContainerRef.createEmbeddedView(templateRef);
  }
}

// @ViewChild doesn't see into child templates ... we therefore use these directives to identify the nodes of interest
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlLabel]',
})
export class FormControlLabelDirective {
  public constructor(c: FormControlComponent, elementRef: ElementRef) {
    c.label = elementRef.nativeElement;
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlHelp]',
})
export class FormControlHelpDirective {
  public constructor(c: FormControlComponent, elementRef: ElementRef) {
    c.help = elementRef.nativeElement;
  }
}
