import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { InspectionResultFieldValueDto } from '../core/api/model/inspectionResultFieldValueDto';
import { InspectionElementFieldType } from '../core/api/model/inspectionElementFieldType';

@Component({
  selector: 'app-detail-result-field-value',
  templateUrl: './detail-result-field-value.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailResultFieldValueComponent implements OnInit {
  @Input()
  public result: FlatInspectionResultDto;

  @Input()
  public readonly = false;

  @Output()
  public resultChange: EventEmitter<any> = new EventEmitter();

  public modal: NgbActiveModal;

  public fieldTypes = InspectionElementFieldType;

  private previousFieldValue: InspectionResultFieldValueDto;

  public constructor(private modalService: NgbModal) {}

  public openDialog(content): void {
    this.modal = this.modalService.open(content, {
      size: 'sm',
      backdrop: 'static',
      centered: true,
    });
  }

  public saveResult(): void {
    let hasFieldValueChanged = false;
    const newPointResult = this.result.fieldValue;
    switch (newPointResult.inspectionElementFieldType) {
      case 'date':
        if (newPointResult.dateValue !== this.previousFieldValue.dateValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.dateValue = newPointResult.dateValue;
        }
        break;
      case 'number':
        if (newPointResult.numberValue !== this.previousFieldValue.numberValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.numberValue = newPointResult.numberValue;
        }
        break;
      case 'text':
        if (newPointResult.textValue !== this.previousFieldValue.textValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.textValue = newPointResult.textValue;
        }
        break;
      case 'yesNo':
        if (newPointResult.yesNoValue !== this.previousFieldValue.yesNoValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.yesNoValue = newPointResult.yesNoValue;
        }
        break;
    }

    if (hasFieldValueChanged) {
      this.resultChange.emit();
    }
  }

  public hasFieldValue(): boolean {
    return (
      this.result.fieldValue.dateValue != null ||
      this.result.fieldValue.numberValue != null ||
      (this.result.fieldValue.textValue != null && this.result.fieldValue.textValue !== '') ||
      this.result.fieldValue.yesNoValue != null
    );
  }

  public ngOnInit(): void {
    if (this.result.fieldValue) {
      const fieldValue = this.result.fieldValue;
      this.previousFieldValue = {
        dateValue: fieldValue.dateValue,
        inspectionElementFieldType: fieldValue.inspectionElementFieldType,
        numberValue: fieldValue.numberValue,
        textValue: fieldValue.textValue,
        yesNoValue: fieldValue.yesNoValue,
      };
    }
  }
}
