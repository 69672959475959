import { InspectionWithResultsDto } from '@core/api';
import { ProtocolPdfFrBaseService } from './protocol-pdf-fr-base.service';
import { ProtocolDto } from 'src/app/dtos/protocolDto';

export class ProtocolPdfFrViehhandelService extends ProtocolPdfFrBaseService {
  protected getInspectionHeaderSecondColumnTexts(
    inspection: InspectionWithResultsDto,
    extendedVersion: boolean,
    protocol: ProtocolDto
  ): Map<string, string> {
    const columnTexts = new Map<string, string>();
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelKTID'),
      inspection.unitCantonId
    );
    if (extendedVersion) {
      columnTexts.set(
        this.translateService.instant('ProtocolPdf.LabelPhone'),
        this.getInspectionHeaderPhone(inspection)
      );
    }
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      this.getInspectionDate(inspection)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelDuration'),
      this.getInspectionDuration(protocol)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelType'),
      this.getInspectionStyleName(inspection)
    );
    return columnTexts;
  }

  protected addAccredationLogo() {
    //skip the accredation logo
  }

  protected getHeaderAddressTo(): string {
    return 'ProtocolPdf.HeaderFr3';
  }

  protected getTextOrderLSVW(): string {
    return 'ProtocolPdf.TextOrderLSVW2';
  }

  protected addFurtherProcedureDetails(baseY: number): number {
    return baseY;
  }
}
