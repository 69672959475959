/**
 * MKDE Intermediary API
 * Application internal API, providing resources for the frontend.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InspectionElementFieldType = 'number' | 'text' | 'date' | 'yesNo';

export const InspectionElementFieldType = {
    Number: 'number' as InspectionElementFieldType,
    Text: 'text' as InspectionElementFieldType,
    Date: 'date' as InspectionElementFieldType,
    YesNo: 'yesNo' as InspectionElementFieldType
};

