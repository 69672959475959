import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable } from 'rxjs';
import {
  InspectionTypeConfig,
  CategoryConfig,
  PointToCategoryMappingConfig,
  PointToPointGroupMappingConfig,
  PointConfig,
  ClientConfig,
} from '../api/model/models';

@Injectable()
export class ClientConfigDBService {
  private readonly idClientConfigInspectionType = 'clientconfig_inspectiontype';
  private readonly idClientConfigCategory = 'clientconfig_category';
  private readonly idClientConfigPointToCategoryMapping = 'clientconfig_pointtocategorymapping';
  private readonly idClientConfigPointToPointGroupMapping = 'clientconfig_pointtopointgroupmapping';
  private readonly idClientConfigPoint = 'clientconfig_point';
  private readonly idClientConfigClient = 'clientconfig_client';

  public constructor(private dbService: DBService) {}

  public getInspectionTypeConfig(): Observable<InspectionTypeConfig> {
    return this.dbService.getById<InspectionTypeConfig>(this.idClientConfigInspectionType);
  }

  public putInspectionTypeConfig(inspectionTypeConfig: InspectionTypeConfig): Observable<string> {
    return this.dbService.put<InspectionTypeConfig>(
      inspectionTypeConfig,
      this.idClientConfigInspectionType
    );
  }

  public getCategoryConfig(): Observable<CategoryConfig> {
    return this.dbService.getById<CategoryConfig>(this.idClientConfigCategory);
  }

  public putCategoryConfig(categoryConfig: CategoryConfig): Observable<string> {
    return this.dbService.put<CategoryConfig>(categoryConfig, this.idClientConfigCategory);
  }

  public getPointToCategoryMappingConfig(): Observable<PointToCategoryMappingConfig> {
    return this.dbService.getById<PointToCategoryMappingConfig>(
      this.idClientConfigPointToCategoryMapping
    );
  }

  public putPointToCategoryMappingConfig(
    pointToCategoryMappingConfig: PointToCategoryMappingConfig
  ): Observable<string> {
    return this.dbService.put<PointToCategoryMappingConfig>(
      pointToCategoryMappingConfig,
      this.idClientConfigPointToCategoryMapping
    );
  }

  public getPointToPointGroupMappingConfig(): Observable<PointToPointGroupMappingConfig> {
    return this.dbService.getById<PointToPointGroupMappingConfig>(
      this.idClientConfigPointToPointGroupMapping
    );
  }

  public putPointToPointGroupMappingConfig(
    pointToPointGroupMappingConfig: PointToPointGroupMappingConfig
  ): Observable<string> {
    return this.dbService.put<PointToPointGroupMappingConfig>(
      pointToPointGroupMappingConfig,
      this.idClientConfigPointToPointGroupMapping
    );
  }

  public getPointConfig(): Observable<PointConfig> {
    return this.dbService.getById<PointConfig>(this.idClientConfigPoint);
  }

  public putPointConfig(pointConfig: PointConfig): Observable<string> {
    return this.dbService.put<PointConfig>(pointConfig, this.idClientConfigPoint);
  }

  public getClientConfig(): Observable<ClientConfig> {
    return this.dbService.getById<ClientConfig>(this.idClientConfigClient);
  }

  public putClientConfig(clientConfig: ClientConfig): Observable<string> {
    return this.dbService.put<ClientConfig>(clientConfig, this.idClientConfigClient);
  }
}
