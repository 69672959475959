import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { InspectionElementFieldType } from '../core/api/model/inspectionElementFieldType';
import { FlatInspectionResultDto } from '@core/api';

@Component({
  selector: 'app-detail-result-number-field-value',
  templateUrl: './detail-result-number-field-value.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailResultNumberFieldValueComponent implements OnInit {
  @Input()
  public result: FlatInspectionResultDto;

  @Input()
  public readonly = false;

  @Input()
  public displayRemark = false;

  @Output()
  public resultChange: EventEmitter<any> = new EventEmitter();

  public fieldTypes = InspectionElementFieldType;

  private previousValue?: number;

  public onChangeResult(): void {
    if (this.result.fieldValue.numberValue !== this.previousValue) {
      this.previousValue = this.result.fieldValue.numberValue;
      this.resultChange.emit();
    }
  }

  public onChangeRemark(): void {
    this.resultChange.emit();
  }

  public ngOnInit() {
    this.previousValue = this.result.fieldValue.numberValue;
  }
}
