<div class="result-button-container">
  <button
    class="btn btn-outline-primary btn-result"
    (click)="onChangeResult()"
    [disabled]="readonly">
    <span *ngIf="result.yesNoValue === null">&nbsp;</span>
    <span *ngIf="result.yesNoValue === true" translate>Ja</span>
    <span *ngIf="result.yesNoValue === false" translate>Nein</span>
  </button>
</div>
