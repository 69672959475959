<app-navigation [currentPage]="'search'" [inspectionKey]="null"></app-navigation>

<div class="bsg-form-group row ms-0 page-content">
  <div class="row">
    <div class="col-md-auto">
      <div class="custom-radio form-control-inline">
        <input
          type="radio"
          id="searchTypeBetrieb"
          name="searchType"
          class="form-check-input"
          value="Betriebe"
          [(ngModel)]="searchType"
          checked />
        <label class="form-control-label" for="searchTypeBetrieb" translate>Betriebe</label>
      </div>
    </div>
    <div class="col-md-auto">
      <div class="custom-radio form-control-inline">
        <input
          type="radio"
          id="searchTypePersonen"
          name="searchType"
          class="form-check-input"
          value="Personen"
          [(ngModel)]="searchType" />
        <label class="form-control-label" for="searchTypePersonen" translate>Personen</label>
      </div>
    </div>
    <div class="col-md-auto">
      <div class="custom-radio form-control-inline">
        <ng-container *ngIf="canUserSearchOwnInspections()">
          <label class="checkbox mb-n2">
            <input
              type="checkbox"
              name="onlyOwnInspections"
              [(ngModel)]="inspectionSearchDto.onlyOwnInspections" />
            <span class="checkmark-sm"></span>
            <span class="checkbox-label" translate>Search.NurEigeneAuftraege</span>
          </label>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<form #form="ngForm">
  <div *ngIf="searchType === 'Betriebe'">
    <form-row column-classes="col-md-1,col-md-2">
      <form-control labelKey="Search.UnitName">
        <input
          name="unitName"
          [(ngModel)]="inspectionSearchDto.unitSearchArguments.name"
          type="text"
          maxlength="255" />
      </form-control>
      <form-control labelKey="Search.Plz" invalidKey="InvalidNumber">
        <input
          name="unitPlz"
          [(ngModel)]="inspectionSearchDto.unitSearchArguments.postalZone"
          type="text"
          maxlength="4"
          pattern="\d{0,4}" />
      </form-control>
      <form-control labelKey="Search.Ort">
        <input
          name="unitOrt"
          [(ngModel)]="inspectionSearchDto.unitSearchArguments.town"
          type="text"
          maxlength="255" />
      </form-control>
      <form-control labelKey="Search.KantonId">
        <input
          name="unitKantonId"
          [(ngModel)]="inspectionSearchDto.unitSearchArguments.ktId"
          maxlength="255" />
      </form-control>
    </form-row>
    <form-row column-classes="col-md-1,col-md-2">
      <form-control labelKey="Search.DatumVon">
        <app-mkde-datepicker
          name="unitDatumVon"
          [(ngModel)]="inspectionSearchDto.inspectionDateFrom"></app-mkde-datepicker>
      </form-control>
      <form-control labelKey="Search.DatumBis">
        <app-mkde-datepicker
          name="unitDatumBis"
          [(ngModel)]="inspectionSearchDto.inspectionDateTo"></app-mkde-datepicker>
      </form-control>
      <form-control labelKey="Search.TvdNr" invalidKey="InvalidNumber">
        <input
          name="unitTvdNr"
          [(ngModel)]="inspectionSearchDto.unitSearchArguments.tvdId"
          type="text"
          maxlength="7"
          pattern="\d{0,7}" />
      </form-control>
      <form-control labelKey="Search.BurNr" invalidKey="InvalidNumber">
        <input
          name="unitBurNr"
          [(ngModel)]="inspectionSearchDto.unitSearchArguments.burId"
          type="text"
          maxlength="8"
          pattern="\d{0,8}" />
      </form-control>
    </form-row>
  </div>
  <div *ngIf="searchType === 'Personen'">
    <form-row column-classes="col-md-1,col-md-2">
      <form-control labelKey="Search.PersonNachname">
        <input
          name="personNachname"
          [(ngModel)]="inspectionSearchDto.personSearchArguments.surname"
          type="text"
          maxlength="255" />
      </form-control>
      <form-control labelKey="Search.PersonVorname">
        <input
          name="personVorname"
          [(ngModel)]="inspectionSearchDto.personSearchArguments.firstname"
          maxlength="255" />
      </form-control>
      <form-control labelKey="Search.Plz" invalidKey="InvalidNumber">
        <input
          name="personPlz"
          [(ngModel)]="inspectionSearchDto.personSearchArguments.postalZone"
          type="text"
          maxlength="4"
          pattern="\d{0,4}" />
      </form-control>
      <form-control labelKey="Search.Ort">
        <input
          name="personOrt"
          [(ngModel)]="inspectionSearchDto.personSearchArguments.town"
          type="text"
          maxlength="255" />
      </form-control>
    </form-row>
    <form-row column-classes="col-md-1,col-md-2">
      <form-control labelKey="Search.DatumVon">
        <app-mkde-datepicker
          name="personDatumVon"
          [(ngModel)]="inspectionSearchDto.inspectionDateFrom"></app-mkde-datepicker>
      </form-control>
      <form-control labelKey="Search.DatumBis">
        <app-mkde-datepicker
          name="personDatumBis"
          [(ngModel)]="inspectionSearchDto.inspectionDateTo"></app-mkde-datepicker>
      </form-control>
      <form-control labelKey="Search.KantonalePersonennummer">
        <input
          name="personKantonalePersonennummer"
          [(ngModel)]="inspectionSearchDto.personSearchArguments.ktId"
          type="text"
          maxlength="255" />
      </form-control>
      <form-control labelKey="Search.Sozialversicherungsnummer">
        <input
          name="personSozialversicherungsnummer"
          [(ngModel)]="inspectionSearchDto.personSearchArguments.socialSecurityNumber"
          type="text"
          maxlength="255" />
      </form-control>
    </form-row>
  </div>

  <button class="btn btn-primary me-2" (click)="search()" [disabled]="form.invalid">
    <span class="fas fa-circle-notch fa-spin fa-fw" *ngIf="searching"></span>
    <span class="fas fa-search" *ngIf="!searching"></span>
    <span translate>Suchen</span>
  </button>
  <button class="btn btn-outline-primary" (click)="resetSearchForm()" translate>
    Zurücksetzen
  </button>
</form>

<hr />

<div id="searchResults" *ngIf="results?.length">
  <div class="bsg-table-header">
    <span translate [translateParams]="{ nbResults: results.length }"
      >Anzahl Kontrollen gefunden</span
    >
  </div>

  <table class="table table-striped table-hover bsg-table">
    <thead>
      <tr>
        <th>
          <div class="form-check form-check-indented">
            <label class="checkbox">
              <input
                name="selectAll"
                type="checkbox"
                [disabled]="!results?.length"
                [(ngModel)]="selectAllChecked"
                (change)="toggleSelectAll()" />
              <span class="checkmark"></span>
            </label>
          </div>
        </th>
        <th>
          <a href (click)="sortBy('name')">
            <span *ngIf="resultsSearchType === 'Betriebe'" translate>Name der Einheit</span>
            <span *ngIf="resultsSearchType === 'Personen'" translate>Name</span>
            <span *ngIf="sortedBy === 'name'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('address')">
            <span translate>Adresse</span>
            <span *ngIf="sortedBy === 'address'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('postalZone')">
            <span translate>PLZ</span>
            <span *ngIf="sortedBy === 'postalZone'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('town')">
            <span translate>Ort</span>
            <span *ngIf="sortedBy === 'town'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th class="col-md-3">
          <a href (click)="sortBy('inspectionType')">
            <span translate>Typ</span>
            <span
              *ngIf="sortedBy === 'inspectionType'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectionDate')">
            <span translate>Datum</span>
            <span
              *ngIf="sortedBy === 'inspectionDate'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectionStyleName')">
            <span translate>Kontrollart</span>
            <span
              *ngIf="sortedBy === 'inspectionStyleName'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectionStatus')">
            <span translate>Status</span>
            <span
              *ngIf="sortedBy === 'inspectionStatus'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th *ngIf="resultsSearchType === 'Betriebe'">
          <a href (click)="sortBy('tvdNumber')">
            <span translate>TVD-Nr.</span>
            <span *ngIf="sortedBy === 'tvdNumber'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th *ngIf="resultsSearchType === 'Betriebe'">
          <a href (click)="sortBy('burNumber')">
            <span translate>BUR-Nr.</span>
            <span *ngIf="sortedBy === 'burNumber'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('cantonId')">
            <span translate>Kant. ID</span>
            <span *ngIf="sortedBy === 'cantonId'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectorName')">
            <span translate>Kontrolleur</span>
            <span
              *ngIf="sortedBy === 'inspectorName'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let result of results"
        [class.selected-row]="result.isSelected"
        [class.disabled-row]="!result.isSelectable"
        [class.selectable-row]="result.isSelectable"
        [ngClass]="{ highlightedRow: !result.isActive }">
        <td>
          <div class="form-check form-check-indented">
            <label class="checkbox">
              <input
                name="selectRow"
                type="checkbox"
                [(ngModel)]="result.isSelected"
                [disabled]="!result.isSelectable" />
              <span class="checkmark"></span>
            </label>
          </div>
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.name }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.address }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.postalZone }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.town }}
        </td>
        <td
          (click)="result.isSelected = !result.isSelected && result.isSelectable"
          *ngIf="result.inspectionType">
          {{ result.inspectionType | i18nText }}
        </td>
        <td
          (click)="result.isSelected = !result.isSelected && result.isSelectable"
          *ngIf="!result.inspectionType">
          {{ result.categoryShortNames | i18nTextList }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.inspectionDate | date : 'dd.MM.yyyy' }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.inspectionStyleName }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.inspectionStatus | i18nText }}
        </td>
        <td
          (click)="result.isSelected = !result.isSelected && result.isSelectable"
          *ngIf="resultsSearchType === 'Betriebe'">
          {{ result.tvdNumber }}
        </td>
        <td
          (click)="result.isSelected = !result.isSelected && result.isSelectable"
          *ngIf="resultsSearchType === 'Betriebe'">
          {{ result.burNumber }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.cantonId }}
        </td>
        <td (click)="result.isSelected = !result.isSelected && result.isSelectable">
          {{ result.inspectorName }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div
  id="actionpane"
  class="p-2"
  style="display: flex; flex-direction: row; justify-content: flex-end">
  <button
    *ngIf="results?.length"
    class="btn btn-primary me-2"
    (click)="downloadInspections()"
    [disabled]="!canDownloadInspections()">
    <span class="fas fa-circle-notch fa-spin fa-fw" *ngIf="downloading"></span>
    <span class="fas fa-download" *ngIf="!downloading"></span>
    <span translate>Herunterladen</span>
  </button>
  <app-scroll-to-top class="align-right me-2"></app-scroll-to-top>
</div>
