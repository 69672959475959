import { Injectable } from '@angular/core';
import { Constants } from '@core/constants';
import { ImageConverterService } from '@core/image-converter.service';
import { Observable, from, map, mergeMap, toArray } from 'rxjs';
import { ImageDto } from 'src/app/dtos/imageDto';
import { ImagesDto } from 'src/app/dtos/imagesDto';

@Injectable()
export class ImageService {
  public constructor(private imageConverterService: ImageConverterService) {}

  public getImages(templateName: string): Observable<ImagesDto> {
    const imagesDto = this.getImagesDto(templateName);
    return this.getCovertedImagesDto(imagesDto);
  }

  private getImagesDto(templateName: string): ImagesDto {
    const imagesDto = new ImagesDto();
    switch (templateName) {
      case Constants.protocolStandardTemplateNameArAi:
      case Constants.protocolTemplateNameArAiCertificate:
        imagesDto.id = Constants.centerIdentifierArAi;
        imagesDto.images.push(this.getImageDto(Constants.imageArAi));
        break;
      case Constants.protocolStandardTemplateNameBe:
      case Constants.protocolTemplateNameBeDhbVertragskantone:
        imagesDto.id = Constants.centerIdentifierBe;
        imagesDto.images.push(this.getImageDto(Constants.imageBe));
        break;
      case Constants.protocolStandardTemplateNameFr:
      case Constants.protocolTemplateNameFrGruyere:
      case Constants.protocolTemplateNameFrVacherin:
      case Constants.protocolTemplateNameFrViehhandel:
        imagesDto.id = Constants.centerIdentifierFr;
        imagesDto.images.push(this.getImageDto(Constants.imageFr1));
        imagesDto.images.push(this.getImageDto(Constants.imageFr2));
        imagesDto.images.push(this.getImageDto(Constants.imageVfrCl));
        imagesDto.images.push(this.getImageDto(Constants.imageVfrCl2));
        break;
      case Constants.protocolStandardTemplateNameFl:
        imagesDto.id = Constants.centerIdentifierFl;
        imagesDto.images.push(this.getImageDto(Constants.imageFl));
        break;
      case Constants.protocolStandardTemplateNameAg:
        case Constants.protocolStandardTemplateNameAgBienen:
        imagesDto.id = Constants.centerIdentifierAg;
        imagesDto.images.push(this.getImageDto(Constants.imageAg));
        break;
      case Constants.protocolTemplateNameZhPrivatePharmacies:
      case Constants.protocolStandardTemplateNameZh:
      case Constants.protocolStandardTemplateNameZhTnp:
        imagesDto.id = Constants.centerIdentifierZh;
        imagesDto.images.push(this.getImageDto(Constants.imageZh1));
        imagesDto.images.push(this.getImageDto(Constants.imageZh2));
        break;
      case Constants.protocolStandardTemplateNameUr:
        imagesDto.id = Constants.centerIdentifierUr;
        imagesDto.images.push(this.getImageDto(Constants.imageUrSzOwNw));
        break;
      default:
        imagesDto.id = Constants.centerIdentifierBe;
        imagesDto.images.push(this.getImageDto(Constants.imageBe));
        imagesDto.images.push(this.getImageDto(Constants.imageBe));
        break;
    }
    return imagesDto;
  }

  private getImageDto(name: string): ImageDto {
    const image = new ImageDto();
    image.name = name;
    image.relativePath = `../../assets/${image.name}`;
    image.extension = image.name.split('.')[1];
    return image;
  }

  private getCovertedImagesDto(imagesDto: ImagesDto): Observable<ImagesDto> {
    return this.getConvertedImages(imagesDto.images).pipe(
      map((convertedImages: ImageDto[]) => {
        imagesDto.images = convertedImages;
        return imagesDto;
      })
    );
  }

  private getConvertedImages(imagesDto: ImageDto[]): Observable<ImageDto[]> {
    return from(imagesDto).pipe(
      mergeMap((image: ImageDto) => {
        const convertedImage$ = this.getConvertedImage(image);
        return convertedImage$;
      }),
      toArray()
    );
  }

  private getConvertedImage(pdfImage: ImageDto): Observable<ImageDto> {
    return this.imageConverterService
      .getBase64ImageFromRelativeUrl(pdfImage.relativePath, pdfImage.extension)
      .pipe(
        map((imageConverted: string) => {
          pdfImage.imageData = imageConverted;
          return pdfImage;
        })
      );
  }
}
