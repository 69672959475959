import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  @Input()
  public enableAutofocus = true;

  public constructor(private element: ElementRef) {}

  public ngAfterViewInit(): void {
    if (this.enableAutofocus) {
      setTimeout(() => {
        if (this.element.nativeElement.nodeName === 'APP-MKDE-DATEPICKER') {
          this.element.nativeElement.children[0].focus();
        } else {
          this.element.nativeElement.focus();
        }
      }, 0);
    }
  }
}
