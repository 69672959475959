import { I18NText } from '../core/api/model/i18NText';

export class InspectionListItemDto {
  public inspectionKey: string;
  public isSelected = false;
  public isSelectable: boolean;
  public isReadyForUpload = false;
  public name?: string;
  public address?: string;
  public postalZone?: string;
  public town?: string;
  public inspectionType?: I18NText;
  public inspectionDate: Date;
  public inspectionStatus?: I18NText;
  public tvdNumber?: string;
  public burNumber?: string;
  public cantonId?: string;
  public categoryShortNames?: I18NText[];
  public inspectorName?: string;
  public inspectionStyleName?: string;
  public isActive: boolean;
}
