import { ConnectionStateService } from '../core/connection-state.service';
import { Component, Input } from '@angular/core';
import { keysToTranslate } from 'src/app/core/util/keys-to-translate';
import { Role } from '../core/api/model/role';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent {
  @Input()
  public currentPage: string;

  @Input()
  public inspectionKey: string;

  public constructor(public connectionStateService: ConnectionStateService) {
    keysToTranslate([
      'LoginRequiredForSearch',
      'SelectOneInspectionForEditResults',
      'SelectOneInspectionForEditProtocol',
    ]);
  }

  public get userIsAdmin() {
    return (
      this.connectionStateService.user &&
      this.connectionStateService.user.roles &&
      this.connectionStateService.user.roles.some((r) => r === Role.AcontrolAdminK)
    );
  }
}
