import { InspectionWithResultsDto } from '@core/api/model/inspectionWithResultsDto';
import { ProtocolPdfBeService } from './protocol-pdf-be.service';
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class ProtocolPdfBeEmailService extends ProtocolPdfBeService {
  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    const multiLineTextResponsiblePersonPresent = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant('ProtocolPdf.TextResponsiblePersonPresentBeEmail'),
      ProtocolPdfConstants.maxLineWidth
    );
    const signatureBlockHeight = multiLineTextResponsiblePersonPresent.length + 2 *
      ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    baseY += ProtocolPdfConstants.lineHeight;
    baseY = this.writeMultiLineText(
      multiLineTextResponsiblePersonPresent,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX,
      baseY,
      0
    );
    baseY += ProtocolPdfConstants.lineHeight;
    return baseY;
  }
}
