import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { InspectionElementDto } from '../api/model/inspectionElementDto';
import { InspectionCategoryDBService } from '../db/inspection-category-db-service';

@Injectable()
export class InspectionCategoriesResolver implements Resolve<InspectionElementDto[]> {
  public constructor(private inspectionCategoryDbService: InspectionCategoryDBService) {}

  public resolve() {
    return this.inspectionCategoryDbService.getAllInspectionCategories();
  }
}
