/* eslint-disable @typescript-eslint/member-ordering */
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class PdfDocumentText {
  public text?: string;
  public maxTextWidth?: number;
  public positionX?: number;
  public positionY?: number;
  public maxNewTextLines?: number;
  public textLinesRequired?: number;
  public multiLinesPrefix?: string;

  private currentPrefix?: string;
  private currentFontType?: string;
  private currentFontSize?: number;
  private currentMultilineText?: string[];

  public get prefix(): string {
    if (this.currentPrefix === undefined) {
      this.currentPrefix = null;
    }
    return this.currentPrefix;
  }

  public set prefix(value: string) {
    if (value === null) {
      this.currentPrefix = null;
    }
    this.currentPrefix = value;
  }

  public get fontSize(): number {
    if (this.currentFontSize === undefined) {
      this.currentFontSize = ProtocolPdfConstants.defaultFontSize;
    }
    return this.currentFontSize;
  }

  public set fontSize(value: number) {
    if (value < 0) {
      this.currentFontSize = ProtocolPdfConstants.defaultFontSize;
    }
    this.currentFontSize = value;
  }

  public get fontType(): string {
    if (this.currentFontType === undefined) {
      this.currentFontType = ProtocolPdfConstants.defaultFontType;
    }
    return this.currentFontType;
  }

  public set fontType(value: string) {
    if (value === null) {
      this.currentFontType = ProtocolPdfConstants.defaultFontType;
    }
    this.currentFontType = value;
  }

  public get multilineText(): string[] {
    if (this.currentMultilineText === undefined) {
      this.currentMultilineText = [];
    }
    return this.currentMultilineText;
  }

  public set multilineText(value: string[]) {
    if (value === null) {
      this.currentMultilineText = [];
    }
    this.currentMultilineText = value;
  }

  public getPostionYRequiredSize(): number {
    if (this.multilineText !== null && this.multilineText.length > 1) {
      const totalExtraRows = this.multilineText.length - 1;
      return totalExtraRows * ProtocolPdfConstants.lineHeight;
    }
    return 0;
  }
}
