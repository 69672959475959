import { Injectable, NgZone } from '@angular/core';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';

@Injectable()
export class MkdeUserNotificationService {
  public constructor(private alerts: MkdeAlertHolderDirective, private ngZone: NgZone) {}

  public error(translatedMessage: string, error: any) {
    this.ngZone.run(() => {
      // error handlers often run outside the zone (for instance for HTTP 403 errors)
      this.alerts.danger(translatedMessage, error);
    });
  }
}
