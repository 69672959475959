import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { keysToTranslate } from '@core/util/keys-to-translate';

@Component({
  selector: 'app-defect-dialog',
  templateUrl: './defect-dialog.component.html',
})
export class DefectDialogComponent implements OnInit {
  @Input()
  public defectForm: FormGroup;

  @Input()
  public readonly: boolean;

  @Output()
  public defectChange: EventEmitter<any> = new EventEmitter();

  public constructor(public modal: NgbActiveModal) {
    keysToTranslate([
      'Mangel.Schweregrad',
      'Mangel.RueckmeldungBis',
      'Mangel.Umfang',
      'Mangel.Wiederholung',
      'Mangel.Beschreibung',
    ]);
  }

  public get showDefectSeveritySelection() {
    return this.defectForm.value.showDefectSeveritySelection;
  }

  public get defectResult() {
    return this.defectForm.get('result') as FormGroup;
  }

  public ngOnInit(): void {
    if (!this.defectResult.value.defectRepetition) {
      this.defectResult.patchValue({ defectRepetition: 0 });
    }
  }

  public onDefectChange(): void {
    if (this.defectForm.valid) {
      this.defectChange.emit();
    }
  }
}
