import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { InspectionWithResultsDto } from '../api/model/inspectionWithResultsDto';
import { InspectionDBService } from '../db/inspection-db-service';

@Injectable()
export class InspectionResolver implements Resolve<InspectionWithResultsDto> {
  public constructor(private inspectionDbService: InspectionDBService) {}

  public resolve(route: ActivatedRouteSnapshot) {
    return this.inspectionDbService.getInspectionWithResults(route.paramMap.get('inspectionKey'));
  }
}
