import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18NText } from '../api/model/i18NText';

@Pipe({ name: 'i18nText', pure: false })
export class I18nTextPipe implements PipeTransform {
  public constructor(private translateService: TranslateService) {}

  public transform(value: I18NText): string {
    let translatedValue = null;
    if (value) {
      const currentLanguage = this.translateService.currentLang;
      translatedValue = value.germanText;
      if (currentLanguage === 'fr') {
        translatedValue = value.frenchText;
      }
    }

    return translatedValue;
  }
}
