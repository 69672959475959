import { forkJoin } from 'rxjs';
import { ProtocolPdfService } from './../core/protocol-pdf/protocol-pdf.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { I18NText, InspectionTypeConfig, InspectionWithResultsDto } from '@core/api';
import { ClientConfigDBService } from '@core/db/client-config-db-service';
import { ActivatedRoute } from '@angular/router';
import { InspectionDBService } from '@core/db/inspection-db-service';
import { InspectionCategoryCacheService } from '@core/cache/inspection-category-cache-service';

@Component({
  selector: 'app-protocol-selector',
  templateUrl: './protocol-selector.component.html',
})
export class ProtocolSelectorComponent implements OnInit {
  @Input()
  public readonly = false;
  @Output()
  public protocolPdfTemplateSelected: EventEmitter<string> = new EventEmitter();
  public selectedProtocolPdfTemplate: string;
  public protocolPdfTemplates: string[];

  public constructor(
    private protocolPdfService: ProtocolPdfService,
    private clientConfigDbService: ClientConfigDBService,
    private inspectionDbService: InspectionDBService,
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    forkJoin([
      this.clientConfigDbService.getInspectionTypeConfig(),
      this.protocolPdfService.getProtocolPdfTemplates(),
      this.inspectionDbService.getInspectionWithResults(
        this.route.snapshot.paramMap.get('inspectionKey')
      ),
    ]).subscribe(
      ([inspectionTypeConfig, protocolPdfTemplates, inspection]: [
        InspectionTypeConfig,
        string[],
        InspectionWithResultsDto
      ]) => {
        this.protocolPdfTemplates = protocolPdfTemplates;
        const inspectionTypeName = this.getInspectionTypeNames(inspection);
        this.selectDefaultProtocolPdfTemplate(inspectionTypeConfig, inspectionTypeName);
      }
    );
  }

  public onProtocolPdfTemplateSelected(value: any): void {
    this.protocolPdfTemplateSelected.emit(value);
  }

  public asIsOrder(_a: any, _b: any): number {
    return 1;
  }

  private getInspectionTypeNames(inspection: InspectionWithResultsDto): I18NText[] {
    return inspection != null && inspection?.inspectionTypeName != null
      ? [inspection.inspectionTypeName]
      : this.getInspectionCategoryShortNames(inspection);
  }

  private getInspectionCategoryShortNames(inspection: InspectionWithResultsDto): I18NText[] {
    return this.inspectionCategoryCacheService.getInspectionCategoryShortNames(
      inspection.categoryResults.map((categoryResult) => categoryResult.categoryNumber)
    );
  }

  private selectDefaultProtocolPdfTemplate(
    inspectionTypeConfig: InspectionTypeConfig,
    inspectionTypeNames: I18NText[]
  ): void {
    const templateKeys = Array.from(this.protocolPdfTemplates);
    let defaultProtocolTemplate = templateKeys[0];
    if (
      inspectionTypeConfig == null ||
      inspectionTypeConfig.inspectionTypes.length <= 0 ||
      templateKeys.length === 1
    ) {
      this.setSelectedProtocolTemplate(defaultProtocolTemplate);
      return;
    }
    const defaultProtocolFromInspectionTypeConfig =
      this.getDefaultProtocolPdfFromInspectionTypeConfig(inspectionTypeConfig, inspectionTypeNames);
    if (defaultProtocolFromInspectionTypeConfig != null) {
      defaultProtocolTemplate = defaultProtocolFromInspectionTypeConfig;
    }
    this.setSelectedProtocolTemplate(defaultProtocolTemplate);
  }

  private setSelectedProtocolTemplate(defaultProtocolTemplate: string): void {
    this.selectedProtocolPdfTemplate = defaultProtocolTemplate;
    this.protocolPdfTemplateSelected.emit(defaultProtocolTemplate);
  }

  private getDefaultProtocolPdfFromInspectionTypeConfig(
    inspectionTypeConfig: InspectionTypeConfig,
    inspectionTypeNames: I18NText[]
  ): string {
    if (inspectionTypeNames == null || inspectionTypeNames.length <= 0) {
      return null;
    }
    const templateKeys = Array.from(this.protocolPdfTemplates);
    const inspectionTypeNamesGerman = inspectionTypeNames.map(
      (inspection) => inspection.germanText
    );
    const defaultProtocolPdfs = inspectionTypeConfig.inspectionTypes.filter((inspection) =>
      inspectionTypeNamesGerman.includes(inspection.acontrolNameDe)
    );
    if (defaultProtocolPdfs == null || defaultProtocolPdfs.length <= 0) {
      return null;
    }
    const sameDefaultProtocolTemplates = defaultProtocolPdfs.filter((defaultProtocol) =>
      templateKeys.includes(defaultProtocol.defaultProtocolPdf)
    );
    if (sameDefaultProtocolTemplates != null && sameDefaultProtocolTemplates.length > 0) {
      return sameDefaultProtocolTemplates[0].defaultProtocolPdf;
    }
    return null;
  }
}
