import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MKDEUserDto } from '../api';
import { UserDBService } from '../db/user-db-service';

@Injectable()
export class UserResolver implements Resolve<MKDEUserDto> {
  public constructor(private userDbService: UserDBService) {}

  public resolve() {
    return this.userDbService.getUser();
  }
}
