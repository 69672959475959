import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ok-cancel-dialog',
  templateUrl: './ok-cancel-dialog.component.html',
})
export class OkCancelDialogComponent {
  @Input()
  public title: string;

  @Input()
  public text: string;

  public constructor(public modal: NgbActiveModal) {}
}
