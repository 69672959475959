import { NgModule } from '@angular/core';
import {
  FormControlComponent,
  FormControlHelpDirective,
  FormControlLabelDirective,
  FormControlSubTemplateDirective,
  FormControlViewContainerDirective,
} from './form-control.component';
import { RegisterFormControlInputDirective } from './register-form-control-input.directive';
import { InlineFormDirective } from './inline-form.directive';
import { FormColumnsDirective } from './form-columns.directive';
import { FormRowDirective } from './form-row.directive';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { FormLayout, VerticalFormLayout } from './form-layout';
import { ValidationMessageService } from './validation-message.service';
import { DisableDirective } from './disabled.rective';

@NgModule({
  imports: [NgbPopoverModule, CommonModule, CoreModule],
  declarations: [
    FormColumnsDirective,
    FormControlComponent,
    FormControlViewContainerDirective,
    FormControlSubTemplateDirective,
    FormControlLabelDirective,
    FormControlHelpDirective,
    FormRowDirective,
    InlineFormDirective,
    RegisterFormControlInputDirective,
    DisableDirective,
  ],
  exports: [
    FormColumnsDirective,
    FormControlComponent,
    FormRowDirective,
    InlineFormDirective,
    RegisterFormControlInputDirective,
    DisableDirective,
  ],
  providers: [{ provide: FormLayout, useClass: VerticalFormLayout }, ValidationMessageService],
})
export class FormModule {}
