import { PdfDocumentText } from './pdf-document-text';
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class PdfDocumentCheckbox extends PdfDocumentText {
  public marginText = 35;
  public currentPositionY: number;
  public checkboxNumber = 1;
  public extraSizePositionX = 0;
  public readonly marginCheckbox = 3;

  public setPositionX(): void {
    this.positionX = (ProtocolPdfConstants.marginX + this.marginCheckbox) * this.checkboxNumber;
    this.positionX += this.extraSizePositionX;
    if (this.checkboxNumber > 1) {
      this.positionX += this.marginCheckbox;
    }
  }
}
