/* eslint-disable @typescript-eslint/naming-convention */
import { ProtocolPdfFrMilkProducersService } from './protocol-pdf-fr-milk-producers-service';

export class ProtocolPdfFrGruyereService extends ProtocolPdfFrMilkProducersService {
  protected pageFooterDateAsText = '01-06-21';
  protected translationKeyPageHeaderTitle = 'ProtocolPdf.GruyerePageHeader';
  protected prefixFileName = 'GRU';
  protected columnIndexStartCheckboxes = 4;
  protected columnIndexStartRequirement = 2;

  protected addTablesToPages(): void {
    this.addTablesToPage1();
    this.addTablesToPage2();
    this.addTablesToPage3();
  }

  protected getTranslationKeyPageFooterRow2Part1(): string {
    return 'ProtocolPdf.GruyerePageFooterRow2Part1';
  }

  private addTablesToPage1(): void {
    this.addUnitTable();
    this.addAuditorTable();
    this.addMilkProductionUnitTable();
    this.addMilkProducersTable(
      this.translateService.instant('ProtocolPdf.GruyereMilkProducersHeader'),
      3
    );
    this.addRemarksForRequirementsTable();
    this.addRequirementsLegend();
    this.addRequirementsTableToPage1();
  }

  private addTablesToPage2(): void {
    this.addRequirementsTableToPage2();
  }

  private addTablesToPage3(): void {
    this.addRequirementsTableToPage3();
    const legendSpaceY = this.addRemarksLegend();
    let numberOfRows = 5;
    if (this.isCurrentLanguageFr) {
      numberOfRows = 4;
    }
    this.addRemarksTable(legendSpaceY, numberOfRows);
    this.addConfirmationTable();
  }

  private addMilkProductionUnitTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      columnStyles: {
        0: { cellWidth: 59 },
        1: { cellWidth: 59 },
        2: { cellWidth: 63.5 },
      },
      head: [
        [
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitHeaderPart1'),
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitHeaderPart2'),
          '',
        ],
      ],
      body: [
        [
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitRow1Col1'),
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitRow1Col2'),
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitRow1Col3'),
        ],
      ],
      didParseCell: (data: any) => {
        this.applySpansToMilkProductionUnitTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToMilkProductionUnitTable(data);
      },
    });
  }

  private applySpansToMilkProductionUnitTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 1) {
      data.cell.colSpan = 2;
    }
  }

  private addAcroFieldsToMilkProductionUnitTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 1) {
      const field = this.addAcroTextbox(data, { width: 25 });
      this.document.setFontSize(7.4);
      this.document.setFont('helvetica', 'italic');
      const positionX = field.x + field.width + this.separationSpace;
      const positionY = field.y + field.height - this.cellPaddingTop;
      this.document.text(
        this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitHeaderPart3'),
        positionX,
        positionY
      );
    }
    if (data.section === 'body' && data.row.index === 0) {
      const positionY = data.cell.y + this.cellPaddingTop;
      const textboxWidthCol1AndCol2 = 33;
      if (data.column.index === 0) {
        this.addAcroTextbox(
          data,
          { width: textboxWidthCol1AndCol2, y: positionY },
          this.protocolInspectionService.getCantonId()
        );
      }
      if (data.column.index === 1) {
        let labelTvdIdPositionX = this.calculateFieldPositionX(data);
        if (this.isCurrentLanguageFr) {
          labelTvdIdPositionX += this.separationSpace;
        }
        this.addAcroTextbox(
          data,
          { width: textboxWidthCol1AndCol2, x: labelTvdIdPositionX, y: positionY },
          this.protocolInspectionService.getTvdId()
        );
      }
      if (data.column.index === 2) {
        let textboxWidhtCol3 = 41.5;
        if (this.isCurrentLanguageFr) {
          textboxWidhtCol3 = 38;
        }
        this.addAcroTextbox(data, { width: textboxWidhtCol3, y: positionY });
      }
    }
  }

  private addRequirementsLegend(): void {
    this.setPagePositionY(0.7);
    const separationSpaceRow1 = 9;
    let positionY = this.getPagePositionY() + separationSpaceRow1;
    let positionX = this.minPositionX + 1;
    this.document.setFontSize(10);
    this.document.setFont('helvetica', 'bold');
    this.document.text(
      this.translateService.instant('ProtocolPdf.GruyereRequirementsLegendRow1'),
      positionX,
      positionY
    );
    const separationSpaceRow2 = 12.5;
    positionY = this.getPagePositionY() + separationSpaceRow2;
    this.document.setFontSize(this.fontSize);
    this.document.setFont('helvetica', 'bold');
    const row2Part1 = this.translateService.instant(
      'ProtocolPdf.GruyereRequirementsLegendRow2Part1'
    );
    this.document.text(row2Part1, positionX, positionY);
    positionX += this.document.getTextWidth(row2Part1) + 57;
    const row2Part2 = this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable');
    this.document.text(row2Part2, positionX, positionY);
    this.resetToDefaultFontSettings();
    const row2Part3 = this.translateService.instant(
      'ProtocolPdf.VacherinRequirementsLegendRow2Part2'
    );
    positionX += this.document.getTextWidth(row2Part2) + 2;
    this.document.text(row2Part3, positionX, positionY);
  }

  private addRequirementsTableToPage1(): void {
    const head = this.getRequirementsTableHeaderPage1();
    const body = this.getRequirementsTableBodyPage1();
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + 14.5,
      headStyles: { halign: 'center', fontStyle: 'bold' },
      styles: { fontSize: this.fontSize },
      columnStyles: this.getHeaderColumnStyles(),
      head,
      body,
      didParseCell: (data: any) => {
        this.applySpansToRequirementsTablePage(data);
        this.applyStylesToRequirementsTablePage(data);
        this.applyStylesAndSpansToSpecificArticlesPage1(data);
      },
      didDrawCell: (data: any) => {
        this.addRequirementsCheckboxes(data, 3, [2, 4]);
      },
    });
  }

  private getHeaderColumnStyles() {
    return {
      0: {
        cellWidth: this.requirementColumnArticleTextWidth,
        valign: 'middle',
        halign: 'center',
      },
      1: {
        cellWidth: this.requirementColumnArticleNumberWidth,
        valign: 'middle',
        halign: 'center',
      },
      3: { cellWidth: 130, valign: 'middle', halign: 'center' },
      4: {
        cellWidth: this.requirementColumnCheckboxNotWidth,
        valign: 'middle',
        halign: 'center',
      },
      5: { cellWidth: this.requirementColumnCheckboxNotWidth, valign: 'middle', halign: 'center' },
      6: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
    };
  }

  private getRequirementsTableHeaderPage1(): any[][] {
    return [
      [
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol1'
        ),
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol2'
        ),
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol3'
        ),
        '',
        '',
      ],
      [
        '',
        '',
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable'),
      ],
    ];
  }

  private applyStylesToRequirementsTablePage(data: any): void {
    this.applyAlignmentInCenterToHeaderRequirement(data);
    this.applyFontsizeToColumnRequirement(data);
  }

  private applyStylesAndSpansToSpecificArticlesPage1(data: any): void {
    if (data.section !== 'body') {
      return;
    }

    //Article 7
    const rowIndexArticle7 = 1;
    if (data.row.index === rowIndexArticle7 && data.column.index === 0) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle7 && data.column.index === 1) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle7 + 1 && data.column.index === 2) {
      data.cell.styles.lineWidth = {
        top: 0,
        right: this.defaultLineWidth,
        bottom: this.defaultLineWidth,
        left: this.defaultLineWidth,
      };
      data.cell.styles.fontStyle = 'italic';
    }
    if (
      data.row.index === rowIndexArticle7 &&
      data.column.index >= this.columnIndexStartCheckboxes
    ) {
      data.cell.rowSpan = 2;
    }

    //Article 7A
    const rowIndexArticle7A = 3;
    if (data.row.index === rowIndexArticle7A && data.column.index === 0) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle7A && data.column.index === 1) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle7A + 1 && data.column.index === 2) {
      data.cell.styles.lineWidth = {
        top: 0,
        right: this.defaultLineWidth,
        bottom: this.defaultLineWidth,
        left: this.defaultLineWidth,
      };
      data.cell.styles.fontStyle = 'italic';
    }
    if (
      data.row.index === rowIndexArticle7A &&
      data.column.index >= this.columnIndexStartCheckboxes
    ) {
      data.cell.rowSpan = 2;
    }

    //Article 15 Part 2
    const rowIndexArticle15Part2 = 5;
    if (data.row.index === rowIndexArticle15Part2 && data.column.index === 0) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle15Part2 && data.column.index === 1) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle15Part2 + 1 && data.column.index === 2) {
      data.cell.styles.lineWidth = {
        top: 0,
        right: this.defaultLineWidth,
        bottom: this.defaultLineWidth,
        left: this.defaultLineWidth,
      };
      data.cell.styles.fontStyle = 'italic';
    }
    if (
      data.row.index === rowIndexArticle15Part2 &&
      data.column.index >= this.columnIndexStartCheckboxes
    ) {
      data.cell.rowSpan = 2;
    }
  }

  private applyStylesAndSpansToSpecificArticlesPage3(data: any): void {
    if (data.section !== 'body') {
      return;
    }

    //Article 18
    const rowIndexArticle18 = 2;
    if (data.row.index === rowIndexArticle18 && data.column.index === 0) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle18 && data.column.index === 1) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle18 + 1 && data.column.index === 2) {
      data.cell.styles.lineWidth = {
        top: 0,
        right: this.defaultLineWidth,
        bottom: this.defaultLineWidth,
        left: this.defaultLineWidth,
      };
      data.cell.styles.fontStyle = 'italic';
    }
    if (
      data.row.index === rowIndexArticle18 &&
      data.column.index >= this.columnIndexStartCheckboxes
    ) {
      data.cell.rowSpan = 2;
    }

    //Article 23.3
    const rowIndexArticle233 = 6;
    if (data.row.index === rowIndexArticle233 && data.column.index === 0) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle233 && data.column.index === 1) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle233 + 1 && data.column.index === 2) {
      data.cell.styles.lineWidth = {
        top: 0,
        right: this.defaultLineWidth,
        bottom: this.defaultLineWidth,
        left: this.defaultLineWidth,
      };
      data.cell.styles.fontStyle = 'italic';
    }
    if (
      data.row.index === rowIndexArticle233 &&
      data.column.index >= this.columnIndexStartCheckboxes
    ) {
      data.cell.rowSpan = 2;
    }
  }

  private getRequirementsTableBodyPage1(): any[][] {
    return [
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle3'),
        '3',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle3'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle7'),
        '7',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle7Part1'),
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle7Part2'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle7A'),
        '7',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle7APart1'),
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle7APart2'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle15'),
        '15',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsText15Part1'),
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsText15Part2'),
        '',
        '',
        '',
        '',
      ],
    ];
  }

  private addRequirementsTableToPage2(): void {
    const head = this.getRequirementsTableHeaderPage1();
    const body = this.getRequirementsTableBodyPage2();
    //@ts-ignore
    this.document.autoTable({
      headStyles: { halign: 'center', fontStyle: 'bold' },
      styles: { fontSize: this.fontSize },
      margin: { top: this.pageHeaderHeight },
      columnStyles: this.getHeaderColumnStyles(),
      pageBreak: 'avoid',
      head,
      body,
      didParseCell: (data: any) => {
        this.applySpansToRequirementsTablePage(data);
        this.applyStylesToRequirementsTablePage(data);
      },
      didDrawCell: (data: any) => {
        this.addRequirementsCheckboxes(data, 3, []);
      },
    });
  }

  private addRequirementsTableToPage3(): void {
    const head = this.getRequirementsTableHeaderPage1();
    const body = this.getRequirementsTableBodyPage3();
    //@ts-ignore
    this.document.autoTable({
      headStyles: { halign: 'center', fontStyle: 'bold' },
      styles: { fontSize: this.fontSize },
      margin: { top: this.pageHeaderHeight },
      columnStyles: this.getHeaderColumnStyles(),
      pageBreak: 'avoid',
      head,
      body,
      didParseCell: (data: any) => {
        this.applySpansToRequirementsTablePage(data);
        this.applyStylesToRequirementsTablePage(data);
        this.applyStylesAndSpansToSpecificArticlesPage3(data);
      },
      didDrawCell: (data: any) => {
        this.addRequirementsCheckboxes(data, 3, []);
      },
    });
  }

  private applySpansToRequirementsTablePage(data: any): void {
    if (data.section === 'body') {
      if (data.column.index === this.columnIndexStartRequirement) {
        data.cell.colSpan = 2;
      }
    }

    const numberOfColumnsToSpanRequirement = 2;
    this.applySpansToHeaderRequirement(
      data,
      this.columnIndexStartCheckboxes,
      numberOfColumnsToSpanRequirement
    );
  }

  private getRequirementsTableBodyPage2(): any[][] {
    return [
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle9'),
        '9',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle9'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle10'),
        '10',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle10'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle111'),
        '11.1',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Part1'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle112'),
        '11.2',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Part2'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle113'),
        '11.3',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Part3'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle114'),
        '11.4',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Part4'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle121'),
        '12.1',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Part1'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle122'),
        '12.2',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Part2'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle131'),
        '13.1',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Part1'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle132'),
        '13.2',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Part2'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle141'),
        '14.1',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle14Part1'),
        '',
        '',
        '',
        '',
      ],
    ];
  }

  private getRequirementsTableBodyPage3(): any[][] {
    return [
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle16'),
        '16',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle16'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle17'),
        '17',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle17'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle181'),
        '18',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle181'),
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle182'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle19a'),
        '19\na',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle19a'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle19bc'),
        '19\nbc',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle19bc'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle233'),
        '23.3',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle233'),
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle23bem'),
        '',
        '',
        '',
        '',
      ],
    ];
  }
}
