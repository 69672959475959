import { Component, OnInit } from '@angular/core';
import { FileSaverService } from 'ngx-filesaver';
import { keysToTranslate } from 'src/app/core/util/keys-to-translate';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConnectionStateService } from '../core/connection-state.service';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap } from 'rxjs/operators';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent implements OnInit {
  public loggedIn: boolean;

  public constructor(
    private fileSaverService: FileSaverService,
    private httpClient: HttpClient,
    private connectionStateService: ConnectionStateService,
    private alerts: MkdeAlertHolderDirective,
    private translate: TranslateService
  ) {
    keysToTranslate(['UploadConfig', 'DownloadConfig']);
  }

  public ngOnInit(): void {
    if (!this.connectionStateService.loggedIn) {
      this.connectionStateService.toggleLogin();
    }
    if (!this.connectionStateService.getIsUserAdmin()) {
      window.open(`${document.location.origin}/dashboard`, '_self');
    }
  }

  public uploadInspectionTypeConfig(files: FileList) {
    if (this.validateJsonFile(files)) {
      this.uploadConfig('json', 'inspectiontypeconfig', files);
    }
  }

  public downloadInspectionTypeConfig() {
    this.downloadConfig('json', 'inspectiontypeconfig', 'inspectiontype-config.json');
  }

  public uploadCategoryConfig(files: FileList) {
    if (this.validateJsonFile(files)) {
      this.uploadConfig('json', 'categoryconfig', files);
    }
  }

  public downloadCategoryConfig() {
    this.downloadConfig('json', 'categoryconfig', 'category-config.json');
  }

  public uploadPointToCategoryMappingsConfig(files: FileList) {
    if (this.validateJsonFile(files)) {
      this.uploadConfig('json', 'pointtocategorymappingconfig', files);
    }
  }

  public downloadPointToCategoryMappingsConfig() {
    this.downloadConfig('json', 'pointtocategorymappingconfig', 'point-to-category-mappings.json');
  }

  public uploadPointToPointGroupMappingsConfig(files: FileList) {
    if (this.validateJsonFile(files)) {
      this.uploadConfig('json', 'pointtopointgroupmappingconfig', files);
    }
  }

  public downloadPointToPointGroupMappingsConfig() {
    this.downloadConfig('json', 'pointtopointgroupmappingconfig', 'point-to-pointgroup-mappings.json');
  }

  public uploadPointConfig(files: FileList) {
    if (this.validateJsonFile(files)) {
      this.uploadConfig('json', 'pointconfig', files);
    }
  }

  public downloadPointConfig() {
    this.downloadConfig('json', 'pointconfig', 'point-config.json');
  }

  public uploadClientConfig(files: FileList) {
    if (this.validateJsonFile(files)) {
      this.uploadConfig('json', 'clientconfig', files);
    }
  }

  public downloadClientConfig() {
    this.downloadConfig('json', 'clientconfig', 'client-config.json');
  }

  public uploadExcelInspectionTypeConfig(files: FileList) {
    if (this.validateExcelFile(files)) {
      this.uploadConfig('excel', 'inspectiontypeconfig', files);
    }
  }

  public downloadExcelInspectionTypeConfig() {
    this.downloadConfig('excel', 'inspectiontypeconfig', 'Kontrolltyp-Konfiguration.xlsx');
  }

  public uploadExcelCategoryConfig(files: FileList) {
    if (this.validateExcelFile(files)) {
      this.uploadConfig('excel', 'categoryconfig', files);
    }
  }

  public downloadExcelCategoryConfig() {
    this.downloadConfig('excel', 'categoryconfig', 'Rubrik-Konfiguration.xlsx');
  }

  public uploadExcelPointToCategoryMappingsConfig(files: FileList) {
    if (this.validateExcelFile(files)) {
      this.uploadConfig('excel', 'pointtocategorymappingconfig', files);
    }
  }

  public downloadExcelPointToCategoryMappingsConfig() {
    this.downloadConfig('excel', 'pointtocategorymappingconfig', 'Abhängigkeiten-Punkt-zu-Rubrik.xlsx');
  }

  public uploadExcelPointToPointGroupMappingsConfig(files: FileList) {
    if (this.validateExcelFile(files)) {
      this.uploadConfig('excel', 'pointtopointgroupmappingconfig', files);
    }
  }

  public downloadExcelPointToPointGroupMappingsConfig() {
    this.downloadConfig('excel', 'pointtopointgroupmappingconfig', 'Abhängigkeiten-Punkt-zu-Punktegruppe.xlsx');
  }

  public uploadExcelPointConfig(files: FileList) {
    if (this.validateExcelFile(files)) {
      this.uploadConfig('excel', 'pointconfig', files);
    }
  }

  public downloadExcelPointConfig() {
    this.downloadConfig('excel', 'pointconfig', 'Kontrollpunkt-Konfiguration.xlsx');
  }

  public uploadExcelClientConfig(files: FileList) {
    if (this.validateExcelFile(files)) {
      this.uploadConfig('excel', 'clientconfig', files);
    }
  }

  public downloadExcelClientConfig() {
    this.downloadConfig('excel', 'clientconfig', 'Basiskonfiguration-Client.xlsx');
  }

  private downloadConfig(fileType: string, configUrlSegment: string, fileName: string) {
    this.connectionStateService
      .hasConnection()
      .pipe(
        exhaustMap((hasConnection: boolean) => {
          if (!hasConnection) {
            return of(undefined);
          }
          const downloadEndpoint = `${document.location.origin}/api/clientconfigurations/${fileType}/${this.connectionStateService.user.centerIdentifier}/${configUrlSegment}`;
          return this.httpClient.get(downloadEndpoint, {
            observe: 'response',
            responseType: 'blob',
          });
        })
      )
      .subscribe(
        (res: HttpResponse<Blob>) => {
          if (res === undefined) {
            return;
          }
          this.fileSaverService.save(res.body, fileName);
        },
        (error) => {
          if (error.status === 404) {
            this.alerts.danger(
              this.translate.instant('Die Konfiguration wurde nicht gefunden'),
              error
            );
          } else {
            this.alerts.danger(
              this.translate.instant('Die Konfiguration konnte nicht heruntergeladen werden.'),
              error
            );
          }
        }
      );
  }

  private validateJsonFile(files: FileList): boolean {
    if (files.length === 0) {
      return false;
    }
    const file = files[0];
    if (!this.isJsonFile(file.name)) {
      this.alerts.danger(
        this.translate.instant('Es dürfen nur .json-Dateien hochgeladen werden.'),
        null
      );
      return false;
    }
    return true;
  }

  private validateExcelFile(files: FileList): boolean {
    if (files.length === 0) {
      return false;
    }
    const file = files[0];
    if (!this.isExcelFile(file.name)) {
      this.alerts.danger(
        this.translate.instant('Es dürfen nur .xlsx-Dateien hochgeladen werden.'),
        null
      );
      return false;
    }
    return true;
  }

  private uploadConfig(fileType: string, configUrlSegment: string, files: FileList) {
    const file = files[0];
    this.connectionStateService
      .hasConnection()
      .pipe(
        concatMap((hasConnection: boolean) => {
          if (!hasConnection) {
            return of(undefined);
          }
          const uploadEndpoint = `${document.location.origin}/api/clientconfigurations/${fileType}/${this.connectionStateService.user.centerIdentifier}/${configUrlSegment}`;
          const formData = new FormData();
          formData.append('file', file, file.name);
          return this.httpClient.put(uploadEndpoint, formData, {}).pipe(
            catchError((error) => {
              console.log(error);
              if (error.status === 401) {
                this.alerts.danger(
                  this.translate.instant(
                    'Sie sind nicht mehr mit Acontrol verbunden. Bitte melden Sie sich erneut an.'
                  ),
                  error
                );
              } else if (error.status === 422) {
                this.alerts.danger(
                  this.translate.instant('Die Konfiguration konnte nicht hochgeladen werden.'),
                  error.error.message
                );
              } else {
                this.alerts.danger(
                  this.translate.instant('Die Konfiguration konnte nicht hochgeladen werden.'),
                  error
                );
              }
              return of(undefined);
            })
          );
        })
      )
      .subscribe((success) => {
        if (success === undefined) {
          return;
        }
        this.alerts.success(
          this.translate.instant('Die Konfiguration wurde erfolgreich hochgeladen.')
        );
      });
  }

  private isJsonFile(fileName: string): boolean {
    return this.isFileTypeAllowed(fileName, '.json');
  }

  private isExcelFile(fileName: string): boolean {
    return this.isFileTypeAllowed(fileName, '.xlsx');
  }

  private isFileTypeAllowed(fileName: string, allowedFileEnding: string): boolean {
    const parts = fileName.split('.');
    if (parts && parts.length > 0) {
      const fileEnding = '.' + parts[parts.length - 1];
      return fileEnding === allowedFileEnding;
    }
    return false;
  }
}
