<div class="result-button-container">
  <div
    ngbPopover="{{ 'Fokuskontrollpunkt' | translate }}"
    placement="top"
    triggers="mouseenter:mouseleave"
    [disablePopover]="!isFkp">
    <button
      class="btn btn-outline-primary btn-result"
      [ngClass]="{ focus: isFkp }"
      (click)="onChangeResult()"
      [disabled]="readonly">
      <span *ngIf="result.inspectionResult === resultTypes.NotDefined">&nbsp;</span>
      <span *ngIf="!result.inspectionResult" class="fas fa-check"></span>
      <span *ngIf="result.inspectionResult === resultTypes.Defect" translate>M</span>
      <span *ngIf="result.inspectionResult === resultTypes.NotControlled" translate>NK</span>
      <span *ngIf="result.inspectionResult === resultTypes.NotApplicable" translate>NZ</span>
    </button>
  </div>
  <div class="detail-result-remark-container">
    <app-detail-result-remark
      class="btn-ellipsis-link-after-dropdown"
      *ngIf="
        result.inspectionResult === resultTypes.Defect ||
        result.inspectionResult === resultTypes.NotControlled ||
        result.inspectionResult === resultTypes.NotApplicable
      "
      [result]="result"
      (remarkChange)="onChangeRemark()"
      [readonly]="readonly"
      [elementName]="elementName"
      [defectDescriptionSuggestions]="defectDescriptionSuggestions"></app-detail-result-remark>
  </div>
</div>
