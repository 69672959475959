import { PdfField } from './pdf-field';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { PdfDocumentCheckbox } from './pdf-document-checkbox';
import { ProtocolPdfArAiService } from './protocol-pdf-ar-ai.service';
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class ProtocolPdfArAiCertificateService extends ProtocolPdfArAiService {
  private readonly prefixCheckboxSpace = 12;
  private checkbox: PdfDocumentCheckbox;
  private baseY: number;

  protected addInspectionTitle(baseY: number, _titleText: string): number {
    return super.addInspectionTitle(baseY, 'ProtocolPdf.HeaderReportArAiCertificate');
  }

  protected getProtocolFileName(inspectionId: number): string {
    return `Kontrollbescheinigung-${inspectionId}.pdf`;
  }

  protected addPageBody(_protocolPdfDto: ProtocolPdfDto, baseY: number): number {
    this.checkbox = new PdfDocumentCheckbox();
    this.baseY = baseY;
    this.addInspectionCheckboxesForSuccess();
    this.addSpaceBetweenBlocks();
    this.addInspectionCheckboxesForFails();
    this.addSpaceBetweenBlocks();
    this.addInspectionCheckboxesComments(5);
    this.addSpaceBetweenBlocks();
    this.addCurrentDateWithLabel(this.baseY, new Date(), ProtocolPdfConstants.smallFontSize);
    this.addSpaceBetweenBlocks(3);
    this.addLabelSignatureInspectionPerson();
    return this.baseY;
  }

  private addInspectionCheckboxesForSuccess(): void {
    this.addInspectionCheckboxesTitle('ProtocolPdf.AiArCertificateForSuccessCheckboxesTitle');
    this.addCheckboxSpace();
    this.addInspectionCheckboxesCheckbox('ProtocolPdf.AiArCertificateForSuccessCheckbox1');
    this.addNewline();
    this.addInspectionCheckboxesCheckbox('ProtocolPdf.AiArCertificateForSuccessCheckbox2');
    this.addInspectionTextboxAndTextAfterCheckbox('ProtocolPdf.AiArCertificateForSuccessCheckbox3');
    this.addNewline(1);
    this.addInspectionCheckboxesComments(2);
    this.removeCheckboxSpace();
  }

  private addSpaceBetweenBlocks(numberOfLines: number = 2): void {
    this.baseY += ProtocolPdfConstants.lineHeight * numberOfLines;
  }

  private addInspectionCheckboxesForFails(): void {
    this.addInspectionCheckboxesTitle('ProtocolPdf.AiArCertificateForFailCheckboxesTitle');
    this.addCheckboxSpace();
    this.addInspectionCheckboxesCheckbox('ProtocolPdf.AiArCertificateForFailCheckbox1');
    this.addNewline();
    this.addInspectionCheckboxesCheckbox('ProtocolPdf.AiArCertificateForFailCheckbox2');
    this.addNewline();
    this.addInspectionCheckboxesComments(2);
    this.removeCheckboxSpace();
  }

  private addCheckboxSpace(): void {
    this.checkbox.positionX += this.prefixCheckboxSpace;
  }

  private removeCheckboxSpace(): void {
    this.checkbox.positionX -= this.prefixCheckboxSpace;
  }

  private addInspectionCheckboxesTitle(translationKey: string): void {
    this.checkbox.text = this.translateService.instant(translationKey);
    this.checkbox.maxTextWidth = ProtocolPdfConstants.maxXValue;
    this.checkbox.currentPositionY = this.baseY;
    this.baseY = this.addCheckbox(this.checkbox);
    this.addNewline();
  }

  private addInspectionCheckboxesCheckbox(translationKey: string): void {
    this.checkbox.text = this.translateService.instant(translationKey);
    this.checkbox.currentPositionY = this.baseY;
    this.baseY = this.addCheckbox(this.checkbox);
  }

  private addInspectionTextboxAndTextAfterCheckbox(translationKey: string): void {
    const separationSpace = 1.5;
    const positionX =
      this.checkbox.positionX + this.pdfDocument.getTextWidth(this.checkbox.text) + separationSpace;
    const dateTextboxWidth = 20;
    const textboxPdfField: PdfField = { x: positionX, y: this.baseY, width: dateTextboxWidth };
    this.addAcroTextbox(textboxPdfField);
    textboxPdfField.width = positionX + dateTextboxWidth;
    this.addLineAboveTextbox(textboxPdfField);
    this.writeText(
      this.translateService.instant(translationKey),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - textboxPdfField.width,
      textboxPdfField.width,
      this.baseY
    );
  }

  private addNewline(numberOfLines: number = 1): void {
    this.baseY += this.getNewLine(numberOfLines);
  }

  private addInspectionCheckboxesComments(numberOfLines: number): void {
    this.checkbox.text = '';
    this.checkbox.currentPositionY = this.baseY;
    this.baseY = this.addCheckbox(this.checkbox);
    const positionX = this.checkbox.positionX;
    const commentsTextboxWidth = ProtocolPdfConstants.maxLineWidth - positionX;
    for (let i = 0; numberOfLines > i; i++) {
      const textboxPdfField: PdfField = {
        x: positionX,
        y: this.baseY,
        width: commentsTextboxWidth,
      };
      this.addAcroTextbox(textboxPdfField);
      textboxPdfField.width = this.checkbox.positionX + commentsTextboxWidth;
      this.addLineAboveTextbox(textboxPdfField);
      this.addNewline();
    }
  }

  private addLabelSignatureInspectionPerson(): void {
    this.baseY = this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureInspectionPerson'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      this.baseY,
      1
    );
  }

  private addLineAboveTextbox(textboxPdfField: PdfField): void {
    this.pdfDocument.setLineWidth(0.3);
    const positionY = textboxPdfField.y + ProtocolPdfConstants.lineHeight;
    this.pdfDocument.line(textboxPdfField.x, positionY, textboxPdfField.width, positionY);
  }
}
