import { Injectable, Output, EventEmitter } from '@angular/core';
import { AccountService, HeartbeatService } from './api';
import { TranslateService } from '@ngx-translate/core';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { Observable, of } from 'rxjs';
import { map, catchError, exhaustMap } from 'rxjs/operators';
import { UserDBService } from './db/user-db-service';
import { Role } from './api/model/role';
import { MKDEUserDto } from './api/model/mKDEUserDto';

@Injectable()
export class ConnectionStateService {
  @Output()
  public userLoaded = new EventEmitter<MKDEUserDto>();
  public loggedIn: boolean;
  public user: MKDEUserDto;
  public onlineCheckInProgress = false;

  private timeout = 3000;
  private lastConnectionCheck = 0;

  public constructor(
    private accountService: AccountService,
    private alerts: MkdeAlertHolderDirective,
    private translateService: TranslateService,
    private heartbeatService: HeartbeatService,
    private userDBService: UserDBService
  ) {
    if (window.location.hash === '#loginSuccessful') {
      this.loggedIn = true;
      window.location.hash = '';
      this.readAndStoreAuthenticatedUser();
    } else if (window.location.hash === '#loginFailed') {
      window.location.hash = '';
      this.alerts.danger(
        this.translateService.instant(
          'Sie verfügen nicht über ausreichende Berechtigungen für diese Applikation.'
        ),
        null
      );
    }
  }

  public toggleLogin() {
    this.onlineCheckInProgress = true;
    if (Date.now() - this.lastConnectionCheck > 30000) {
      this.timeout = 3000;
    } else {
      this.timeout += 3000;
      if (this.timeout > 30000) {
        this.timeout = 30000;
      }
    }
    this.lastConnectionCheck = Date.now();

    this.hasConnection(true, this.timeout).subscribe(
      (hasConnection) => {
        this.onlineCheckInProgress = false;
        if (hasConnection) {
          if (this.loggedIn) {
            window.open(
              `${document.location.origin}/api/account/logout?returnUrl=${encodeURIComponent(
                window.location.toString()
              )}`,
              '_self'
            );
          } else {
            window.open(
              `${document.location.origin}/api/account/login?returnUrl=${encodeURIComponent(
                window.location.toString()
              )}`,
              '_self'
            );
          }
        }
      },
      () => {
        this.onlineCheckInProgress = false;
      }
    );
  }

  public getIsUserAdmin(): boolean {
    const isAdmin =
      this.user && this.user.roles && this.user.roles.some((r) => r === Role.AcontrolAdminK);
    return isAdmin;
  }

  public isUserOnlyInspector(): boolean {
    const hasRoles = this.user && this.user.roles && this.user.roles.length > 0;
    if (hasRoles) {
      const acontrolRoles = this.getOnlyAControlRoles();
      if (acontrolRoles.length <= 0) {
        return true;
      }
      return acontrolRoles.length === 1 && acontrolRoles.some((r) => r === Role.AcontrolInspector);
    }
    return true;
  }

  public hasConnection(showError: boolean = true, timeout?: number): Observable<boolean> {
    if (timeout) {
      this.heartbeatService.defaultHeaders = this.heartbeatService.defaultHeaders.set(
        'timeout',
        timeout.toString()
      );
    }
    return this.heartbeatService.getHeartbeat().pipe(
      map(() => true),
      catchError(() => {
        if (showError) {
          this.alerts.info(
            this.translateService.instant(
              'Aktuell besteht keine Verbindung zum Server. Sie können aber weiterhin offline arbeiten (Ergebnisse erfassen und Protokoll erstellen).'
            )
          );
        }
        return of(false);
      })
    );
  }

  public readAndStoreAuthenticatedUser() {
    this.accountService
      .getAuthenticatedUser()
      .pipe(
        exhaustMap((user: MKDEUserDto) => {
          this.user = user;
          return this.userDBService.putUser(user).pipe(
            catchError((error) => {
              console.log(error);
              this.alerts.danger(
                this.translateService.instant('Benutzer konnte nicht gespeichert werden.'),
                error
              );
              return of(false);
            })
          );
        })
      )
      .subscribe(
        (success: string) => {
          if (!success) {
            return;
          }
          console.log(success);
          this.userLoaded.emit(this.user);
        },
        (err: any) => {
          console.log(err);
          this.alerts.danger(
            this.translateService.instant('Benutzer konnte nicht von Acontrol gelesen werden.'),
            err
          );
        }
      );
  }

  private getOnlyAControlRoles(): string[] {
    const roles: string[] = [];
    this.user.roles.forEach((role) => {
      if (isNaN(Number(role)) && role.startsWith('acontrol')) {
        roles.push(role);
      }
    });
    return roles;
  }
}
