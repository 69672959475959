<div class="modal-header bg-dark">
  <h4 class="modal-title" translate>Über ACmobile</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"
    tabindex="-1"></button>
</div>
<div class="modal-body">
  <div *ngIf="acmobileAbout" class="multiline" [innerHTML]="acmobileAbout | i18nText"></div>
  <div
    class="mt-4 clickable"
    (click)="versionInfoCollapsed = !versionInfoCollapsed"
    aria-expanded="!versionInfoCollapsed"
    aria-controls="collapse-version-info">
    <span translate>Version-Info</span>
    <button class="btn btn-link btn-details-toggle">
      <i
        class="fas"
        [ngClass]="{
          'fa-chevron-circle-down': versionInfoCollapsed,
          'fa-chevron-circle-up': !versionInfoCollapsed
        }"></i>
    </button>
  </div>
  <div [ngbCollapse]="versionInfoCollapsed" id="collapse-version-info">
    <div class="small">
      <span translate>ACmobile-Client.Version:</span>&nbsp;<span>{{ acmobileClientVersion }}</span>
    </div>
    <div class="small">
      <span translate>ACmobile-Services.Version:</span>&nbsp;<span
        *ngIf="acmobileServicesVersion"
        >{{ acmobileServicesVersion }}</span
      ><span *ngIf="!acmobileServicesVersion" translate>Nicht verfügbar</span>
    </div>
    <div class="small">
      <span translate>Client-Config.Version:</span>&nbsp;<span *ngIf="clientConfigVersion">{{
        clientConfigVersion
      }}</span
      ><span *ngIf="!clientConfigVersion" translate>Nicht verfügbar</span>
    </div>
    <div class="small">
      <span translate>Inspection-Type-Config.Version:</span>&nbsp;<span
        *ngIf="inspectionTypeConfigVersion"
        >{{ inspectionTypeConfigVersion }}</span
      ><span *ngIf="!inspectionTypeConfigVersion" translate>Nicht verfügbar</span>
    </div>
    <div class="small">
      <span translate>Category-Config.Version:</span>&nbsp;<span *ngIf="categoryConfigVersion">{{
        categoryConfigVersion
      }}</span
      ><span *ngIf="!categoryConfigVersion" translate>Nicht verfügbar</span>
    </div>
    <div class="small">
      <span translate>Point-Config.Version:</span>&nbsp;<span *ngIf="pointConfigVersion">{{
        pointConfigVersion
      }}</span
      ><span *ngIf="!pointConfigVersion" translate>Nicht verfügbar</span>
    </div>
    <div class="small">
      <span translate>Point-To-Category-Mapping.Version:</span>&nbsp;<span
        *ngIf="pointToCategoryMappingConfigVersion"
        >{{ pointToCategoryMappingConfigVersion }}</span
      ><span *ngIf="!pointToCategoryMappingConfigVersion" translate>Nicht verfügbar</span>
    </div>
    <div class="small">
      <span translate>Point-To-Point-Group-Mapping.Version:</span>&nbsp;<span
        *ngIf="pointToPointGroupMappingConfigVersion"
        >{{ pointToPointGroupMappingConfigVersion }}</span
      ><span *ngIf="!pointToPointGroupMappingConfigVersion" translate>Nicht verfügbar</span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div>
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.dismiss()"
      appAutofocus
      translate>
      Schliessen
    </button>
  </div>
</div>
