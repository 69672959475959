import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PointConfig } from '../api/model/pointConfig';
import { ClientConfigDBService } from '../db/client-config-db-service';

@Injectable()
export class PointConfigResolver implements Resolve<PointConfig> {
  public constructor(private clientConfigDbService: ClientConfigDBService) {}

  public resolve() {
    return this.clientConfigDbService.getPointConfig();
  }
}
