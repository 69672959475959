/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Injectable } from '@angular/core';
/**
 * Styles nested FormControlComponents.
 */
export abstract class FormLayout {
  abstract groupClasses: string;
  abstract labelClasses: string;
  abstract labelWrapperClasses: string;
  abstract controlWrapperClasses: string;
}

/**
 * The default bootstrap layout: single column, full-width, labels above inputs
 */
@Injectable()
export class VerticalFormLayout extends FormLayout {
  public groupClasses = 'd-block';
  public labelClasses = '';
  public labelWrapperClasses = '';
  public controlWrapperClasses = '';
}
