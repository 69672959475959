<div *ngIf="point.pointResult">
  <app-detail-result-button
    *ngIf="!point.pointResult.fieldValue"
    (resultChange)="onPointResultChanged()"
    [result]="point.pointResult"
    [isFkp]="point.isFkp"
    [defectDescriptionSuggestions]="point.defectDescriptionSuggestions"
    [readonly]="readonly"
    [elementName]="point.pointName"></app-detail-result-button>
  <form
    #form="ngForm"
    (focusout)="!form.invalid && onPointFieldValueChanged()"
    *ngIf="point.pointResult.fieldValue">
    <form-control
      *ngIf="point.pointResult.fieldValue.inspectionElementFieldType === fieldTypes.Text">
      <div
        ngbDropdown
        class="d-inline-block input-group-text"
        *ngIf="!isTextFieldValueSuggestionEmpty()">
        <button
          class="btn btn-outline-primary"
          id="dropdownTextFieldValueSuggestions"
          ngbDropdownToggle
          [disabled]="readonly"></button>
        <div ngbDropdownMenu aria-labelledby="dropdownTextFieldValueSuggestions">
          <button
            *ngFor="let textFieldValueSuggestion of getTextFieldValueSuggestionTranslation()"
            class="dropdown-item"
            ngbDropdownItem
            (click)="insertTextFieldValueSuggestion(textFieldValueSuggestion)">
            {{ textFieldValueSuggestion | i18nText }}
          </button>
        </div>
      </div>
      <input
        name="textValue"
        [(ngModel)]="point.pointResult.fieldValue.textValue"
        type="text"
        maxlength="255"
        [disabled]="readonly" />
    </form-control>
    <form-control
      *ngIf="point.pointResult.fieldValue.inspectionElementFieldType === fieldTypes.Number"
      invalidKey="InvalidNumber">
      <input
        name="numberValue"
        [(ngModel)]="point.pointResult.fieldValue.numberValue"
        type="number"
        [disabled]="readonly" />
      <div
      *ngIf="!readonly && displayCopyFieldValueNumber"
        ngbPopover="{{ 'Wert kopieren' | translate }}"
        placement="bottom"
        triggers="mouseenter:mouseleave">
        <button
          class="btn btn-outline-primary ms-1"
          (click)="onCopyFieldValueNumber()"
          [appDisable]="readonly">
          <span class="far fa-copy"></span>
        </button>
      </div>
    </form-control>
    <form-control
      *ngIf="point.pointResult.fieldValue.inspectionElementFieldType === fieldTypes.YesNo">
      <select
        name="yesNoValue"
        [(ngModel)]="point.pointResult.fieldValue.yesNoValue"
        [disabled]="readonly"
        class="form-select">
        <option [ngValue]="null"></option>
        <option [ngValue]="true" translate>Ja</option>
        <option [ngValue]="false" translate>Nein</option>
      </select>
    </form-control>
    <form-control
      *ngIf="point.pointResult.fieldValue.inspectionElementFieldType === fieldTypes.Date">
      <app-mkde-datepicker
        name="dateValue"
        [(ngModel)]="point.pointResult.fieldValue.dateValue"
        [disabled]="readonly"></app-mkde-datepicker>
    </form-control>
  </form>
</div>
