import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-mkde-alert',
  template: `
    <div
      class="alert fade alert-{{ type }}"
      [class.alert-dismissible]="dismissible"
      [class.show]="!fading"
      role="alert">
      <button
        type="button"
        class="btn-close btn-close-grey"
        (click)="fading = true; dismiss.emit()"
        *ngIf="dismissible"></button>
      <span class="fas {{ icons[type] }} me-2"></span>
      <ng-content></ng-content>
    </div>
  `,
})
export class MkdeAlertComponent {
  @Input()
  public type: string;

  @Output()
  public dismiss = new EventEmitter<void>();

  public fading = false;

  public icons = {
    danger: 'fa-exclamation-circle',
    warning: 'fa-exclamation-triangle',
    info: 'fa-info-circle',
    success: 'fa-check-circle',
  };

  public get dismissible() {
    return this.dismiss.observers.length;
  }
}
