<div class="modal-header bg-dark">
  <h4 class="modal-title" translate>Erfassung unvollständig</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <div *ngIf="!furtherActionSelectionValid" class="mb-2">
    <div
      class="multiline"
      translate="ConfirmStatusChangeMessageInvalidFurtherActionSelection"></div>
  </div>
  <hr *ngIf="!furtherActionSelectionValid && categoriesWithMissingDefectSeverity?.length" />
  <div *ngIf="categoriesWithMissingDefectSeverity?.length" class="mb-2">
    <div
      class="multiline"
      [translateParams]="{ number: categoriesWithMissingDefectSeverity.length }"
      translate="ConfirmStatusChangeMessageCategoriesWithMissingDefectSeverities"></div>
    <button
      class="btn btn-link btn-details-toggle"
      (click)="
        categoriesWithMissingDefectSeverityDetailsCollapsed =
          !categoriesWithMissingDefectSeverityDetailsCollapsed
      "
      aria-expanded="!categoriesWithMissingDefectSeverityDetailsCollapsed"
      aria-controls="collapse-missing-severity-details">
      <span *ngIf="categoriesWithMissingDefectSeverityDetailsCollapsed" class="me-1" translate
        >Details anzeigen</span
      >
      <span *ngIf="!categoriesWithMissingDefectSeverityDetailsCollapsed" class="me-1" translate
        >Details ausblenden</span
      >
      <i
        class="fas"
        [ngClass]="{
          'fa-chevron-circle-down': categoriesWithMissingDefectSeverityDetailsCollapsed,
          'fa-chevron-circle-up': !categoriesWithMissingDefectSeverityDetailsCollapsed
        }"></i>
    </button>
    <div
      [ngbCollapse]="categoriesWithMissingDefectSeverityDetailsCollapsed"
      id="collapse-missing-severity-details">
      <h6 class="mt-1" translate>Rubriken mit fehlendem Schweregrad</h6>
      <div class="mt-1" *ngFor="let category of categoriesWithMissingDefectSeverity">
        <span
          >{{ getCategoryName(category.categoryNumber) | i18nText }} -
          {{ getCategoryShortName(category.categoryNumber) | i18nText }}</span
        >
      </div>
    </div>
  </div>
  <div
    *ngIf="
      notDefinedPointsMap &&
      !categoriesWithMissingDefectSeverity?.length &&
      furtherActionSelectionValid
    "
    class="mb-2">
    <div
      class="multiline"
      translate="ConfirmStatusChangeMessageUndefinedPoints"
      [translateParams]="{ number: getNumberOfUndefinedPoints() }"></div>
    <button
      class="btn btn-link btn-details-toggle"
      (click)="undefinedPointsDetailsCollapsed = !undefinedPointsDetailsCollapsed"
      aria-expanded="!undefinedPointsDetailsCollapsed"
      aria-controls="collapse-undefined-points-details">
      <span *ngIf="undefinedPointsDetailsCollapsed" class="me-1" translate>Details anzeigen</span>
      <span *ngIf="!undefinedPointsDetailsCollapsed" class="me-1" translate
        >Details ausblenden</span
      >
      <i
        class="fas"
        [ngClass]="{
          'fa-chevron-circle-down': undefinedPointsDetailsCollapsed,
          'fa-chevron-circle-up': !undefinedPointsDetailsCollapsed
        }"></i>
    </button>
    <div [ngbCollapse]="undefinedPointsDetailsCollapsed" id="collapse-undefined-points-details">
      <h6 class="mt-1" translate>Übersicht der nicht beurteilten Punkte</h6>
      <div class="mt-1" *ngFor="let categoryPoints of notDefinedPointsMap | keyvalue">
        <span
          >{{ getCategoryName(categoryPoints.key) | i18nText }} -
          {{ getCategoryShortName(categoryPoints.key) | i18nText }} ({{
            categoryPoints.value.length
          }})</span
        >
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    *ngIf="
      notDefinedPointsMap &&
      !categoriesWithMissingDefectSeverity?.length &&
      furtherActionSelectionValid
    "
    type="button"
    class="btn btn-primary"
    (click)="setResultsToOK()"
    translate>
    Alle als OK markieren
  </button>
  <button
    *ngIf="
      notDefinedPointsMap &&
      !categoriesWithMissingDefectSeverity?.length &&
      furtherActionSelectionValid
    "
    type="button"
    class="btn btn-primary"
    (click)="setResultsToNK()"
    translate>
    Alle als NK markieren
  </button>
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="modal.dismiss()"
    appAutofocus
    translate>
    Abbrechen
  </button>
</div>
