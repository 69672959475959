import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable } from 'rxjs';
import { InspectionStatusDto } from '../api/model/models';

@Injectable()
export class InspectionStatusDBService {
  private readonly idInspectionStates = 'inspection_states';

  public constructor(private dbService: DBService) {}

  public getInspectionStates(): Observable<InspectionStatusDto[]> {
    return this.dbService.getById<InspectionStatusDto[]>(this.idInspectionStates);
  }

  public putInspectionStates(inspectionStates: InspectionStatusDto[]): Observable<string> {
    return this.dbService.put<InspectionStatusDto[]>(inspectionStates, this.idInspectionStates);
  }
}
