/**
 * MKDE Intermediary API
 * Application internal API, providing resources for the frontend.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InspectionStatus = 'planned' | 'cancelled' | 'working' | 'entered' | 'closed' | 'released' | 'discontinued' | 'sanctionsWorking' | 'sanctionsEntered' | 'appeal' | 'decisionReleased' | 'inProgress';

export const InspectionStatus = {
    Planned: 'planned' as InspectionStatus,
    Cancelled: 'cancelled' as InspectionStatus,
    Working: 'working' as InspectionStatus,
    Entered: 'entered' as InspectionStatus,
    Closed: 'closed' as InspectionStatus,
    Released: 'released' as InspectionStatus,
    Discontinued: 'discontinued' as InspectionStatus,
    SanctionsWorking: 'sanctionsWorking' as InspectionStatus,
    SanctionsEntered: 'sanctionsEntered' as InspectionStatus,
    Appeal: 'appeal' as InspectionStatus,
    DecisionReleased: 'decisionReleased' as InspectionStatus,
    InProgress: 'inProgress' as InspectionStatus
};

