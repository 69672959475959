import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb-browser';
import { Observable } from 'rxjs';
import { Semaphore } from 'prex';

@Injectable()
export class DBService {
  private db: PouchDB;
  private semaphore = new Semaphore(1);

  public constructor() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.db = new PouchDB('mkde-db', { revs_limit: 1, auto_compaction: true });
  }

  public getDB(): PouchDB {
    return this.db;
  }

  /**
   * Reads a document by ID.
   * If the document is not found, the result is null (no error).
   *
   * @param id ID of the document.
   * @returns Observable emitting the result or an error.
   */
  public getById<T>(id: string): Observable<T> {
    return new Observable((observer) => {
      this.db
        .get(id)
        .catch((err) => this.handleReadError(err))
        .then((doc) => {
          observer.next(doc.body);
          observer.complete();
        })
        .catch((err) => {
          observer.error(`Error reading document with id ${id} from local database: ${err}`);
        });
    });
  }

  /**
   * Reads all documents with a specific ID-prefix.
   *
   * @param idPrefix ID-prefix.
   * @returns Observable emitting the result or an error.
   */
  public getAll<T>(idPrefix: string): Observable<T[]> {
    return new Observable((observer) => {
      this.db
        .allDocs({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          include_docs: true,
          startkey: idPrefix,
          endkey: idPrefix + '\uffff',
        })
        .then((result) => {
          const documents: T[] = [];
          for (const row of result.rows) {
            documents.push(row.doc.body);
          }
          observer.next(documents);
          observer.complete();
        })
        .catch((err) => {
          observer.error(
            `Error reading all documents with id-prefix ${idPrefix} from local database: ${err}`
          );
        });
    });
  }

  /**
   * Creates or updates a document.
   *
   * @param document Document.
   * @param id ID of the document.
   * @returns Observable emitting the success or an error.
   */
  public put<T>(document: T, id: string): Observable<string> {
    return new Observable((observer) => {
      this.semaphore.wait().then(() => {
        this.db
          .get(id)
          .then((doc) => {
            doc.body = document;
            this.db.put(doc);
            observer.next(`Document with id ${id} updated in local database.`);
            observer.complete();
            this.semaphore.release();
          })
          .catch((err) => {
            if (err.status === 404) {
              this.db.put({
                _id: id,
                body: document,
              });
              observer.next(`Document with id ${id} created in local database.`);
              observer.complete();
              this.semaphore.release();
            } else {
              throw err;
            }
          })
          .catch((err) => {
            observer.error(`Error saving document with id ${id} into local database: ${err}`);
            this.semaphore.release();
          });
      });
    });
  }

  /**
   * Deletes a document.
   *
   * @param id ID of the document
   * @returns Observable emitting the success or an error.
   */
  public delete(id: string): Observable<string> {
    return new Observable((observer) => {
      this.semaphore.wait().then(() => {
        this.db
          .get(id)
          .then((doc) => {
            this.db.remove(doc);
            observer.next(`Document with id ${id} deleted from local database.`);
            observer.complete();
            this.semaphore.release();
          })
          .catch((err) => {
            observer.error(`Error deleting document with id ${id} from local database: ${err}`);
            this.semaphore.release();
          });
      });
    });
  }

  /**
   * Reads a file by ID.
   *
   * @param id ID of the document.
   * @returns Observable emitting the result or an error.
   */
  public getFileById(id: string): Observable<File> {
    return new Observable((observer) => {
      this.db
        .get(id, { attachments: true, binary: true })
        .then((doc) => {
          // eslint-disable-next-line no-underscore-dangle
          observer.next(doc._attachments.file.data);
          observer.complete();
        })
        .catch((err) => {
          observer.error(`Error reading document (file) with id ${id} from local database: ${err}`);
        });
    });
  }

  /**
   * Creates or updates a file. One file (attachement) per document is stored.
   *
   * @param file File.
   * @param id ID of the document.
   * @returns Observable emitting the success or an error.
   */
  public putFile(file: File, id: string): Observable<string> {
    return new Observable((observer) => {
      this.semaphore.wait().then(() => {
        this.db
          .get(id, { attachments: true, binary: true })
          .then((doc) => {
            // eslint-disable-next-line no-underscore-dangle
            doc._attachments.file.content_type = file.type;
            // eslint-disable-next-line no-underscore-dangle
            doc._attachments.file.data = file;
            this.db.put(doc).then(() => {
              observer.next(`Document (file) with id ${id} updated in local database.`);
              observer.complete();
              this.semaphore.release();
            });
          })
          .catch((err) => {
            if (err.status === 404) {
              this.db
                .put({
                  _id: id,
                  _attachments: {
                    file: {
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      content_type: file.type,
                      data: file,
                    },
                  },
                })
                .then(() => {
                  observer.next(`Document (file) with id ${id} created in local database.`);
                  observer.complete();
                  this.semaphore.release();
                });
            } else {
              this.semaphore.release();
              throw err;
            }
          })
          .catch((err) => {
            observer.error(
              `Error saving document (file) with id ${id} into local database: ${err}`
            );
            this.semaphore.release();
          });
      });
    });
  }

  private handleReadError(err): any {
    if (err.status === 404) {
      return {
        body: null,
      };
    } else {
      throw err;
    }
  }
}
