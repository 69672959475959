<div class="config-table-actions">
  <div
    class="config-table-action"
    ngbPopover="{{ 'DownloadConfig' | translate }}"
    placement="top"
    triggers="mouseenter:mouseleave">
    <button class="btn btn-primary" (click)="download.emit()">
      <span class="fas fa-download"></span>
    </button>
  </div>
  <div
    class="config-table-action"
    ngbPopover="{{ 'UploadConfig' | translate }}"
    placement="top"
    triggers="mouseenter:mouseleave">
    <label class="btn btn-primary btn-file">
      <input type="file" style="display: none" (change)="onFileSelect($event.target)" />
      <span class="fas fa-upload"></span>
    </label>
  </div>
</div>
