import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable } from 'rxjs';
import { ProtocolDto } from 'src/app/dtos/protocolDto';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class ProtocolDBService {
  private readonly idPrefixProtocol = 'protocol_';

  public constructor(private dbService: DBService) {}

  public getProtocol(inspectionKey: string): Observable<ProtocolDto> {
    const id = this.getProtocolId(inspectionKey);
    return this.dbService.getById<ProtocolDto>(id);
  }

  public putProtocol(protocol: ProtocolDto): Observable<string> {
    const id = this.getProtocolId(protocol.inspectionKey);
    return this.dbService.put<ProtocolDto>(protocol, id);
  }

  public deleteProtocol(inspectionKey: string): Observable<string> {
    return new Observable((observer: any) => {
      this.getProtocol(inspectionKey)
        .pipe(
          concatMap((protocol: ProtocolDto) => {
            if (protocol) {
              const id = this.getProtocolId(inspectionKey);
              return this.dbService.delete(id);
            }
            observer.next(
              `Inspection ${inspectionKey} has no protocol to delete from local database.`
            );
            observer.complete();
          })
        )
        .subscribe(
          (success: string) => {
            console.log(success);
            observer.next(
              `Protocol of inspection with key ${inspectionKey} deleted from local database.`
            );
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  private getProtocolId(inspectionKey: string): string {
    return `${this.idPrefixProtocol}${inspectionKey}`;
  }
}
