import { Component, Input, OnInit } from '@angular/core';
import { InspectionCategoryCacheService } from '../core/cache/inspection-category-cache-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InspectionDialogComponent } from './inspection-dialog.component';
import { FileDialogComponent } from '../file-dialog/file-dialog.component';
import { FileDBService } from '../core/db/file-db-service';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionWithResultsDto } from '../core/api/model/inspectionWithResultsDto';
import { TranslateService } from '@ngx-translate/core';
import { I18nTextPipe } from '@core/pipes/i18n-text.pipe';

@Component({
  selector: 'app-inspection-header',
  templateUrl: './inspection-header.component.html',
  styleUrls: ['./inspection-header.component.scss'],
})
export class InspectionHeaderComponent implements OnInit {
  @Input()
  public inspection: InspectionWithResultsDto;

  public unitSummary: string;
  public inspectionCategoryShortNames: I18NText[];
  public fileCount = 0;

  public constructor(
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private fileDBService: FileDBService,
    private i18nTextPipe: I18nTextPipe
  ) {}

  public ngOnInit(): void {
    this.unitSummary = this.getUnitSummary();
    this.inspectionCategoryShortNames = this.getInspectionCategoryShortNames();
    this.updateFileCount();
  }

  public openInspectionDialog(): void {
    const modal = this.modalService.open(InspectionDialogComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modal.componentInstance.inspection = this.inspection;
  }

  public openFileDialog(): void {
    const modal = this.modalService.open(FileDialogComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modal.componentInstance.inspectionKey = this.inspection.inspectionKey;
    modal.result.then(
      () => this.updateFileCount(),
      () => this.updateFileCount()
    );
  }

  public getInspectionIsPriority(): string {
    const isPriority =
      this.inspection.inspectionIsPriority !== null && this.inspection.inspectionIsPriority;
    const textToTranslate = isPriority ? 'Ja' : 'Nein';
    return this.translateService.instant(textToTranslate);
  }

  public getInspectionReason(value: I18NText): string {
    const inspectionReason = this.i18nTextPipe.transform(value);
    if (inspectionReason == null) {
      return '';
    }
    if (inspectionReason.includes(',')) {
      return inspectionReason.replace(', ', ',').replace(',', ', ');
    }
    return inspectionReason;
  }

  private getUnitSummary(): string {
    let address = this.inspection.unitStreetName;
    if (this.inspection.unitStreetNumber) {
      address = address
        ? address + ' ' + this.inspection.unitStreetNumber
        : this.inspection.unitStreetNumber;
    }
    let summary = this.inspection.unitName;
    if (address) {
      summary += ', ' + address;
    }
    if (this.inspection.unitPostalZone) {
      summary += ', ' + this.inspection.unitPostalZone;
      if (this.inspection.unitTown) {
        summary += ' ' + this.inspection.unitTown;
      }
    } else if (this.inspection.unitTown) {
      summary += ', ' + this.inspection.unitTown;
    }
    return summary;
  }

  private getInspectionCategoryShortNames(): I18NText[] {
    return this.inspectionCategoryCacheService.getInspectionCategoryShortNames(
      this.inspection.categoryResults.map((categoryResult) => categoryResult.categoryNumber)
    );
  }

  private updateFileCount() {
    this.fileDBService.getFileNames(this.inspection.inspectionKey).subscribe((fileNames) => {
      this.fileCount = fileNames ? fileNames.length : 0;
    });
  }
}
