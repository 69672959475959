import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { I18nTextPipe } from '../core/pipes/i18n-text.pipe';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionResultType } from '../core/api/model/inspectionResultType';

@Component({
  selector: 'app-detail-result-remark',
  templateUrl: './detail-result-remark.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailResultRemarkComponent {
  @Input()
  public result: FlatInspectionResultDto;

  @Input()
  public elementName: I18NText;

  @Input()
  public readonly = false;

  @Input()
  public defectDescriptionSuggestions: I18NText[];

  @Output()
  public remarkChange: EventEmitter<any> = new EventEmitter();

  public modal: NgbActiveModal;

  public resultTypes = InspectionResultType;

  public constructor(private modalService: NgbModal, private i18nTextPipe: I18nTextPipe) {}

  public openDialog(content): void {
    this.modal = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      centered: true,
    });
  }

  public insertElementName(): void {
    const pointName = this.i18nTextPipe.transform(this.elementName);
    if (this.result.defectDescription != null) {
      this.result.defectDescription = pointName + ': ' + this.result.defectDescription;
    } else {
      this.result.defectDescription = pointName + ': ';
    }
    this.trimDefectDescription();
    this.saveResult();
  }

  public insertDefectDescriptionSuggestion(defectDescriptionSuggestion: I18NText): void {
    const suggestion = this.i18nTextPipe.transform(defectDescriptionSuggestion);
    if (this.result.defectDescription == null || this.result.defectDescription === '') {
      this.result.defectDescription = suggestion;
    } else {
      this.result.defectDescription += ' ' + suggestion;
    }
    this.trimDefectDescription();
    this.saveResult();
  }

  public saveResult(): void {
    this.remarkChange.emit();
  }

  private trimDefectDescription(): void {
    this.result.defectDescription = this.result.defectDescription.substring(0, 2000);
  }
}
