<div class="result-dropdown-container detail-category-result">
  <select
    #resultType
    class="form-control result-dropdown form-select"
    [ngClass]="{ 'result-dropdown-pull-left': !isDefect() }"
    [(ngModel)]="localResultType"
    (change)="onChangeResult(); $event.stopPropagation()"
    [disabled]="readonly">
    <option [ngValue]="resultTypes.NotDefined"></option>
    <option [ngValue]="null" translate>OK</option>
    <option [ngValue]="resultTypes.NotApplicable" translate>NZ</option>
    <option [disabled]="!hasDefects" [ngValue]="resultTypes.Defect" translate>M</option>
    <option [ngValue]="resultTypes.NotControlled" translate>NK</option>
  </select>
  <select
    name="defectSeverity"
    *ngIf="result.inspectionResult === resultTypes.Defect"
    class="form-control form-control-defect-severity form-select"
    [(ngModel)]="result.defectSeverity"
    (change)="onChangeDefectSeverity(); $event.stopPropagation()"
    [disabled]="readonly">
    <option [ngValue]="null" translate>Schweregrad</option>
    <option [ngValue]="1" translate>Geringfügig</option>
    <option [ngValue]="2" translate>Wesentlich</option>
    <option [ngValue]="3" translate>Schwerwiegend</option>
  </select>
</div>
