import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18NText } from '../api/model/i18NText';

@Pipe({ name: 'i18nTextList', pure: false })
export class I18nTextListPipe implements PipeTransform {
  public constructor(private translateService: TranslateService) {}

  public transform(value: I18NText[]): string {
    let translatedValue = null;
    if (value && value.length) {
      const currentLanguage = this.translateService.currentLang;
      if (currentLanguage === 'fr') {
        translatedValue = value.map((v) => v.frenchText).join(', ');
      } else {
        translatedValue = value.map((v) => v.germanText).join(', ');
      }
    }

    return translatedValue;
  }
}
