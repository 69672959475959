import { Directive, ElementRef, forwardRef, Input, Renderer2 } from '@angular/core';
import { FormLayout } from './form-layout';
import { FormColumnsDirective } from './form-columns.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form-row',
  host: {
    class: 'form-row bsg-form-group',
  },
  providers: [{ provide: FormLayout, useExisting: forwardRef(() => FormRowDirective) }],
})
export class FormRowDirective extends FormColumnsDirective {
  public groupClasses = '';

  public constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super();
  }

  @Input() public set removeClass(value: string) {
    this.renderer.removeClass(this.elementRef.nativeElement, value);
  }
}
