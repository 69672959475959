import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable } from 'rxjs';
import { InspectionElementDto } from '../api/model/models';

@Injectable()
export class InspectionCategoryDBService {
  private readonly idPrefixInspectionCategory = 'inspection_category_';

  public constructor(private dbService: DBService) {}

  public getInspectionCategory(inspectionCategoryNumber: string): Observable<InspectionElementDto> {
    const id = this.getInspectionCategoryId(inspectionCategoryNumber);
    return this.dbService.getById<InspectionElementDto>(id);
  }

  public getAllInspectionCategories(): Observable<InspectionElementDto[]> {
    return this.dbService.getAll(this.idPrefixInspectionCategory);
  }

  public putInspectionCategory(inspectionCategory: InspectionElementDto): Observable<string> {
    const id = this.getInspectionCategoryId(inspectionCategory.number);
    return this.dbService.put<InspectionElementDto>(inspectionCategory, id);
  }

  private getInspectionCategoryId(inspectionCategoryNumber: string): string {
    return `${this.idPrefixInspectionCategory}${inspectionCategoryNumber}`;
  }
}
