import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { InspectionTypeConfig } from '../api/model/inspectionTypeConfig';
import { ClientConfigDBService } from '../db/client-config-db-service';

@Injectable()
export class InspectionTypeConfigResolver implements Resolve<InspectionTypeConfig> {
  public constructor(private clientConfigDbService: ClientConfigDBService) {}

  public resolve() {
    return this.clientConfigDbService.getInspectionTypeConfig();
  }
}
