import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * Provides default validation messages to nested FormControlComponents.
 */
@Injectable()
export class ValidationMessageService {
  public constructor(protected translate: TranslateService) {}

  public messagesFor(label: string, errors: ValidationErrors) {
    const messages: string[] = [];
    for (const type in errors) {
      if (errors.hasOwnProperty(type)) {
        messages.push(this.messageFor(label, type, errors[type]));
      }
    }
    return messages;
  }

  protected messageFor(label: string, type: string, info): string {
    switch (type) {
      case 'required':
        return this.translate.instant('{{label}} required', { label });
      case 'ngbDate':
        return this.translate.instant('{{label}} must be a valid date of the form 31.12.2018', {
          label,
        });
      default:
        throw new Error('missing message for validator of type ' + type);
    }
  }
}
