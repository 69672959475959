import { Directive, ElementRef, Optional } from '@angular/core';
import { FormControlComponent } from './form-control.component';

/**
 * Registers the element this directive is applied as input with the parent form-control (if a parent form-control exists).
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[registerFormControlInput]',
})
export class RegisterFormControlInputDirective {
  public constructor(@Optional() formControl: FormControlComponent, elementRef: ElementRef) {
    if (formControl) {
      formControl.registerChild(elementRef);
    }
  }
}
