import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import {
  CategoryConfig,
  InspectionElementDto,
  I18NText,
  FlatInspectionCategoryResultDto,
} from '../api/model/models';
import { I18nTextPipe } from '../pipes/i18n-text.pipe';

@Injectable()
export class InspectionCategoryCacheService {
  private categoryConfig: CategoryConfig;
  private inspectionCategories: InspectionElementDto[];

  public constructor(private i18nTextPipe: I18nTextPipe) {}

  public setCache(categoryConfig: CategoryConfig, inspectionCategories: InspectionElementDto[]) {
    this.categoryConfig = categoryConfig;
    this.inspectionCategories = inspectionCategories;
  }

  public getInspectionCategoryShortName(inspectionCategoryNumber: string): I18NText {
    return this.getInspectionCategoryShortNames([inspectionCategoryNumber])[0];
  }

  public getInspectionCategoryShortNames(inspectionCategoryNumbers: string[]): I18NText[] {
    let shortNames: I18NText[];
    if (this.categoryConfig && this.categoryConfig.categories) {
      shortNames = inspectionCategoryNumbers.map((inspectionCategoryNumber) => {
        const categoryConfig = this.categoryConfig.categories.find(
          (c) => c.number === inspectionCategoryNumber
        );
        if (categoryConfig) {
          return {
            germanText: categoryConfig.shortLabelDe,
            frenchText: categoryConfig.shortLabelFr,
            italianText: null,
          };
        } else {
          const category = this.inspectionCategories.find(
            (c) => c.number === inspectionCategoryNumber
          );
          return {
            germanText: category.number,
            frenchText: category.number,
            italianText: category.number,
          };
        }
      });
    } else {
      shortNames = inspectionCategoryNumbers.map((inspectionCategoryNumber) => {
        const category = this.inspectionCategories.find(
          (c) => c.number === inspectionCategoryNumber
        );
        return {
          germanText: category.number,
          frenchText: category.number,
          italianText: category.number,
        };
      });
    }
    return _.sortBy(shortNames, (shortName: I18NText) => {
      const localizedShortName = this.i18nTextPipe.transform(shortName);
      return localizedShortName ? localizedShortName.toLowerCase() : null;
    });
  }

  public getInspectionCategoryName(inspectionCategory: FlatInspectionCategoryResultDto): I18NText {
    const category = this.inspectionCategories.find(
      (c) => c.number === inspectionCategory.categoryNumber
    );
    let categoryName: I18NText = {
      germanText: category.name.germanText,
      frenchText: category.name.frenchText,
      italianText: category.name.italianText,
    };
    if (this.categoryConfig && this.categoryConfig.categories) {
      const categoryConfig = this.categoryConfig.categories.find(
        (c) => c.number === inspectionCategory.categoryNumber
      );
      if (categoryConfig) {
        categoryName = {
          germanText: categoryConfig.labelDe,
          frenchText: categoryConfig.labelFr,
          italianText: null,
        };
      }
    }
    return categoryName;
  }

  public getInspectionCategoryPresentationType(
    inspectionCategory: FlatInspectionCategoryResultDto
  ): string {
    if (this.categoryConfig && this.categoryConfig.categories) {
      const categoryConfig = this.categoryConfig.categories.find(
        (c) => c.number === inspectionCategory.categoryNumber
      );
      if (categoryConfig && categoryConfig.presentationType) {
        return categoryConfig.presentationType;
      }
    }
    return 'List';
  }

  public getDisplayCategoryResult(inspectionCategory: FlatInspectionCategoryResultDto): boolean {
    if (this.categoryConfig && this.categoryConfig.categories) {
      const config = this.categoryConfig.categories.find(
        (c) => c.number === inspectionCategory.categoryNumber
      );
      if (config) {
        return config.displayCategoryResult;
      }
    }
    return true;
  }

  public getDisplayInspectionDataOnTopOfProtocolPdf(inspectionCategoryNumber: string): boolean {
    if (this.categoryConfig && this.categoryConfig.categories) {
      const config = this.categoryConfig.categories.find(
        (c) => c.number === inspectionCategoryNumber
      );
      if (config && config.displayInspectionDataOnTopOfProtocolPdf) {
        return config.displayInspectionDataOnTopOfProtocolPdf;
      }
    }
    return false;
  }

  public getDisplayResultWithoutDefectInProtocolPdf(inspectionCategoryNumber: string): boolean {
    if (this.categoryConfig && this.categoryConfig.categories) {
      const config = this.categoryConfig.categories.find(
        (c) => c.number === inspectionCategoryNumber
      );
      if (config && config.displayResultWithoutDefectInProtocolPdf) {
        return config.displayResultWithoutDefectInProtocolPdf;
      }
    }
    return false;
  }

  public getInspectionCategories(): InspectionElementDto[] {
    return this.inspectionCategories;
  }
}
