<div class="modal-header bg-dark">
  <h4 class="modal-title" translate>{{ title }}</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="mb-2">
    <div class="multiline" translate>{{ text }}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-dialog-sm" (click)="modal.close()" translate>
    Ja
  </button>
  <button
    type="button"
    class="btn btn-outline-primary btn-dialog-sm"
    (click)="modal.dismiss()"
    appAutofocus
    translate>
    Nein
  </button>
</div>
