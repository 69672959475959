export * from './account.service';
import { AccountService } from './account.service';
export * from './clientConfiguration.service';
import { ClientConfigurationService } from './clientConfiguration.service';
export * from './heartbeat.service';
import { HeartbeatService } from './heartbeat.service';
export * from './inspection.service';
import { InspectionService } from './inspection.service';
export * from './inspectionCategory.service';
import { InspectionCategoryService } from './inspectionCategory.service';
export * from './inspectionStatus.service';
import { InspectionStatusService } from './inspectionStatus.service';
export * from './inspectionStyle.service';
import { InspectionStyleService } from './inspectionStyle.service';
export * from './saml.service';
import { SamlService } from './saml.service';
export * from './version.service';
import { VersionService } from './version.service';
export const APIS = [AccountService, ClientConfigurationService, HeartbeatService, InspectionService, InspectionCategoryService, InspectionStatusService, InspectionStyleService, SamlService, VersionService];
