import { InspectionResultType } from '../api/model/models';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { DefectTableParams } from './protocol-defect-table-params';
import { ProtocolPdfBeService } from './protocol-pdf-be.service';
import { ProtocolPdfResultDto } from 'src/app/dtos/ProtocolPdfResultDto';

export class ProtocolPdfBeDhbVertragskantoneService extends ProtocolPdfBeService {
  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = super.getDefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineBe.DhbVertragskantone';
    return defectTableParams;
  }

  protected getOptionsForCommentText1(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionForComment1.DhbVertragskantone');
  }

  protected getOptionsForCommentText2(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionForComment2.DhbVertragskantone');
  }

  protected getTextHeaderFurtherProcedureDetails(): string {
    return this.translateService.instant('ProtocolPdf.TextHeaderFurtherProcedureDetails');
  }

  protected getTextOptionFurtherProcedureDetails1(): string {
    return this.translateService.instant(
      'ProtocolPdf.TextOptionFurtherProcedureDetails1.DhbVertragskantone'
    );
  }

  protected getTextOptionFurtherProcedureDetails2(): string {
    return this.translateService.instant(
      'ProtocolPdf.TextOptionFurtherProcedureDetails2.DhbVertragskantone'
    );
  }

  protected getTextOptionFurtherProcedureDetails3(): string {
    return this.translateService.instant(
      'ProtocolPdf.TextOptionFurtherProcedureDetails3.DhbVertragskantone'
    );
  }

  protected getTextOptionFurtherProcedureDetails4(): string {
    return this.translateService.instant(
      'ProtocolPdf.TextOptionFurtherProcedureDetails4.DhbVertragskantone'
    );
  }

  protected getTextOptionFurtherProcedureDetails5(): string {
    return this.translateService.instant(
      'ProtocolPdf.TextOptionFurtherProcedureDetails5.DhbVertragskantone'
    );
  }

  protected getDefectTableHeaderMeasure(): string {
    return this.translateService.instant('ProtocolPdf.DefectTableHeaderProposedMeasure');
  }

  protected addCommissioningAuthorityBlock(protocolPdfDto: ProtocolPdfDto, baseY: number): number {
    const inspectionResultsWithOnTop = this.inspectionResultsService.getInspectionResultsByAccessor(
      {
        ...protocolPdfDto,
        inspectionResultAccessor: InspectionResultAccessor.fieldValueWithOnTopOfProtocolPdf,
      }
    );
    if (inspectionResultsWithOnTop && inspectionResultsWithOnTop.size > 0) {
      baseY = this.addCommissioningAuthorityBlockTable(baseY, inspectionResultsWithOnTop);
      baseY += this.getNewLine(1, 3);
    }
    return baseY;
  }

  protected addCommissioningAuthorityBlockTable(
    baseY: number,
    inspectionResults: Map<string, Map<string, ProtocolPdfResultDto[]>>
  ): number {
    const maxFirstRowWidth = ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth;

    const result = new Array<ProtocolPdfResultDto>();
    inspectionResults.forEach((element: Map<string, ProtocolPdfResultDto[]>, key: string) => {
      element.forEach((d) => {
        const results = d.filter(
          (a) => a.inspectionResult.inspectionResult !== InspectionResultType.Defect
        );
        if (results) {
          result.push(...results);
        }
      });
      result.forEach((path) => {
        const fieldName = this.getMultiLineText(
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          this.translateService.instant(this.i18nTextPipe.transform(path.pointName)) + ':',
          ProtocolPdfConstants.firstColumnWidth - 1
        );
        baseY = this.writeMultiLineText(
          fieldName,
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          ProtocolPdfConstants.marginX,
          baseY,
          0
        );
        const fieldValue = this.inspectionResultsService.getFieldValueOfLine(path.inspectionResult);
        const fieldKey = this.getMultiLineText(
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          fieldValue,
          ProtocolPdfConstants.maxXValue - maxFirstRowWidth
        );
        baseY = this.writeMultiLineText(
          fieldKey,
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          maxFirstRowWidth,
          baseY,
          0
        );
        baseY += ProtocolPdfConstants.lineHeight;
      });
    });
    return baseY;
  }

  protected getDisplayPointGroupNumber(): boolean {
    return true;
  }
}
