import { jsPDF } from 'jspdf';
import { InspectionWithResultsDto } from '../api/model/models';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfStandardService } from './protocol-pdf-standard.service';
import { DefectTableParams } from './protocol-defect-table-params';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { Constants } from '@core/constants';

export class ProtocolPdfBeService extends ProtocolPdfStandardService {
  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto) {
    const originalLanguage = this.translateService.currentLang;
    this.translateService.use(protocolPdfDto.language);
    this.pdfDocument = new jsPDF();
    let baseY = this.addPageHeader() + this.getNewLine(2, 2);
    baseY = this.addInspectionTitle(baseY, 'ProtocolPdf.HeaderProtocol');
    baseY =
      this.addInspectionHeader(protocolPdfDto.inspection, protocolPdfDto.protocol, baseY, false) +
      this.getNewLine(1, 3);
    protocolPdfDto.displayPointGroupNumber = this.getDisplayPointGroupNumber();
    baseY = this.addInspectionResultsWithoutDefect(protocolPdfDto, baseY);
    const inspectionResults = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.defectOrFieldValue,
    });
    baseY = this.addCommissioningAuthorityBlock(protocolPdfDto, baseY);
    baseY = this.addInspectionResults(protocolPdfDto, inspectionResults, baseY);
    baseY += inspectionResults.size ? 10 : ProtocolPdfConstants.lineHeight;
    baseY = this.addCommentOfDepartment(
      protocolPdfDto,
      baseY,
      'ProtocolPdf.LabelCommentOfDepartmentShortPlural'
    );
    baseY = this.addFurtherAction(protocolPdfDto.protocol, baseY);
    baseY = this.addFees(protocolPdfDto.protocol.fees, baseY);
    baseY = this.addSignatureBlock(protocolPdfDto.inspection, baseY) + this.getNewLine(2);
    if (inspectionResults.size) {
      baseY = this.addOptionsForComment(baseY) + this.getNewLine(1, 3);
      baseY = this.addFurtherProcedureDetails(baseY);
    }
    if (protocolPdfDto.protocol.furtherAction.feedback) {
      this.addInspectionResultsCorrections(
        protocolPdfDto.inspection,
        protocolPdfDto.protocol,
        inspectionResults
      );
    }
    this.addFooter(protocolPdfDto.inspection);
    this.pdfDocument.save('kontrolle-' + protocolPdfDto.inspection.inspectionId + '.pdf');
    this.translateService.use(originalLanguage);
  }

  protected addPageHeader(): number {
    const marginFirstHeaderColumn = 69;
    const marginSecondHeaderColumn = 118;
    const marginThirdHeaderColumn = 159;

    this.pdfDocument.setFont('helvetica');
    const imageData = this.getImageData(Constants.imageBe);
    this.addImage(imageData, 'jpg', 3, -0.6, 53, 30.8);
    this.pdfDocument.setFontSize(9);
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderBe1'),
      marginFirstHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage
    );
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderBe2'),
      marginFirstHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 4
    );
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderBe3'),
      marginFirstHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 8
    );

    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderAddressBe'),
      marginSecondHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage
    );
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderPostalZoneBe'),
      marginSecondHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 4
    );
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderZipCodeAndTownBe'),
      marginSecondHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 8
    );

    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderMailAddressBe'),
      marginThirdHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage
    );
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderWebsiteBe'),
      marginThirdHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 4
    );
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderPhoneNumberBe'),
      marginThirdHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 8
    );
    return 24;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    const multiLineTextResponsiblePersonPresent = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant('ProtocolPdf.TextResponsiblePersonPresent'),
      ProtocolPdfConstants.maxLineWidth
    );
    const multiLineTextInspector = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      inspection.inspectorName,
      ProtocolPdfConstants.signatureBlockWidthDottedLine - 1
    );
    const multiLineTextInspectorLength =
      multiLineTextInspector.length > 3 ? multiLineTextInspector.length : 3;
    const signatureBlockHeight =
      (multiLineTextResponsiblePersonPresent.length + multiLineTextInspectorLength + 6) *
      ProtocolPdfConstants.lineHeight;
    const margin2ndColumn =
      ProtocolPdfConstants.marginX + ProtocolPdfConstants.signatureBlockWidthLabel;
    const margin3rdColumn = margin2ndColumn + ProtocolPdfConstants.signatureBlockWidthDottedLine;
    const margin4thColumn = margin3rdColumn + ProtocolPdfConstants.signatureBlockWidthLabel;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    baseY = this.writeMultiLineText(
      multiLineTextResponsiblePersonPresent,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX,
      baseY,
      0
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelName'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    if (inspection.inspectorName) {
      this.writeMultiLineText(
        multiLineTextInspector,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        margin2ndColumn,
        baseY
      );
    } else {
      this.writeDottedLine(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.signatureBlockWidthDottedLine - 5,
        margin2ndColumn,
        baseY
      );
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelName'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      margin3rdColumn,
      baseY,
      1
    );
    this.writeDottedLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthDottedLine,
      margin4thColumn,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.SublabelInspector'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.SublabelResponsiblePerson'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      margin3rdColumn,
      baseY,
      1
    );
    baseY += multiLineTextInspectorLength * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeDottedLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthDottedLine - 5,
      margin2ndColumn,
      baseY
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      margin3rdColumn,
      baseY,
      1
    );
    this.writeDottedLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthDottedLine,
      margin4thColumn,
      baseY
    );
    baseY += 3 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignature'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeDottedLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthDottedLine - 5,
      margin2ndColumn,
      baseY
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignature'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      margin3rdColumn,
      baseY,
      1
    );
    this.writeDottedLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthDottedLine,
      margin4thColumn,
      baseY
    );
    return baseY;
  }

  protected getTextHeaderFurtherProcedureDetails(): string {
    return this.translateService.instant('ProtocolPdf.TextHeaderFurtherProcedureDetails');
  }

  protected getTextOptionFurtherProcedureDetails1(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetails1');
  }

  protected getTextOptionFurtherProcedureDetails2(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetails2');
  }

  protected getTextOptionFurtherProcedureDetails3(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetails3');
  }

  protected getTextOptionFurtherProcedureDetails4(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetails4');
  }

  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = new DefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineBe';
    defectTableParams.extraMarginColumnDeadline = 4;
    return defectTableParams;
  }

  protected validateHasControlledLocationValue(): boolean {
    return true;
  }

  protected addCommissioningAuthorityBlock(protocolPdfDto: ProtocolPdfDto, baseY: number): number {
    return baseY;
  }

  protected getDisplayPointGroupNumber(): boolean {
    return false;
  }
}
