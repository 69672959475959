<div [style.display]="displayStatusDingsbums ? 'none' : 'block'">
  <div class="modal-header bg-dark">
    <h4 class="modal-title" translate>Grunddaten</h4>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="modal.dismiss()"
      [disabled]="form.invalid"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid ms-0 ps-0 me-0 pe-0">
      <form column-classes="col-md-2,col-md-10" #form="ngForm" (focusout)="saveInspection()">
        <form-row column-classes="col-md-2,col-md-4">
          <form-control labelKey="Kontrolle.Datum">
            <app-mkde-datepicker
              name="inspectionDate"
              [showTodayButton]="true"
              required
              [(ngModel)]="inspection.inspectionDate"
              appAutofocus>
            </app-mkde-datepicker>
          </form-control>
        </form-row>

        <form-row column-classes="col-md-2,col-md-4">
          <form-control *ngIf="inspection.inspectionTypeName" labelKey="Kontrolle.Typ">
            <input
              name="inspectionType"
              type="text"
              [disabled]="true"
              [ngModel]="inspection.inspectionTypeName | i18nText" />
          </form-control>

          <form-control *ngIf="!inspection.inspectionTypeName" labelKey="Kontrolle.Typ">
            <input
              name="inspectionType"
              type="text"
              [disabled]="true"
              [ngModel]="inspectionCategoryShortNames | i18nTextList" />
          </form-control>

          <form-control labelKey="Kontrolle.Grund">
            <input
              name="inspectionReason"
              type="text"
              [disabled]="true"
              [ngModel]="inspection.inspectionReasonName | i18nText" />
          </form-control>
        </form-row>

        <form-row column-classes="col-md-2,col-md-4">
          <form-control labelKey="Kontrolle.Stelle">
            <input
              name="inspectionCenter"
              type="text"
              [disabled]="true"
              [ngModel]="inspection.inspectionCenterName" />
          </form-control>

          <form-control labelKey="Kontrolle.Art">
            <select
              name="InspectionStyle"
              [(ngModel)]="inspection.inspectionStyle"
              [compareWith]="sameKey"
              class="form-select">
              <option *ngFor="let inspectionStyle of inspectionStyles" [ngValue]="inspectionStyle">
                {{ inspectionStyle.name | i18nText }}
              </option>
            </select>
          </form-control>
        </form-row>

        <form-row column-classes="col-md-2,col-md-4">
          <form-control labelKey="Kontrolle.Kontrolleur">
            <input
              name="inspectionInspector"
              type="text"
              [disabled]="true"
              [ngModel]="inspection.inspectorName" />
          </form-control>

          <form-control labelKey="Kontrolle.Status">
            <select
              name="inspectionStatus"
              [(ngModel)]="tmpInspectionStatus"
              [compareWith]="sameEnumValue"
              (change)="$event.srcElement.blur(); changeInspectionStatus()"
              class="form-select">
              <option
                *ngFor="let inspectionStatus of inspectionStates"
                [ngValue]="inspectionStatus">
                {{ inspectionStatus.description | i18nText }}
              </option>
            </select>
          </form-control>
        </form-row>

        <form-control labelKey="Kontrolle.Bemerkung">
          <textarea
            name="inspectionRemark"
            rows="5"
            maxlength="4000"
            [(ngModel)]="inspection.inspectionRemark"></textarea>
        </form-control>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.dismiss()"
      [disabled]="form.invalid"
      translate>
      Schliessen
    </button>
  </div>
</div>
