import { Injectable } from '@angular/core';
import { InspectionCategoryCacheService } from './cache/inspection-category-cache-service';
import { ProtocolFurtherActionDto } from '../dtos/protocolfurtherActionDto';
import { InspectionResultType } from './api/model/inspectionResultType';
import { InspectionResultElementType } from './api/model/inspectionResultElementType';
import { PointGroupType } from './api/model/pointGroupType';
import {
  InspectionWithResultsDto,
  FlatInspectionResultDto,
  FlatInspectionCategoryResultDto,
  InspectionElementDto,
} from './api/model/models';

@Injectable({ providedIn: 'root' })
export class StatusChangeValidationService {
  public constructor(private inspectionCategoryCacheService: InspectionCategoryCacheService) {}

  public getNotDefinedPointsMap(
    inspection: InspectionWithResultsDto
  ): Map<string, FlatInspectionResultDto[]> {
    const inspectionCategories = this.inspectionCategoryCacheService.getInspectionCategories();
    const notDefinedPointsMap = new Map<string, FlatInspectionResultDto[]>();
    inspection.categoryResults.forEach((categoryResult) => {
      const notDefinedPointsForCategory = this.getNotDefinedPointsForInspectionElement(
        categoryResult.results,
        this.getCategoryDefinitionByNumber(categoryResult.categoryNumber, inspectionCategories)
      );
      if (notDefinedPointsForCategory.length) {
        notDefinedPointsMap.set(categoryResult.categoryNumber, notDefinedPointsForCategory);
      }
    }, notDefinedPointsMap);
    return notDefinedPointsMap;
  }

  public getCategoriesWithMissingDefectSeverity(
    inspection: InspectionWithResultsDto
  ): FlatInspectionCategoryResultDto[] {
    const inspectionCategories = this.inspectionCategoryCacheService.getInspectionCategories();
    return inspection.categoryResults.filter((categoryResult) =>
      this.hasCategoryMissingDefectSeverity(categoryResult, inspectionCategories)
    );
  }

  public isFurtherActionSelectionValid(furtherActions: ProtocolFurtherActionDto) {
    let furtherActionSelectionValid = false;
    if (furtherActions) {
      if (
        furtherActions.noFurtherAction &&
        !furtherActions.inspectionReport &&
        !furtherActions.criminalComplaint &&
        !furtherActions.feedback &&
        !furtherActions.followupInspection &&
        !furtherActions.repairsIndependently &&
        !furtherActions.inspectionReport &&
        !furtherActions.order
      ) {
        furtherActionSelectionValid = true;
      } else if (
        !furtherActions.noFurtherAction &&
        (furtherActions.repairsIndependently ||
          furtherActions.criminalComplaint ||
          furtherActions.feedback ||
          furtherActions.followupInspection ||
          furtherActions.furtherAction ||
          furtherActions.inspectionReport ||
          furtherActions.notification ||
          furtherActions.order)
      ) {
        furtherActionSelectionValid = true;
      }
    }
    return furtherActionSelectionValid;
  }

  private getNotDefinedPointsForInspectionElement(
    categoryResults: FlatInspectionResultDto[],
    inspectionElement: InspectionElementDto
  ): FlatInspectionResultDto[] {
    const notDefinedResults = new Array<FlatInspectionResultDto>();
    if (inspectionElement) {
      const elementResult = categoryResults.find(
        (result) => result.inspectionElementKey === inspectionElement.key
      );
      if (inspectionElement.type === InspectionResultElementType.Point) {
        if (!this.hasPointValue(inspectionElement, elementResult)) {
          notDefinedResults.push(elementResult);
        }
      } else {
        if (
          inspectionElement.pointGroupType === PointGroupType.SubheadWithoutDataEntry &&
          inspectionElement.childElements
        ) {
          inspectionElement.childElements.forEach((childElement) => {
            notDefinedResults.push.apply(
              notDefinedResults,
              this.getNotDefinedPointsForInspectionElement(categoryResults, childElement)
            );
          });
        } else if (
          (inspectionElement.pointGroupType === PointGroupType.Regular ||
            inspectionElement.type === InspectionResultElementType.Category) &&
          inspectionElement.childElements
        ) {
          if (
            !elementResult ||
            (elementResult.inspectionResult !== InspectionResultType.NotApplicable &&
              elementResult.inspectionResult !== InspectionResultType.NotControlled)
          ) {
            inspectionElement.childElements.forEach((childElement) => {
              notDefinedResults.push.apply(
                notDefinedResults,
                this.getNotDefinedPointsForInspectionElement(categoryResults, childElement)
              );
            });
          }
        }
      }
    }
    return notDefinedResults;
  }

  private hasCategoryMissingDefectSeverity(
    categoryResultDto: FlatInspectionCategoryResultDto,
    inspectionCategories: InspectionElementDto[]
  ): boolean {
    const inspectionCategory = this.getCategoryDefinitionByNumber(
      categoryResultDto.categoryNumber,
      inspectionCategories
    );
    const categoryResult = categoryResultDto.results.find(
      (result) => result.inspectionElementKey === inspectionCategory.key
    );
    if (categoryResult.inspectionResult !== InspectionResultType.Defect) {
      return false;
    }
    const displayCategoryResult =
      this.inspectionCategoryCacheService.getDisplayCategoryResult(categoryResultDto);
    if (displayCategoryResult === false) {
      return false;
    }
    return categoryResult && !categoryResult.defectSeverity;
  }

  private getCategoryDefinitionByNumber(
    categoryNumber: string,
    inspectionCategories: InspectionElementDto[]
  ) {
    return inspectionCategories.find((category) => category.number === categoryNumber);
  }

  private hasPointValue(pointElement: InspectionElementDto, result: FlatInspectionResultDto) {
    let hasPointValue = true;
    if (!result) {
      hasPointValue = false;
    } else if (
      !pointElement.fieldType &&
      result.inspectionResult === InspectionResultType.NotDefined
    ) {
      hasPointValue = false;
    }
    return hasPointValue;
  }
}
