import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfZhService } from './protocol-pdf-zh.service';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';

export class ProtocolPdfZhTnpService extends ProtocolPdfZhService {
  protected addSignatureAdditionalInformationCheckboxes(baseY: number) {
    const marginColumn = ProtocolPdfConstants.marginX + 80;
    const marginFirstText = 4;
    const marginSecondCheckbox = 55;
    const marginSecondText = 59;
    this.addAcroCheckBox(3, marginColumn, baseY);
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelUnitTnpAbsent'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      marginSecondCheckbox - marginFirstText - 1,
      marginColumn + marginFirstText,
      baseY
    );
    this.addAcroCheckBox(3, marginColumn + marginSecondCheckbox, baseY);
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelUnitAbsentLetter'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - marginSecondText - 1,
      marginColumn + marginSecondText,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.addAcroCheckBox(3, marginColumn + marginSecondCheckbox, baseY);
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelUnitAbsentDeposit'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - marginSecondText - 1,
      marginColumn + marginSecondText,
      baseY
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight + 10;
    return baseY;
  }

  protected addFurtherProcedureDetailsAbbreviations(baseY: number): number {
    return baseY;
  }

  protected getTextOptionFurtherProcedureDetails1(): string {
    return this.translateService.instant(`ProtocolPdf.TextOptionFurtherProcedureDetailsZhTnp1`);
  }

  protected getTextOptionFurtherProcedureDetails2(): string {
    return this.translateService.instant(`ProtocolPdf.TextOptionFurtherProcedureDetailsZhTnp2`);
  }

  protected getTextOptionFurtherProcedureDetails3(): string {
    return this.translateService.instant(`ProtocolPdf.TextOptionFurtherProcedureDetailsZhTnp3`);
  }

  protected getTextOptionFurtherProcedureDetails4(): string {
    return '';
  }

  protected addInspectionResultsWithOnTop(protocolPdfDto: ProtocolPdfDto, baseY: number): number {
    return this.addInspectionResultsDisplayedOnTop(protocolPdfDto, baseY);
  }
}
