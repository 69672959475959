import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InspectionCategoryCacheService } from '../core/cache/inspection-category-cache-service';
import * as _ from 'underscore';
import { EnumCacheService } from '../core/cache/enum-cache-service';
import { InspectionCategoryDetailDto } from '../dtos/inspectionCategoryDetailDto';
import { InspectionDBService } from '../core/db/inspection-db-service';
import { TranslateService } from '@ngx-translate/core';
import { AffectingPointDto } from '../dtos/affectingPointDto';
import { FieldValueChangedEventDto } from '../dtos/fieldValueChangedEventDto';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { CategoryConfig } from '../core/api/model/categoryConfig';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { InspectionElementDto } from '../core/api/model/inspectionElementDto';
import { InspectionStatusDto } from '../core/api/model/inspectionStatusDto';
import { InspectionStyleDto } from '../core/api/model/inspectionStyleDto';
import { PointToCategoryMappingConfig } from '../core/api/model/pointToCategoryMappingConfig';
import {
  FlatInspectionCategoryResultDto,
  InspectionResultFieldValueDto,
  InspectionWithResultsDto,
  PointToCategoryMapping,
  PointToPointGroupMapping,
} from '../core/api/model/models';
import { InspectionResultType } from '../core/api/model/inspectionResultType';
import { PointToPointGroupMappingConfig } from '../core/api/model/pointToPointGroupMappingConfig';
import { InspectionResultElementType } from '../core/api/model/inspectionResultElementType';
import { InspectionStatus } from '../core/api/model/inspectionStatus';
import { PointGroupType } from '../core/api/model/pointGroupType';
import { InspectionElementFieldType } from '../core/api/model/inspectionElementFieldType';

@Component({
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit {
  public inspection: InspectionWithResultsDto;
  public categoryDetailsOfInspection: InspectionCategoryDetailDto[];
  public notApplicableCategoriesDisplayed = false;
  private allCategories: InspectionElementDto[];
  private pointToCategoryMappingConfig: PointToCategoryMappingConfig;
  private pointToPointGroupMappingConfig: PointToPointGroupMappingConfig;

  public constructor(
    private route: ActivatedRoute,
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private enumCacheService: EnumCacheService,
    private inspectionDbService: InspectionDBService,
    private translateService: TranslateService,
    private alerts: MkdeAlertHolderDirective
  ) {}

  public ngOnInit(): void {
    this.inspection = this.route.snapshot.data['inspection'] as InspectionWithResultsDto;
    this.allCategories = this.route.snapshot.data['inspectionCategories'] as InspectionElementDto[];
    this.pointToCategoryMappingConfig = this.route.snapshot.data[
      'pointToCategoryMappingConfig'
    ] as PointToCategoryMappingConfig;
    this.pointToPointGroupMappingConfig = this.route.snapshot.data[
      'pointToPointGroupMappingConfig'
    ] as PointToPointGroupMappingConfig;

    // Set cache in case the user reloads the application on the detail view.
    this.initializeCaches();
    this.categoryDetailsOfInspection = this.getCategoryDetails();
    this.initializeInspectionStatus();
    this.initializeInspectionResults();
    this.saveInspection();
  }

  public getCategoryDetails(): InspectionCategoryDetailDto[] {
    const categories: InspectionCategoryDetailDto[] = this.inspection.categoryResults.map(
      (inspectionCategoryResult) => ({
        categoryShortName: this.inspectionCategoryCacheService.getInspectionCategoryShortName(
          inspectionCategoryResult.categoryNumber
        ),
        presentationType:
          this.inspectionCategoryCacheService.getInspectionCategoryPresentationType(
            inspectionCategoryResult
          ),
        categoryResult: inspectionCategoryResult,
        categoryNumber: inspectionCategoryResult.categoryNumber,
        categoryName:
          this.inspectionCategoryCacheService.getInspectionCategoryName(inspectionCategoryResult),
        categoryInspectionReason: inspectionCategoryResult.inspectionReason,
        displayCategoryResult:
          this.inspectionCategoryCacheService.getDisplayCategoryResult(inspectionCategoryResult),
        displayInspectionDataOnTopOfProtocolPdf:
          this.inspectionCategoryCacheService.getDisplayInspectionDataOnTopOfProtocolPdf(
            inspectionCategoryResult.categoryNumber
          ),
      })
    );
    return _.sortBy(categories, (category: any) => category.categoryNumber);
  }

  public getCategoriesToDisplay(): InspectionCategoryDetailDto[] {
    return this.categoryDetailsOfInspection.filter(
      (c) =>
        this.notApplicableCategoriesDisplayed ||
        this.getCategoryResult(c.categoryNumber).inspectionResult !==
          InspectionResultType.NotApplicable
    );
  }

  public getInspectionCategory(categoryNumber: string): InspectionElementDto {
    let inspectionCategory: InspectionElementDto = null;
    if (this.allCategories) {
      inspectionCategory = this.allCategories.find((c) => c.number === categoryNumber);
    }
    return inspectionCategory;
  }

  public saveInspection() {
    this.inspectionDbService.putInspectionWithResults(this.inspection).subscribe(
      (success: string) => {
        console.log(success);
      },
      (err: any) => {
        console.log(err);
        this.alerts.danger(
          this.translateService.instant('Die Kontrollresultate konnten nicht gespeichert werden.'),
          err
        );
      }
    );
  }

  public handlePointFieldValueChange(fieldValueChangedEvent: FieldValueChangedEventDto) {
    const numericValue = this.getNumericValue(fieldValueChangedEvent.pointResult.fieldValue);
    const previousNumericValue = this.getNumericValue(fieldValueChangedEvent.previousFieldValue);
    if (
      numericValue !== null &&
      !isNaN(numericValue) &&
      !(previousNumericValue > 0 && numericValue > 0)
    ) {
      const affectingPoint = this.getAffectingPoint(
        fieldValueChangedEvent.categoryNumber,
        fieldValueChangedEvent.pointResult.inspectionElementKey,
        numericValue
      );
      if (
        affectingPoint &&
        affectingPoint.categoryNumber &&
        affectingPoint.pointGroupNumber &&
        affectingPoint.pointNumber
      ) {
        this.handleAffectedPointGroups(affectingPoint);
        this.handleAffectedCategories(affectingPoint);
      }
    }
  }

  public isReadOnly() {
    return this.inspection.inspectionStatus.asEnum === InspectionStatus.Entered;
  }

  public isStatusEntered(): boolean {
    return this.inspection.inspectionStatus.asEnum === InspectionStatus.Entered;
  }

  private initializeCaches(): void {
    const categoryConfig = this.route.snapshot.data['categoryConfig'] as CategoryConfig;
    const inspectionStyles = this.route.snapshot.data['inspectionStyles'] as InspectionStyleDto[];
    const inspectionStates = this.route.snapshot.data['inspectionStates'] as InspectionStatusDto[];
    this.inspectionCategoryCacheService.setCache(categoryConfig, this.allCategories);
    this.enumCacheService.setCache(inspectionStyles, inspectionStates);
  }

  private initializeInspectionResults(): void {
    this.inspection.categoryResults.forEach((category) => {
      const categoryElement = this.allCategories.find((c) => c.number === category.categoryNumber);
      if (!category.results) {
        category.results = new Array<FlatInspectionResultDto>();
      }
      this.initializeResultsForElement(category.results, categoryElement);
    });
  }

  private initializeInspectionStatus(): void {
    if (this.inspection.inspectionStatus.asEnum === InspectionStatus.Planned) {
      this.inspection.inspectionStatus = this.enumCacheService
        .getInspectionStates()
        .find((s) => s.asEnum === InspectionStatus.Working);
    }
  }

  private getCategoryResult(categoryNumber: string): FlatInspectionResultDto {
    const categoryResults = this.inspection.categoryResults.find(
      (category) => category.categoryNumber === categoryNumber
    );
    return categoryResults.results.find(
      (result) => result.inspectionElementKey === this.getCategoryKey(categoryNumber)
    );
  }

  private getResult(
    results: FlatInspectionResultDto[],
    inspectionElementKey: string
  ): FlatInspectionResultDto {
    let result: FlatInspectionResultDto = null;
    if (results && results.length) {
      result = results.find((r) => r.inspectionElementKey === inspectionElementKey);
    }

    return result;
  }

  private getCategoryKey(categoryNumber: string): string {
    return this.allCategories.find((category) => category.number === categoryNumber).key;
  }

  private initializeResultsForElement(
    results: FlatInspectionResultDto[],
    inspectionElement: InspectionElementDto
  ): void {
    if (inspectionElement.pointGroupType !== PointGroupType.SubheadWithoutDataEntry) {
      const result = this.getResult(results, inspectionElement.key);
      if (!result) {
        if (!inspectionElement.fieldType) {
          results.push(
            this.getDefaultResult(inspectionElement.key, InspectionResultType.NotDefined)
          );
        } else {
          results.push(
            this.getDefaultFieldResult(inspectionElement.key, inspectionElement.fieldType)
          );
        }
      } else if (
        result.inspectionResult === InspectionResultType.NotControlled ||
        result.inspectionResult === InspectionResultType.NotApplicable
      ) {
        this.propagateResultToChildren(results, inspectionElement, result.inspectionResult);
      }
    }
    if (inspectionElement.childElements) {
      inspectionElement.childElements.forEach((childInspectionElement) => {
        this.initializeResultsForElement(results, childInspectionElement);
      });
    }
  }

  private propagateResultToChildren(
    results: FlatInspectionResultDto[],
    inspectionElement: InspectionElementDto,
    resultType: InspectionResultType
  ): void {
    if (inspectionElement.childElements) {
      inspectionElement.childElements.forEach((childInspectionElement) => {
        if (childInspectionElement.pointGroupType !== PointGroupType.SubheadWithoutDataEntry) {
          const existingResult = this.getResult(results, childInspectionElement.key);
          if (existingResult) {
            if (
              existingResult.inspectionResult !== resultType &&
              !childInspectionElement.fieldType
            ) {
              this.changeResultType(existingResult, resultType);
            }
          } else {
            if (!childInspectionElement.fieldType) {
              results.push(this.getDefaultResult(childInspectionElement.key, resultType));
            } else {
              results.push(
                this.getDefaultFieldResult(
                  childInspectionElement.key,
                  childInspectionElement.fieldType
                )
              );
            }
          }
        }
        this.propagateResultToChildren(results, childInspectionElement, resultType);
      });
    }
  }

  private getDefaultResult(
    inspectionElementKey: string,
    resultType: InspectionResultType
  ): FlatInspectionResultDto {
    return {
      amountAffected: null,
      complaintRemedyDateDue: null,
      defectDescription: null,
      defectRepetition: null,
      defectSeverity: null,
      fieldValue: null,
      inspectionElementKey,
      inspectionResult: resultType,
      livestockOwnerFeedbackDateDue: null,
      remark: null,
    };
  }

  private getDefaultFieldResult(
    inspectionElementKey: string,
    fieldType: InspectionElementFieldType
  ): FlatInspectionResultDto {
    const result = this.getDefaultResult(inspectionElementKey, null);
    result.fieldValue = {
      dateValue: null,
      numberValue: null,
      textValue: null,
      yesNoValue: null,
      inspectionElementFieldType: fieldType,
    };
    return result;
  }

  private changeResultType(
    result: FlatInspectionResultDto,
    newResultType: InspectionResultType
  ): void {
    result.amountAffected = null;
    result.complaintRemedyDateDue = null;
    result.defectDescription = null;
    result.defectRepetition = null;
    result.defectSeverity = null;
    result.fieldValue = null;
    result.inspectionResult = newResultType;
    result.livestockOwnerFeedbackDateDue = null;
    result.remark = null;
  }

  private getNumericValue(fieldValue: InspectionResultFieldValueDto): number {
    let numericValue: number = null;
    if (fieldValue) {
      if (
        fieldValue.inspectionElementFieldType === InspectionElementFieldType.Number &&
        fieldValue.numberValue != null
      ) {
        numericValue = fieldValue.numberValue;
      } else if (
        fieldValue.inspectionElementFieldType === InspectionElementFieldType.Text &&
        fieldValue.textValue &&
        typeof fieldValue.textValue === 'number'
      ) {
        numericValue = Number(fieldValue.textValue);
      } else if (
        fieldValue.inspectionElementFieldType === InspectionElementFieldType.YesNo &&
        fieldValue.yesNoValue != null
      ) {
        numericValue = fieldValue.yesNoValue ? 1 : 0;
      }
    }
    return numericValue;
  }

  private getAffectingPoint(categoryNumber: string, pointKey: string, value: number) {
    const affectingPoint: AffectingPointDto = {
      categoryNumber: null,
      pointGroupNumber: null,
      pointNumber: null,
      value,
    };
    const inspectionCategory = this.allCategories.find(
      (category) => category.number === categoryNumber
    );
    if (inspectionCategory) {
      this.fillPathToAffectingPoint(inspectionCategory, affectingPoint, pointKey);
    }
    return affectingPoint;
  }

  private fillPathToAffectingPoint(
    inspectionElement: InspectionElementDto,
    affectingPoint: AffectingPointDto,
    pointKey: string
  ): void {
    if (this.isPointInSubtree(inspectionElement, pointKey)) {
      if (inspectionElement.childElements) {
        inspectionElement.childElements.forEach((childElement) => {
          this.fillPathToAffectingPoint(childElement, affectingPoint, pointKey);
        });
      }
      if (
        inspectionElement.type === InspectionResultElementType.Point &&
        inspectionElement.key === pointKey
      ) {
        affectingPoint.pointNumber = inspectionElement.number;
      } else if (
        inspectionElement.type === InspectionResultElementType.PointGroup &&
        affectingPoint.pointNumber
      ) {
        if (
          inspectionElement.pointGroupType === PointGroupType.Regular ||
          !affectingPoint.pointGroupNumber
        ) {
          affectingPoint.pointGroupNumber = inspectionElement.number;
        }
      } else if (
        inspectionElement.type === InspectionResultElementType.Category &&
        affectingPoint.pointGroupNumber &&
        affectingPoint.pointNumber
      ) {
        affectingPoint.categoryNumber = inspectionElement.number;
      }
    }
  }

  private handleAffectedPointGroups(affectingPoint: AffectingPointDto): void {
    if (
      this.pointToPointGroupMappingConfig == null ||
      this.pointToPointGroupMappingConfig.pointToPointGroupMappings == null
    ) {
      return;
    }
    const affectedMappings = this.pointToPointGroupMappingConfig.pointToPointGroupMappings.filter(
      (mapping) =>
        mapping.affectingCategoryNumber === affectingPoint.categoryNumber &&
        mapping.affectingPointGroupNumber === affectingPoint.pointGroupNumber &&
        mapping.affectingPointNumber === affectingPoint.pointNumber
    );
    if (affectedMappings == null) {
      return;
    }
    affectedMappings.forEach((mapping) => {
      const categoryResultContainer = this.inspection.categoryResults.find(
        (result) => result.categoryNumber === mapping.affectedCategoryNumber
      );
      const categoryDefinition = this.allCategories.find(
        (category) => category.number === mapping.affectedCategoryNumber
      );
      if (categoryResultContainer && categoryResultContainer.results && categoryDefinition) {
        const affectedPointGroupElement = this.getPointGroupElement(
          categoryDefinition,
          mapping.affectedPointGroupNumber
        );
        if (affectedPointGroupElement) {
          const pointGroupResult = categoryResultContainer.results.find(
            (result) => result.inspectionElementKey === affectedPointGroupElement.key
          );
          const affectingPointValues = this.getAffectingPointValuesForPointGroup(mapping);
          const newResultType = this.getNewResultType(affectingPointValues, affectingPoint);
          if (newResultType) {
            if (pointGroupResult && pointGroupResult.inspectionResult !== newResultType) {
              pointGroupResult.inspectionResult = newResultType;
              pointGroupResult.remark = null;
            }
            this.propagateResultToChildren(
              categoryResultContainer.results,
              affectedPointGroupElement,
              newResultType
            );
          }
        }
      }
    });
  }

  private handleAffectedCategories(affectingPoint: AffectingPointDto): void {
    if (
      this.pointToCategoryMappingConfig == null ||
      this.pointToCategoryMappingConfig.pointToCategoryMappings == null
    ) {
      return;
    }
    const affectedMappings = this.pointToCategoryMappingConfig.pointToCategoryMappings.filter(
      (mapping) =>
        mapping.affectingCategoryNumber === affectingPoint.categoryNumber &&
        mapping.affectingPointGroupNumber === affectingPoint.pointGroupNumber &&
        mapping.affectingPointNumber === affectingPoint.pointNumber
    );
    if (affectedMappings == null) {
      return;
    }
    affectedMappings.forEach((mapping) => {
      const categoryResultContainer = this.inspection.categoryResults.find(
        (results) => results.categoryNumber === mapping.affectedCategoryNumber
      );
      const categoryDefinition = this.allCategories.find(
        (category) => category.number === mapping.affectedCategoryNumber
      );
      if (categoryResultContainer && categoryResultContainer.results && categoryDefinition) {
        const categoryResult = categoryResultContainer.results.find(
          (result) => result.inspectionElementKey === categoryDefinition.key
        );
        const affectingPointValues = this.getAffectingPointValuesForCategory(mapping);
        const newResultType = this.getNewResultType(affectingPointValues, affectingPoint);
        if (newResultType) {
          if (categoryResult && categoryResult.inspectionResult !== newResultType) {
            categoryResult.inspectionResult = newResultType;
            categoryResult.remark = null;
          }
          this.propagateResultToChildren(
            categoryResultContainer.results,
            categoryDefinition,
            newResultType
          );
        }
      }
    });
  }

  private getNewResultType(affectingPointValues: number[], affectingPoint: AffectingPointDto) {
    let newResultType = null;
    if (Math.max(...affectingPointValues) <= 0) {
      newResultType = InspectionResultType.NotApplicable;
    } else if (affectingPointValues.reduce((a, b) => a + b) - affectingPoint.value === 0) {
      newResultType = InspectionResultType.NotDefined;
    }
    return newResultType;
  }

  private getAffectingPointValuesForPointGroup(
    affectedPointGroupMapping: PointToPointGroupMapping
  ): number[] {
    const affectingPointValues = new Array<number>();
    const affectingMappings = this.pointToPointGroupMappingConfig.pointToPointGroupMappings.filter(
      (m) =>
        m.affectedCategoryNumber === affectedPointGroupMapping.affectedCategoryNumber &&
        m.affectedPointGroupNumber === affectedPointGroupMapping.affectedPointGroupNumber
    );
    if (affectingMappings == null) {
      return affectingPointValues;
    }
    affectingMappings.forEach((affectingMapping) => {
      const affectingResultContainer = this.inspection.categoryResults.find(
        (results) => results.categoryNumber === affectingMapping.affectingCategoryNumber
      );
      const affectingCategoryDefinition = this.allCategories.find(
        (category) => category.number === affectingMapping.affectingCategoryNumber
      );
      if (
        affectingResultContainer &&
        affectingResultContainer.results &&
        affectingCategoryDefinition
      ) {
        const affectingPointElement = this.getPointElement(
          affectingCategoryDefinition,
          affectingMapping.affectingPointGroupNumber,
          affectingMapping.affectingPointNumber
        );
        const affectingPointValue = this.getAffectingPointValue(
          affectingPointElement,
          affectingResultContainer
        );
        if (affectingPointValue !== null) {
          affectingPointValues.push(affectingPointValue);
        }
      }
    });
    return affectingPointValues;
  }

  private getAffectingPointValuesForCategory(
    affectedCategoryMapping: PointToCategoryMapping
  ): number[] {
    const affectingPointValues = new Array<number>();
    const affectingMappings = this.pointToCategoryMappingConfig.pointToCategoryMappings.filter(
      (m) => m.affectedCategoryNumber === affectedCategoryMapping.affectedCategoryNumber
    );
    if (affectingMappings == null) {
      return affectingPointValues;
    }
    affectingMappings.forEach((affectingMapping) => {
      const affectingResultContainer = this.inspection.categoryResults.find(
        (results) => results.categoryNumber === affectingMapping.affectingCategoryNumber
      );
      const affectingCategoryDefinition = this.allCategories.find(
        (category) => category.number === affectingMapping.affectingCategoryNumber
      );
      if (
        affectingResultContainer &&
        affectingResultContainer.results &&
        affectingCategoryDefinition
      ) {
        const affectingPointElement = this.getPointElement(
          affectingCategoryDefinition,
          affectingMapping.affectingPointGroupNumber,
          affectingMapping.affectingPointNumber
        );
        const affectingPointValue = this.getAffectingPointValue(
          affectingPointElement,
          affectingResultContainer
        );
        if (affectingPointValue !== null) {
          affectingPointValues.push(affectingPointValue);
        }
      }
    });
    return affectingPointValues;
  }

  private getAffectingPointValue(
    affectingPointElement: InspectionElementDto,
    affectingResultContainer: FlatInspectionCategoryResultDto
  ): number {
    if (!affectingPointElement) {
      return null;
    }
    const affectingPointResult = affectingResultContainer.results.find(
      (result) => result.inspectionElementKey === affectingPointElement.key
    );
    if (affectingPointResult) {
      const numericValue = this.getNumericValue(affectingPointResult.fieldValue);
      if (numericValue !== null && !isNaN(numericValue)) {
        return numericValue;
      }
    }
    return null;
  }

  private getPointGroupElement(
    inspectionElement: InspectionElementDto,
    pointGroupNumber: string
  ): InspectionElementDto {
    let pointGroupElement: InspectionElementDto = null;
    if (
      inspectionElement.type === InspectionResultElementType.PointGroup &&
      inspectionElement.number === pointGroupNumber
    ) {
      pointGroupElement = inspectionElement;
    } else if (inspectionElement.childElements) {
      inspectionElement.childElements.forEach((childElement) => {
        const childPointGroupElement = this.getPointGroupElement(childElement, pointGroupNumber);
        if (childPointGroupElement) {
          pointGroupElement = childPointGroupElement;
        }
      });
    }
    return pointGroupElement;
  }

  private getPointElement(
    inspectionElement: InspectionElementDto,
    pointGroupNumber: string,
    pointNumber: string
  ): InspectionElementDto {
    const pointGroupElement = this.getPointGroupElement(inspectionElement, pointGroupNumber);
    if (pointGroupElement && pointGroupElement.childElements) {
      return pointGroupElement.childElements.find(
        (pointElement) => pointElement.number === pointNumber
      );
    }
    return null;
  }

  private isPointInSubtree(inspectionElement: InspectionElementDto, pointKey: string): boolean {
    let pointFound = false;
    if (inspectionElement.childElements) {
      inspectionElement.childElements.forEach(
        (childElement) => (pointFound = pointFound || this.isPointInSubtree(childElement, pointKey))
      );
    }

    if (
      inspectionElement.type === InspectionResultElementType.Point &&
      inspectionElement.key === pointKey
    ) {
      pointFound = true;
    }

    return pointFound;
  }
}
