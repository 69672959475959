import { FlatInspectionResultDto } from '../core/api';
import { I18NText } from '../core/api/model/i18NText';

export class ProtocolPdfResultDto {
  public pathToElement: string;
  public categoryName: I18NText;
  public categoryShortName: I18NText;
  public pointGroupNames: I18NText[];
  public pointNumber: string;
  public pointName: I18NText;
  public inspectionResult: FlatInspectionResultDto;
  public categoryReason: I18NText;
  public displayInspectionReason: boolean;
  public groupElementNumber: string;
}
