import { Directive, forwardRef, Input } from '@angular/core';
import { FormLayout } from './form-layout';

/**
 * Specifies that the form should use a grid layout. This causes nested FormComponents to automatically apply the
 * appropriate classes to rows, labels and controls.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'form[column-classes]',
  providers: [{ provide: FormLayout, useExisting: forwardRef(() => FormColumnsDirective) }],
})
export class FormColumnsDirective extends FormLayout {
  public groupClasses = 'form-row';
  public labelWrapperClasses: string;
  public controlWrapperClasses: string;
  public labelClasses = 'col-form-label';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('column-classes')
  public set columnClasses(s: string) {
    const tokens = s.split(/,/);
    this.labelWrapperClasses = tokens[0] + ' bsg-label-wrapper';
    this.controlWrapperClasses = (tokens[1] || tokens[0]) + ' bsg-input-wrapper';
  }
}
