import { ProtocolPdfResultDto } from './../../dtos/ProtocolPdfResultDto';
import { DatePipe } from '@angular/common';
import { I18NText } from '@core/api/model/i18NText';
import {
  FlatInspectionCategoryResultDto,
  FlatInspectionResultDto,
  InspectionElementDto,
  InspectionElementFieldType,
  InspectionResultElementType,
  InspectionResultType,
} from '@core/api';
import { InspectionCategoryCacheService } from '@core/cache/inspection-category-cache-service';
import { TranslateService } from '@ngx-translate/core';
import { ProtocolDto } from 'src/app/dtos/protocolDto';
import { ProtocolPdfAggregatedResultDto } from 'src/app/dtos/protocolPdfAggregatedResultDto';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { Injectable } from '@angular/core';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';

@Injectable()
export class InspectionResultsService {
  public constructor(
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {}

  public getInspectionResultsByAccessor(
    protocolPdf: ProtocolPdfDto
  ): Map<string, Map<string, ProtocolPdfResultDto[]>> {
    const pdfResultsMap = new Map<string, Map<string, ProtocolPdfResultDto[]>>();
    if (protocolPdf.inspection.categoryResults) {
      protocolPdf.inspection.categoryResults.forEach((categoryResult) => {
        const categoryDefinition = protocolPdf.inspectionCategories.find(
          (category) => category.number === categoryResult.categoryNumber
        );
        if (categoryDefinition == null) {
          return;
        }
        const categoryPdfResults = this.getInspectionResultsForCategory(
          categoryResult,
          categoryDefinition,
          protocolPdf
        );
        if (categoryPdfResults.size <= 0) {
          return;
        }
        pdfResultsMap.set(categoryDefinition.number, categoryPdfResults);
      });
    }
    return pdfResultsMap;
  }

  public getFieldValueOfLine(inspectionResult: FlatInspectionResultDto): string {
    let text = '';
    const fieldValue = inspectionResult.fieldValue;
    if (fieldValue && fieldValue.inspectionElementFieldType) {
      switch (fieldValue.inspectionElementFieldType) {
        case InspectionElementFieldType.Date:
          text = this.datePipe.transform(fieldValue.dateValue, ProtocolPdfConstants.dateFormat);
          break;
        case InspectionElementFieldType.Number:
          if (fieldValue.numberValue != null) {
            text = fieldValue.numberValue.toString();
            if (inspectionResult.remark) {
              text += ', ';
            }
          }
          if (inspectionResult.remark) {
            text += inspectionResult.remark;
          }
          break;
        case InspectionElementFieldType.YesNo:
          if (fieldValue.yesNoValue != null) {
            const yesNoValue = fieldValue.yesNoValue
              ? 'DisplayFeesOnProtocol.Ja'
              : 'DisplayFeesOnProtocol.Nein';
            text = this.translateService.instant(yesNoValue);
          }
          break;
        case InspectionElementFieldType.Text:
          text = fieldValue.textValue;
          break;
      }
    }
    return text;
  }

  private getInspectionResultsForCategory(
    categoryResult: FlatInspectionCategoryResultDto,
    categoryDefinition: InspectionElementDto,
    protocolPdf: ProtocolPdfDto
  ): Map<string, ProtocolPdfResultDto[]> {
    const pdfResultMap = new Map<string, ProtocolPdfResultDto[]>();
    const protocolPdfResults = new Array<ProtocolPdfResultDto>();
    const aggregatedDefectKeysSet = new Set<string>();
    if (categoryDefinition.childElements) {
      categoryDefinition.childElements.forEach((childElement) => {
        protocolPdfResults.push.apply(
          protocolPdfResults,
          this.getInspectionResultsFromElement(
            categoryResult.results,
            childElement,
            categoryDefinition.number,
            protocolPdf,
            null
          )
        );
      });
      protocolPdfResults.forEach((pdfResult) => {
        if (!aggregatedDefectKeysSet.has(pdfResult.inspectionResult.inspectionElementKey)) {
          pdfResult.categoryName =
            this.inspectionCategoryCacheService.getInspectionCategoryName(categoryResult);
          pdfResult.categoryReason = categoryResult.inspectionReason;
          pdfResult.categoryShortName =
            this.inspectionCategoryCacheService.getInspectionCategoryShortName(
              categoryResult.categoryNumber
            );
          pdfResult.displayInspectionReason = protocolPdf.displayInspectionReason;
          const identicalDefects = this.getIdenticalDefects(pdfResult, protocolPdfResults);
          if (identicalDefects.length) {
            const aggregatedDefect = this.createAggregatedDefect(
              pdfResult,
              identicalDefects,
              protocolPdf
            );
            pdfResultMap.set(aggregatedDefect.pathToElement, [aggregatedDefect]);
            aggregatedDefectKeysSet.add(pdfResult.inspectionResult.inspectionElementKey);
            identicalDefects.forEach((d) =>
              aggregatedDefectKeysSet.add(d.inspectionResult.inspectionElementKey)
            );
          } else {
            if (!pdfResultMap.has(pdfResult.pathToElement)) {
              pdfResultMap.set(pdfResult.pathToElement, [pdfResult]);
            } else {
              pdfResultMap.get(pdfResult.pathToElement).push(pdfResult);
            }
          }
        }
      });
    }
    return pdfResultMap;
  }

  private createAggregatedDefect(
    pdfResult: ProtocolPdfResultDto,
    identicalDefects: ProtocolPdfResultDto[],
    protocolPdf: ProtocolPdfDto
  ) {
    const aggregatedDefect = new ProtocolPdfAggregatedResultDto();
    aggregatedDefect.categoryName = pdfResult.categoryName;
    aggregatedDefect.categoryShortName = pdfResult.categoryShortName;
    aggregatedDefect.pathToElement = pdfResult.inspectionResult.inspectionElementKey;
    aggregatedDefect.pointNumber = pdfResult.pointNumber;
    aggregatedDefect.pointGroupNames = [...pdfResult.pointGroupNames];
    aggregatedDefect.pointGroupNames.shift();
    aggregatedDefect.inspectionResult = this.getInspectionResultClone(
      pdfResult.inspectionResult,
      protocolPdf
    );
    aggregatedDefect.results = [pdfResult, ...identicalDefects];
    return aggregatedDefect;
  }

  private getInspectionResultClone(
    inspectionResult: FlatInspectionResultDto,
    protocolPdf: ProtocolPdfDto
  ): FlatInspectionResultDto {
    const inspectionResultClone = Object.assign({}, inspectionResult);
    this.addDefectSeverityToDefectDescription(inspectionResultClone, protocolPdf);
    return inspectionResultClone;
  }

  private addDefectSeverityToDefectDescription(
    inspectionResultClone: FlatInspectionResultDto,
    protocolPdf: ProtocolPdfDto
  ) {
    const defectSeverity = inspectionResultClone.defectSeverity;
    if (
      protocolPdf.displayDefectSeverity &&
      (defectSeverity === 1 || defectSeverity === 2 || defectSeverity === 3)
    ) {
      let defectDescription = this.translateService.instant('Mangel.Schweregrad') + ': ';
      if (defectSeverity === 1) {
        defectDescription += this.translateService.instant('Geringfügig');
      } else if (defectSeverity === 2) {
        defectDescription += this.translateService.instant('Wesentlich');
      } else if (defectSeverity === 3) {
        defectDescription += this.translateService.instant('Schwerwiegend');
      }

      if (!inspectionResultClone.defectDescription?.endsWith(defectDescription)) {
        if (inspectionResultClone.defectDescription) {
          if (inspectionResultClone.defectDescription.endsWith('.')) {
            inspectionResultClone.defectDescription += ' ';
          } else {
            inspectionResultClone.defectDescription += ', ';
          }
        } else {
          inspectionResultClone.defectDescription = '';
        }
        inspectionResultClone.defectDescription += defectDescription;
      }
    }
  }

  private getInspectionResultsFromElement(
    results: FlatInspectionResultDto[],
    inspectionElement: InspectionElementDto,
    elementPath: string,
    protocolPdf: ProtocolPdfDto,
    parentInspectionElement: InspectionElementDto
  ): ProtocolPdfResultDto[] {
    let pdfResults = new Array<ProtocolPdfResultDto>();
    if (inspectionElement.type === InspectionResultElementType.PointGroup) {
      pdfResults = this.getInspectionResultsFromElementByPointGroup(
        inspectionElement,
        elementPath,
        results,
        protocolPdf
      );
    } else if (inspectionElement.type === InspectionResultElementType.Point && results != null) {
      const parentInspectionGroupElement =
        parentInspectionElement &&
        parentInspectionElement.type === InspectionResultElementType.PointGroup
          ? parentInspectionElement
          : null;
      pdfResults = this.getInspectionResultsFromElementByPoint(
        results,
        inspectionElement,
        protocolPdf,
        elementPath,
        parentInspectionGroupElement
      );
    }
    return pdfResults;
  }

  private getInspectionResultsFromElementByPoint(
    results: FlatInspectionResultDto[],
    inspectionElement: InspectionElementDto,
    protocolPdf: ProtocolPdfDto,
    elementPath: string,
    parentInspectionGroupElement: InspectionElementDto
  ): ProtocolPdfResultDto[] {
    const pdfResults = new Array<ProtocolPdfResultDto>();
    const pointResult = results.find(
      (result) => result.inspectionElementKey === inspectionElement.key
    );
    const pointIsSelected = this.isPointSelected(
      pointResult,
      inspectionElement,
      protocolPdf,
      elementPath
    );

    if (pointIsSelected) {
      pdfResults.push({
        pathToElement: elementPath,
        categoryName: null,
        categoryShortName: null,
        pointGroupNames: [],
        pointNumber: inspectionElement.number,
        inspectionResult: this.getInspectionResultClone(pointResult, protocolPdf),
        pointName: inspectionElement.name,
        categoryReason: null,
        displayInspectionReason: protocolPdf.displayInspectionReason,
        groupElementNumber: parentInspectionGroupElement
          ? parentInspectionGroupElement.number
          : null,
      });
    }
    return pdfResults;
  }

  private getInspectionResultsFromElementByPointGroup(
    inspectionElement: InspectionElementDto,
    elementPath: string,
    results: FlatInspectionResultDto[],
    protocolPdf: ProtocolPdfDto
  ): ProtocolPdfResultDto[] {
    const pdfResults = new Array<ProtocolPdfResultDto>();
    if (inspectionElement.childElements) {
      const extendedPath = `${elementPath}_${inspectionElement.number}`;
      inspectionElement.childElements.forEach((childElement) => {
        pdfResults.push.apply(
          pdfResults,
          this.getInspectionResultsFromElement(
            results,
            childElement,
            extendedPath,
            protocolPdf,
            inspectionElement
          )
        );
      });
      pdfResults.forEach((pdfResult) => {
        const inspectionElementName = this.getPointGroupName(inspectionElement, protocolPdf);
        pdfResult.pointGroupNames.unshift(inspectionElementName);
      });
    }
    return pdfResults;
  }

  private getPointGroupName(inspectionElement: InspectionElementDto, protocolPdf: ProtocolPdfDto) {
    if (protocolPdf.displayPointGroupNumber) {
      const pointGroupNameWithNumber: I18NText = {
        germanText: `${inspectionElement.number} ${inspectionElement.name.germanText}`,
        frenchText: `${inspectionElement.number} ${inspectionElement.name.frenchText}`,
        italianText: `${inspectionElement.number} ${inspectionElement.name.italianText}`,
      };
      return pointGroupNameWithNumber;
    }
    return inspectionElement.name;
  }

  private isPointSelected(
    pointResult: FlatInspectionResultDto,
    inspectionElement: InspectionElementDto,
    protocolPdf: ProtocolPdfDto,
    elementPath: string
  ): boolean {
    switch (protocolPdf.inspectionResultAccessor) {
      case InspectionResultAccessor.defect:
        return this.isDefectSelected(pointResult);
      case InspectionResultAccessor.fieldValue:
        return this.isFieldValueSelected(
          pointResult,
          inspectionElement,
          protocolPdf.protocol,
          elementPath
        );
      case InspectionResultAccessor.defectOrFieldValue:
        return (
          this.isDefectSelected(pointResult) ||
          this.isFieldValueSelected(
            pointResult,
            inspectionElement,
            protocolPdf.protocol,
            elementPath
          )
        );
      case InspectionResultAccessor.fieldValueWithOnTopOfProtocolPdf:
        const onTopSelected = true;
        return this.isFieldValueSelected(
          pointResult,
          inspectionElement,
          protocolPdf.protocol,
          elementPath,
          onTopSelected
        );
      default:
        return false;
    }
  }

  private isDefectSelected(pointResult: FlatInspectionResultDto) {
    return pointResult && pointResult.inspectionResult === InspectionResultType.Defect;
  }

  private isFieldValueSelected(
    pointResult: FlatInspectionResultDto,
    inspectionElement: InspectionElementDto,
    protocol: ProtocolDto,
    elementPath: string,
    onTopSelected: boolean = false
  ) {
    const fieldValueToDisplay =
      protocol.displayFieldValueElements !== undefined &&
      protocol.displayFieldValueElements.find(
        (e) =>
          elementPath ===
            e.categoryNumber + (e.pointGroupNumber === null ? '' : '_' + e.pointGroupNumber) &&
          e.pointNumber === inspectionElement.number &&
          (onTopSelected
            ? this.inspectionCategoryCacheService.getDisplayInspectionDataOnTopOfProtocolPdf(
                e.categoryNumber
              ) === true
            : this.inspectionCategoryCacheService.getDisplayInspectionDataOnTopOfProtocolPdf(
                e.categoryNumber
              ) === false)
      );
    const isSelected = fieldValueToDisplay && fieldValueToDisplay !== null;
    const fieldValue = this.getFieldValueOfLine(pointResult);
    const hasValue = fieldValue !== '' && fieldValue != null;
    return pointResult && isSelected && hasValue;
  }

  private getIdenticalDefects(
    defect: ProtocolPdfResultDto,
    defects: ProtocolPdfResultDto[]
  ): ProtocolPdfResultDto[] {
    return defects.filter(
      (otherDefect) =>
        otherDefect.inspectionResult.inspectionElementKey !==
          defect.inspectionResult.inspectionElementKey &&
        otherDefect.pointNumber === defect.pointNumber &&
        otherDefect.inspectionResult.defectDescription ===
          defect.inspectionResult.defectDescription &&
        otherDefect.inspectionResult.inspectionResult === InspectionResultType.Defect &&
        defect.inspectionResult.inspectionResult === InspectionResultType.Defect
    );
  }
}
