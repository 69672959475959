import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClientConfigDBService } from './core/db/client-config-db-service';
import { ConnectionStateService } from './core/connection-state.service';
import { forkJoin, of } from 'rxjs';
import { ClientConfigurationService } from './core/api';
import {
  InspectionTypeConfig,
  CategoryConfig,
  PointToCategoryMappingConfig,
  PointToPointGroupMappingConfig,
  PointConfig,
  ClientConfig,
} from './core/api/model/models';
import { concatMap, mergeMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public constructor(
    private translateService: TranslateService,
    private clientConfigurationService: ClientConfigurationService,
    private clientConfigDBService: ClientConfigDBService,
    private connectionStateService: ConnectionStateService
  ) {}

  public ngOnInit() {
    this.initTranslateService();
    this.connectionStateService
      .hasConnection(false)
      .pipe(
        concatMap((hasConnection) =>
          hasConnection && this.connectionStateService.user
            ? of(true)
            : this.connectionStateService.userLoaded
        )
      )
      .subscribe(() => {
        this.initClientConfig();
      });
  }

  public initTranslateService() {
    let language = 'de';
    const browserLang = this.translateService.getBrowserLang();
    if (browserLang !== undefined && browserLang === 'fr') {
      language = browserLang.toLowerCase();
    }

    this.translateService.setDefaultLang(language);
    this.translateService.use(language);

    // we bundle the translations to prevent a flash of untranslated content
    // (this does not significantly increase bundle size unless you have dozens of translations)
    for (const lang of ['de', 'fr']) {
      this.translateService.setTranslation(lang, require(`../i18n/locale-${lang}.json`));
    }
  }

  public initClientConfig() {
    if (this.connectionStateService.user && this.connectionStateService.user.centerIdentifier) {
      forkJoin([
        this.clientConfigurationService
          .getInspectionTypeConfig(this.connectionStateService.user.centerIdentifier)
          .pipe(
            mergeMap((result: InspectionTypeConfig) =>
              this.clientConfigDBService.putInspectionTypeConfig(result)
            )
          ),
        this.clientConfigurationService
          .getCategoryConfig(this.connectionStateService.user.centerIdentifier)
          .pipe(
            mergeMap((result: CategoryConfig) =>
              this.clientConfigDBService.putCategoryConfig(result)
            )
          ),
        this.clientConfigurationService
          .getPointToCategoryMappingConfig(this.connectionStateService.user.centerIdentifier)
          .pipe(
            mergeMap((result: PointToCategoryMappingConfig) =>
              this.clientConfigDBService.putPointToCategoryMappingConfig(result)
            )
          ),
        this.clientConfigurationService
          .getPointToPointGroupMappingConfig(this.connectionStateService.user.centerIdentifier)
          .pipe(
            mergeMap((result: PointToPointGroupMappingConfig) =>
              this.clientConfigDBService.putPointToPointGroupMappingConfig(result)
            )
          ),
        this.clientConfigurationService
          .getPointConfig(this.connectionStateService.user.centerIdentifier)
          .pipe(
            mergeMap((result: PointConfig) => this.clientConfigDBService.putPointConfig(result))
          ),
        this.clientConfigurationService
          .getClientConfig(this.connectionStateService.user.centerIdentifier)
          .pipe(
            mergeMap((result: ClientConfig) => this.clientConfigDBService.putClientConfig(result))
          ),
      ]).subscribe(
        ([
          inspectionTypeConfigSuccess,
          categoryConfigSuccess,
          pointToCategoryMappingConfigSuccess,
          pointToPointGroupMappingConfigSuccess,
          pointConfigSuccess,
          clientConfigSuccess,
        ]) => {
          console.log(inspectionTypeConfigSuccess);
          console.log(categoryConfigSuccess);
          console.log(pointToCategoryMappingConfigSuccess);
          console.log(pointToPointGroupMappingConfigSuccess);
          console.log(pointConfigSuccess);
          console.log(clientConfigSuccess);
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }
}
