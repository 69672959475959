import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { InspectionWithResultsDto } from '../api/model/inspectionWithResultsDto';
import { InspectionDBService } from '../db/inspection-db-service';

@Injectable()
export class InspectionsResolver implements Resolve<InspectionWithResultsDto[]> {
  public constructor(private inspectionDbService: InspectionDBService) {}

  public resolve() {
    return this.inspectionDbService.getAllInspectionsWithResults();
  }
}
