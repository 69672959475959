<app-navigation
  [currentPage]="'protocol'"
  [inspectionKey]="inspectionWithResult.inspectionKey"></app-navigation>

<div class="page-content">
  <app-inspection-header [inspection]="inspectionWithResult"></app-inspection-header>

  <app-mkde-alert *ngIf="showReadonlyScreenDueToEnteredStatusWarning()" type="warning">
    {{ 'ReadonlyScreenDueToEnteredStatus' | translate }}</app-mkde-alert
  >

  <form
    column-classes="col-md-3,col-md-9"
    [formGroup]="protocolForm"
    (focusout)="saveProtocolAndInspection()">
    <h2
      class="title-divider mt-4 mb-4"
      [ngClass]="{ clickable: defects.length > 0 }"
      (click)="defects.length > 0 && (inspectionResultCollapsed = !inspectionResultCollapsed)"
      aria-expanded="!inspectionResultCollapsed"
      aria-controls="collapse-inspection-result">
      <span translate>Kontrollergebnis</span>
      <button *ngIf="defects.length > 0" class="btn btn-link btn-details-toggle">
        <i
          class="fas"
          [ngClass]="{
            'fa-chevron-circle-down': inspectionResultCollapsed,
            'fa-chevron-circle-up': !inspectionResultCollapsed
          }"></i>
      </button>
    </h2>
    <span *ngIf="defects.length === 0" translate>Keine Mängel</span>

    <div [ngbCollapse]="inspectionResultCollapsed" id="collapse-inspection-result">
      <div formArrayName="defects">
        <div class="card my-4" *ngFor="let defect of defectArray.controls; let defectIndex = index">
          <div [formGroupName]="defectIndex">
            <div class="card-header d-flex flex-column">
              <div>
                <span class="mb-0 me-auto">
                  <b>{{ defect.value.label }}</b>
                  {{ defect.get('result').get('defectDescription').value }}
                </span>
              </div>
              <div
                *ngIf="defect.value.isAggregate"
                class="clickable"
                (click)="clickDefectDetailsCollapse(defectIndex)"
                aria-expanded="!getDefectDetailsCollapse(defectIndex)"
                [attr.aria-controls]="defect.get('result').get('inspectionElementKey').value">
                <span translate>Betroffene Punktegruppen</span>
                <button class="btn btn-link btn-details-toggle">
                  <i
                    class="fas"
                    [ngClass]="{
                      'fa-chevron-circle-down': getDefectDetailsCollapse(defectIndex),
                      'fa-chevron-circle-up': !getDefectDetailsCollapse(defectIndex)
                    }"></i>
                </button>
                <div
                  [ngbCollapse]="getDefectDetailsCollapse(defectIndex)"
                  id="{{ defect.get('result').get('inspectionElementKey').value }}">
                  <ul formArrayName="defects">
                    <li
                      *ngFor="let subDefect of defect.value.defects; let subDefectIndex = index"
                      [formGroupName]="subDefectIndex">
                      {{ subDefect.inspectionElementNames[1] | i18nText }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <form-row column-classes="col-md-4,col-md-8">
                    <form-control labelKey="Mangel.Massnahme" [labelReadonly]="false">
                      <div
                        ngbDropdown
                        class="d-inline-block input-group-text"
                        *ngIf="!isDefectSancionSuggestionEmpty(defectIndex)">
                        <button
                          class="btn btn-outline-primary"
                          id="dropdownSuggestions"
                          ngbDropdownToggle
                          [appDisable]="readonly"></button>
                        <div ngbDropdownMenu aria-labelledby="dropdownSuggestions">
                          <button
                            *ngFor="
                              let sanctionSuggestion of getDefectSanctionSuggestionTranslation(
                                defectIndex
                              )
                            "
                            class="dropdown-item"
                            ngbDropdownItem
                            (click)="insertSanction(defectIndex, sanctionSuggestion)">
                            {{ sanctionSuggestion | i18nText }}
                          </button>
                        </div>
                      </div>
                      <ng-container formGroupName="result">
                        <textarea
                          [name]="'defectDescription' + defectIndex"
                          type="text"
                          formControlName="remark"
                          [appDisable]="readonly"
                          maxlength="2000"
                          rows="1"
                          [appDisable]="readonly"></textarea>
                      </ng-container>
                    </form-control>
                  </form-row>
                </div>
                <div class="col-md-4">
                  <form-row column-classes="col-md-4,col-md-8">
                    <form-control labelKey="Mangel.Frist" [labelReadonly]="false">
                      <ng-container formGroupName="result">
                        <app-mkde-datepicker
                          formControlName="complaintRemedyDateDue"
                          [appDisable]="readonly"
                          [isDisabled]="readonly">
                        </app-mkde-datepicker>
                      </ng-container>
                    </form-control>
                  </form-row>
                </div>
                <div
                  class="col-md-auto"
                  *ngIf="defect.get('result').get('complaintRemedyDateDue').value">
                  <form-row column-classes="col-md-2,col-md-2">
                    <div
                      ngbPopover="{{ 'Für Massnahmen ohne erfasste Frist übernehmen' | translate }}"
                      placement="bottom"
                      triggers="mouseenter:mouseleave">
                      <button
                        class="btn btn-outline-primary ms-1"
                        (click)="
                          copyDueDate(defect.get('result').get('complaintRemedyDateDue').value)
                        "
                        [appDisable]="readonly">
                        <span class="far fa-copy"></span>
                      </button>
                    </div>
                    <div
                      ngbPopover="{{ 'Mangel bearbeiten' | translate }}"
                      placement="bottom"
                      triggers="mouseenter:mouseleave">
                      <button
                        class="btn btn-outline-primary ms-1"
                        (click)="openDefectDialog(defectIndex)">
                        <span class="fas fa-ellipsis-h"></span>
                      </button>
                    </div>
                  </form-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2
      class="title-divider mt-4 mb-4 clickable"
      (click)="furtherActionCollapsed = !furtherActionCollapsed"
      aria-expanded="!furtherActionCollapsed"
      aria-controls="collapse-further-action">
      <span translate>Weiteres Vorgehen</span>
      <button class="btn btn-link btn-details-toggle">
        <i
          class="fas"
          [ngClass]="{
            'fa-chevron-circle-down': furtherActionCollapsed,
            'fa-chevron-circle-up': !furtherActionCollapsed
          }"></i>
      </button>
    </h2>

    <div
      [ngbCollapse]="furtherActionCollapsed"
      id="collapse-further-action"
      formGroupName="furtherAction">
      <form-row column-classes="col-md-0,col-md-12">
        <div class="form-check col-md-12 mt-1">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="noFurtherAction" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>WeiteresVorgehen.KeineWeiterenSchritte</span>
          </label>
        </div>
      </form-row>

      <form-row column-classes="col-md-0,col-md-8">
        <div class="form-check col-md-4 mt-1">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="repairsIndependently" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate
              >WeiteresVorgehen.VerantwortlichePersonBehebtMaengelSelbstaendig</span
            >
          </label>
        </div>
        <div class="form-check col-md-8 mt-1">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="feedback" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>WeiteresVorgehen.Rueckmeldung</span>
          </label>
        </div>
      </form-row>

      <form-row column-classes="col-md-0,col-md-8">
        <div class="form-check col-md-4 mt-1">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="inspectionReport" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>WeiteresVorgehen.Kontrollbericht</span>
          </label>
        </div>
        <div class="form-check col-md-8 mt-1">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="order" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>WeiteresVorgehen.Anordnung</span>
          </label>
        </div>
      </form-row>

      <form-row column-classes="col-md-4,col-md-8">
        <div class="form-check col-md-4 mt-1">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="criminalComplaint" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>WeiteresVorgehen.Strafanzeige</span>
          </label>
        </div>
        <div class="form-check col-md-8 mt-1">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="followupInspection" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>WeiteresVorgehen.Nachkontrolle</span>
          </label>
        </div>
      </form-row>

      <form-row column-classes="col-md-4,col-md-8">
        <div class="form-check col-md-3 .col-5 .col-lg-2">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="notification" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="form-checkbox-label" translate>WeiteresVorgehen.Mitteilung</span>
          </label>
        </div>
        <div class="form-check col-md-5 input-group">
          <input
            type="text"
            formControlName="notificationText"
            maxlength="2000"
            class="form-control"
            [appDisable]="readonly || !furtherAction.controls.notification.value" />
          <div
            ngbDropdown
            class="d-inline-block input-group-text"
            *ngIf="configProtocolNotifications.length">
            <button
              class="btn btn-outline-primary"
              id="dropdownFurtherActionNotifications"
              ngbDropdownToggle
              [appDisable]="readonly || !furtherAction.controls.notification.value"></button>
            <div ngbDropdownMenu aria-labelledby="dropdownFurtherActionNotifications">
              <button
                *ngFor="let item of configProtocolNotifications; let itemIndex = index"
                (click)="onFurtherActionNotificationChange(item)"
                class="dropdown-item"
                ngbDropdownItem>
                {{ item | i18nText }}
              </button>
            </div>
          </div>
        </div>
      </form-row>

      <form-row column-classes="col-md-4,col-md-8">
        <div class="form-check col-md-3 .col-5 .col-lg-2">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="furtherAction" [appDisable]="readonly" />
            <span class="checkmark"></span>
            <span class="form-checkbox-label" translate>WeiteresVorgehen.WeitereMassnahmen</span>
          </label>
        </div>
        <div class="form-check col-md-5 input-group">
          <input
            type="text"
            formControlName="furtherActionText"
            maxlength="2000"
            class="form-control"
            [appDisable]="readonly || !furtherAction.controls.furtherAction.value" />
          <div
            ngbDropdown
            class="d-inline-block input-group-text"
            *ngIf="configProtocolFurtherActions.length">
            <button
              class="btn btn-outline-primary"
              id="dropdownFurtherActionFurtherAction"
              ngbDropdownToggle
              [appDisable]="readonly || !furtherAction.controls.furtherAction.value"></button>
            <div ngbDropdownMenu aria-labelledby="dropdownFurtherActionFurtherAction">
              <button
                *ngFor="let item of configProtocolFurtherActions; let itemIndex = index"
                (click)="onFurtherActionFurtherActionChange(item)"
                class="dropdown-item"
                ngbDropdownItem>
                {{ item | i18nText }}
              </button>
            </div>
          </div>
        </div>
      </form-row>
    </div>

    <h2
      *ngIf="displaySectionFees()"
      class="title-divider mt-4 mb-4 clickable"
      (click)="feesCollapsed = !feesCollapsed"
      aria-expanded="!feesCollapsed"
      aria-controls="collapse-fees">
      <span translate>Gebühren</span>
      <button class="btn btn-link btn-details-toggle">
        <i
          class="fas"
          [ngClass]="{
            'fa-chevron-circle-down': feesCollapsed,
            'fa-chevron-circle-up': !feesCollapsed
          }"></i>
      </button>
    </h2>

    <div *ngIf="displaySectionFees()" [ngbCollapse]="feesCollapsed" id="collapse-fees">
      <ng-container formGroupName="fees">
        <form-row column-classes="col-md-2,col-md-1">
          <form-control labelKey="Ausweisen" [labelReadonly]="false">
            <select
              formControlName="displayFeesOnProtocol"
              [appDisable]="readonly || !hasFees"
              class="form-select">
              <option [ngValue]="0"></option>
              <option [ngValue]="1" translate>DisplayFeesOnProtocol.Ja</option>
              <option [ngValue]="2" translate>DisplayFeesOnProtocol.Nein</option>
            </select>
          </form-control>
        </form-row>
        <form-row column-classes="col-md-2,col-md-10">
          <form-control labelKey="Protokolltext" [labelReadonly]="false">
            <select
              formControlName="feesSelection"
              [appDisable]="readonly || !hasFees"
              (change)="onFeesSelectionChange()"
              class="form-select">
              <option
                *ngFor="let feetype of configProtocolFeesType; let feesTypeIndex = index"
                [ngValue]="feesTypeIndex">
                {{ feetype | i18nText }}
              </option>
            </select>
          </form-control>
        </form-row>
        <ng-container *ngIf="hasFees">
          <div *ngIf="fees.controls.feesSelection.value !== 0">
            <ng-container formArrayName="positions">
              <ng-container *ngFor="let fee of feesPositionArray.controls; let feeIndex = index">
                <ng-container [formGroupName]="feeIndex">
                  <form-row column-classes="col-md-2,col-md-10">
                    <div class="form-check col-sm-2 mt-1">
                      <label class="checkbox mb-0">
                        <input
                          type="checkbox"
                          formControlName="selected"
                          (change)="onFeeChanged(feeIndex)"
                          [appDisable]="readonly" />
                        <span class="checkmark"></span>
                        <span class="checkbox-label">{{ fee.value.text | i18nText }}</span>
                      </label>
                    </div>
                    <div class="col-sm-1">
                      <input
                        type="number"
                        class="form-control"
                        formControlName="hours"
                        min="0"
                        id="hours"
                        [appDisable]="readonly || !fee.value.selected"
                        (change)="onFeesHoursChange(feeIndex)" />
                    </div>
                    <label class="col-sm-1 ms-2 col-form-label text-start" for="hours" translate>
                      {{ fee.controls.rate.value }} Fr.
                    </label>
                    <div class="col-sm-1">
                      <input
                        type="number"
                        class="form-control"
                        formControlName="hoursFee"
                        [appDisable]="true" />
                    </div>
                    <label class="col-sm-1 ms-2 col-form-label text-start" for="hoursFee" translate
                      >Fr.</label
                    >
                    <div class="col-sm-5">
                      <input
                        type="text"
                        class="form-control"
                        formControlName="hoursReason"
                        maxlength="2000"
                        (change)="onHoursReasonChanged($event.target.value, feeIndex)"
                        [appDisable]="readonly || !fee.value.hoursFee"
                        [placeholder]="'Begründung' | translate" />
                    </div>
                  </form-row>
                </ng-container>
              </ng-container>
            </ng-container>
            <form-row column-classes="col-md-2,col-md-10">
              <label class="col-sm-2 me-2 col-form-label fw-bold" for="field5" translate
                >Total</label
              >
              <div class="col-sm-1 offset-2">
                <input
                  type="number"
                  class="form-control fw-bold"
                  formControlName="totalFee"
                  id="totalFee"
                  [appDisable]="true" />
              </div>
              <label
                class="col-sm-1 ms-2 col-form-label text-start fw-bold"
                for="totalFee"
                translate
                >Fr.</label
              >
            </form-row>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <h2
      class="title-divider mt-4 mb-4 clickable"
      (click)="toggleInspectionDetailsCollapsed()"
      aria-expanded="!inspectionDetailsCollapsed"
      aria-controls="collapse-inspection-details">
      <span translate>Kontrolle</span>
      <button class="btn btn-link btn-details-toggle">
        <i
          class="fas"
          [ngClass]="{
            'fa-chevron-circle-down': inspectionDetailsCollapsed,
            'fa-chevron-circle-up': !inspectionDetailsCollapsed
          }"></i>
      </button>
    </h2>

    <div
      [ngbCollapse]="inspectionDetailsCollapsed"
      id="collapse-inspection-details"
      formGroupName="inspection">
      <form-row column-classes="col-md-2,col-md-10" class="noborder">
        <form-control labelKey="Kontrolle.KontrollierterBereich">
          <input type="text" formControlName="controlledArea" maxlength="2000" />
        </form-control>
      </form-row>
      <div class="row">
        <div class="col-md-6 pe-0">
          <form-row column-classes="col-md-4,col-md-8">
            <form-control labelKey="Kontrolle.Datum">
              <app-mkde-datepicker
                formControlName="inspectionDate"
                [showTodayButton]="true"
                [isDisabled]="readonly"
                [appDisable]="readonly"
                (ngModelChange)="saveInspectionDate($event)"
                (valueChanged)="saveInspectionDate($event)">
              </app-mkde-datepicker>
            </form-control>
          </form-row>
        </div>
        <div class="col-md-6">
          <form-row column-classes="col-md-3,col-md-3">
            <form-control labelKey="Kontrolle.Kontrollbeginn">
              <ngb-timepicker
                class="timepicker"
                [spinners]="false"
                [minuteStep]="15"
                formControlName="inspectionStartDate"
                [appDisable]="readonly">
              </ngb-timepicker>
            </form-control>
            <form-control labelKey="Kontrolle.Kontrollende">
              <ngb-timepicker
                class="timepicker"
                [spinners]="false"
                [minuteStep]="15"
                formControlName="inspectionEndDate"
                [appDisable]="readonly">
              </ngb-timepicker>
            </form-control>
          </form-row>
        </div>
      </div>

      <form-row column-classes="col-md-2,col-md-10">
        <form-control labelKey="Kontrolle.Art" [labelReadonly]="false">
          <select
            formControlName="inspectionStyle"
            [compareWith]="sameKey"
            [appDisable]="readonly"
            class="form-select">
            <option *ngFor="let inspectionStyle of inspectionStyles" [ngValue]="inspectionStyle">
              {{ inspectionStyle.name | i18nText }}
            </option>
          </select>
        </form-control>
      </form-row>

      <form-row column-classes="col-md-2,col-md-10">
        <form-control labelKey="Kontrolle.Kontrolleur" [labelReadonly]="false">
          <textarea
            formControlName="inspectorName"
            maxlength="2000"
            rows="1"
            [appDisable]="readonly"></textarea>
        </form-control>
      </form-row>

      <form-row column-classes="col-md-2,col-md-10">
        <form-control labelKey="Kontrolle.KontrollierterStandort" [labelReadonly]="false">
          <textarea
            formControlName="controlledLocation"
            maxlength="2000"
            rows="1"
            [appDisable]="readonly"></textarea>
        </form-control>
      </form-row>

      <form-row column-classes="col-md-2,col-md-1">
        <form-control labelKey="Bemerkung ausweisen" [labelReadonly]="false">
          <select
            formControlName="displayInspectionRemarkInProtocolPdf"
            [appDisable]="readonly"
            class="form-select">
            <option [ngValue]="true" translate>Ja</option>
            <option [ngValue]="false" translate>Nein</option>
          </select>
        </form-control>
      </form-row>

      <form-control labelKey="Kontrolle.Bemerkung" [labelReadonly]="false">
        <div
          ngbDropdown
          class="d-inline-block input-group-text"
          *ngIf="configProtocolInspectionRemarkSuggestions.length">
          <button
            class="btn btn-outline-primary"
            id="dropdownInspectionRemarkSuggestion"
            ngbDropdownToggle
            [appDisable]="readonly"></button>
          <div ngbDropdownMenu aria-labelledby="dropdownInspectionRemarkSuggestion">
            <button
              *ngFor="let item of configProtocolInspectionRemarkSuggestions; let itemIndex = index"
              (click)="onInspectionRemarkSuggestionChange(item)"
              class="dropdown-item"
              ngbDropdownItem>
              {{ item | i18nText }}
            </button>
          </div>
        </div>
        <textarea
          formControlName="inspectionRemark"
          maxlength="4000"
          rows="5"
          [appDisable]="readonly"></textarea>
      </form-control>
    </div>

    <div
      *ngIf="displaySectionStatement()"
      ngbPopover="{{ 'Stellungnahme.Readonly' | translate }}"
      placement="top-left"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isStatusEntered()"
      class="popover-left-border">
      <h2
        class="title-divider mt-4 mb-4 clickable"
        (click)="statementActionCollapsed = !statementActionCollapsed"
        aria-expanded="!statementActionCollapsed"
        aria-controls="collapse-statement">
        <span translate>Stellungnahme</span>
        <ng-container *ngIf="!readonly; else toggleDeactivated">
          <button class="btn btn-link btn-details-toggle" [appDisable]="true">
            <i class="fas fa-chevron-circle-down"></i>
          </button>
        </ng-container>
        <ng-template #toggleDeactivated>
          <button class="btn btn-link btn-details-toggle">
            <i
              class="fas"
              [ngClass]="{
                'fa-chevron-circle-down': statementActionCollapsed,
                'fa-chevron-circle-up': !statementActionCollapsed
              }"></i>
          </button>
        </ng-template>
      </h2>
    </div>

    <div
      *ngIf="readonly && displaySectionStatement()"
      [ngbCollapse]="statementActionCollapsed"
      id="collapse-statement"
      formGroupName="statement">
      <form-row column-classes="col-md-4,col-md-8" [removeClass]="'bsg-form-group'">
        <div class="form-check col-md-5 mt-1 form-check-sm">
          <label class="form-control-title" translate>
            <span translate>Stellungnahme.EntsprechenTatsachenTitel</span>
          </label>
        </div>
        <div class="form-check col-md-3 mt-0 form-check-sm">
          <label class="checkbox mb-1">
            <input type="checkbox" formControlName="correspondToFacts" [appDisable]="!readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>Stellungnahme.EntsprechenTatsachen</span>
          </label>
        </div>
        <div class="form-check col-md-4 mt-0 form-check-sm">
          <label class="checkbox mb-1">
            <input type="checkbox" formControlName="noCorrespondToFacts" [appDisable]="!readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>Stellungnahme.EntsprechenNichtTatsachen</span>
          </label>
        </div>
      </form-row>

      <form-row column-classes="col-md-4,col-md-8" [removeClass]="'bsg-form-group'">
        <div class="form-check col-md-5 mt-1 form-check-sm">
          <label class="form-control-title">
            <span translate>Stellungnahme.EinverstandenTitel</span>
          </label>
        </div>
        <div class="form-check col-md-3 mt-1 form-check-sm">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="agree" [appDisable]="!readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>Stellungnahme.Einverstanden</span>
          </label>
        </div>
        <div class="form-check col-md-4 mt-1 form-check-sm">
          <label class="checkbox mb-0">
            <input type="checkbox" formControlName="disagree" [appDisable]="!readonly" />
            <span class="checkmark"></span>
            <span class="checkbox-label" translate>Stellungnahme.NichtEinverstanden</span>
          </label>
        </div>
      </form-row>

      <form-row column-classes="col-md-2,col-md-10">
        <form-control labelKey="Stellungnahme.Bemerkung" [labelReadonly]="false">
          <textarea
            id="StellungnahmeBemerkung"
            formControlName="remark"
            maxlength="2000"
            rows="3"
            [appDisable]="!readonly"></textarea>
        </form-control>
      </form-row>
    </div>
  </form>

  <div
    id="actionpane"
    class="p-2"
    style="display: flex; flex-direction: row; justify-content: flex-end">
    <button class="btn btn-primary me-2" (click)="changeInspectionStatus()">
      <span *ngIf="!isStatusEntered()" class="fas fa-lock"></span>
      <span *ngIf="isStatusEntered()" class="fas fa-unlock"></span>
      <span *ngIf="!isStatusEntered()" translate>Erfassung abschliessen</span>
      <span *ngIf="isStatusEntered()" translate>Weiter erfassen</span>
    </button>
    <div
      ngbPopover="{{ 'ProtocolPdfCreationOnlyInStatusEntered' | translate }}"
      placement="top"
      triggers="mouseenter:mouseleave"
      [disablePopover]="isStatusEntered()"
      class="popover-left-border">
      <button
        class="btn btn-primary me-2"
        (click)="generateProtocolPdf()"
        [appDisable]="!isPrintProtocolPdfEnabled()">
        <span class="far fa-file-pdf"></span>
        <span translate>Kontrollprotokoll drucken</span>
      </button>
    </div>
    <app-protocol-selector (protocolPdfTemplateSelected)="onProtocolPdfTemplateSelected($event)">
    </app-protocol-selector>
    <app-scroll-to-top class="align-right me-2"></app-scroll-to-top>
  </div>
</div>
