<div class="modal-header bg-dark">
  <h4 class="modal-title" translate>Überschreiben bestätigen</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">{{ confirmationText }}</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modal.close()" translate>
    Überschreiben
  </button>
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="modal.dismiss()"
    appAutofocus
    translate>
    Abbrechen
  </button>
</div>
