<div class="modal-header bg-dark">
  <h4 class="modal-title" translate>Dokumente</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <app-mkde-alert type="danger" (dismiss)="showAlert = false" *ngIf="showAlert">{{
    alertMessage
  }}</app-mkde-alert>
  <span *ngIf="!fileNames || fileNames.length === 0" translate
    >Es sind keine Dokumente vorhanden</span
  >
  <table
    class="table table-striped table-hover bsg-table mt-3"
    *ngIf="fileNames && fileNames.length > 0">
    <tbody>
      <tr *ngFor="let fileName of fileNames" style="cursor: pointer">
        <td class="col-10" style="cursor: pointer" (click)="openFile(fileName)">{{ fileName }}</td>
        <td class="td-button col-1">
          <button class="btn btn-primary me-3 mt-1 mb-1" (click)="openFile(fileName)" translate>
            Öffnen
          </button>
        </td>
        <td class="td-button col-1">
          <button class="btn btn-primary me-2" (click)="openDeleteConfirmation(fileName)">
            <span translate>Entfernen</span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <div>
    <input
      type="file"
      id="selectFile"
      style="display: none"
      #fileInput
      (change)="onFilesSelected()"
      multiple />
    <button class="btn btn-primary" onclick="document.getElementById('selectFile').click()">
      <span class="fas fa-circle-notch fa-spin fa-fw" *ngIf="isSavingDocuments"></span>
      <span class="fas fa-plus-circle" *ngIf="!isSavingDocuments"></span>
      <span translate>Dokument hinzufügen</span>
    </button>
  </div>
  <div>
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.dismiss()"
      appAutofocus
      translate>
      Schliessen
    </button>
  </div>
</div>
