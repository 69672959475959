// eslint-disable-next-line id-blacklist
interface String {
  contains(...filter): boolean;
  toFixed(decimals: number): string;
  asDate(): Date;
}

String.prototype.asDate = function(): Date {
  const dateAsStr = String(this);
  if (dateAsStr === null) {
    return null;
  }
  if (dateAsStr.contains('-')) {
    let convertedDate;
    if (dateAsStr.contains('T')) {
      const dateWithoutTime = dateAsStr.split('T')[0];
      convertedDate = dateWithoutTime.split('-').map((str) => parseInt(str, 10));
    } else {
      convertedDate = dateAsStr.split('-').map((str) => parseInt(str, 10));
    }
    return new Date(convertedDate[0], convertedDate[1] - 1, convertedDate[2]);
  }
  return new Date(Date.parse(dateAsStr));
};

String.prototype.contains = function(...filter: string[]): boolean {
  const valueLower = String(this).toLocaleLowerCase();
  let result = false;
  filter.forEach((item) => {
    if (valueLower.indexOf(item) !== -1) {
      result = true;
      return false;
    }
  });
  return result;
};

String.prototype.toFixed = function(decimals: number): string {
  const value = String(this);

  if (value === null) {
    return '';
  }

  if (decimals <= 0) {
    return value;
  }

  return parseFloat(value).toFixed(decimals);
};
