import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionElementLinkedDto } from './InspectionElementLinkedDto';

export class DefectDto {
  // Names for category and point group(s)
  public inspectionElementNames: I18NText[];
  public pointNumber: string;
  public isAggregate?: boolean;
  public result: FlatInspectionResultDto;
  public showDefectSeveritySelection: boolean;
  public sanctionSuggestions: I18NText[];
  public inspectionElement: InspectionElementLinkedDto;
  public label: string;
  public detailsCollapsed: boolean;
  public defects: DefectDto[];
}
