<div class="table-responsive table-list">
  <table class="table table-striped table-sm table-bordered table-hover bsg-table result-list">
    <thead>
      <tr>
        <th class="text-center element-number">
          <button
            *ngIf="notApplicablePointGroupsDisplayed"
            ngbPopover="{{ 'NZ-Punktegruppen ausblenden' | translate }}"
            placement="right"
            triggers="mouseenter:mouseleave"
            class="btn btn-link btn-expand"
            (click)="onNotApplicablePointGroupsDisplayedChanged()">
            <span class="fas fa-angle-double-left"></span>
          </button>
          <button
            *ngIf="!notApplicablePointGroupsDisplayed"
            ngbPopover="{{ 'NZ-Punktegruppen einblenden' | translate }}"
            placement="right"
            triggers="mouseenter:mouseleave"
            class="btn btn-link btn-expand"
            (click)="onNotApplicablePointGroupsDisplayedChanged()">
            <span class="fas fa-angle-double-right"></span>
          </button>
        </th>
        <th colspan="3">
          <h3>
            <span *ngIf="categoryDetail.categoryName">{{
              categoryDetail.categoryName | i18nText
            }}</span
            ><span>&nbsp;({{ categoryDetail.categoryNumber }})</span>
          </h3>
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let listItem of list.items">
        <tr
          *ngIf="
            listItem.pointGroup && listItem.pointGroup.pointGroupType === pointGroupTypes.Regular
          "
          class="detail-list-regular-point-group">
          <td class="text-center">{{ listItem.pointGroup.pointGroupNumber }}</td>
          <td
            (click)="
              hasDescription(listItem.pointGroup.pointGroupDescription) &&
                (listItem.pointGroup.pointGroupDescriptionDisplayed =
                  !listItem.pointGroup.pointGroupDescriptionDisplayed)
            "
            class="list-point-group-name list-name"
            [ngClass]="{ clickable: hasDescription(listItem.pointGroup.pointGroupDescription) }"
            [attr.aria-expanded]="listItem.pointGroup.pointGroupDescriptionDisplayed"
            [attr.aria-controls]="listItem.pointGroup.pointGroupNumber">
            <span
              *ngIf="hasDescription(listItem.pointGroup.pointGroupDescription)"
              class="description-icon fas fa-info-circle"></span>
            <div>{{ listItem.pointGroup.pointGroupName | i18nText }}</div>
            <div
              id="{{ listItem.pointGroup.pointGroupNumber }}"
              [ngbCollapse]="!listItem.pointGroup.pointGroupDescriptionDisplayed">
              <i class="element-description">{{
                listItem.pointGroup.pointGroupDescription | i18nText
              }}</i>
            </div>
          </td>
          <td class="text-center detail-list-value">
            <app-detail-result-dropdown
              *ngIf="!hasOnlyFieldValuePoints(listItem.pointGroup)"
              (resultChange)="onPointGroupResultChanged(listItem)"
              [result]="listItem.pointGroup.pointGroupResult"
              [resultType]="listItem.pointGroup.pointGroupResult.inspectionResult"
              [dependingResults]="getDependingResultsForPointGroup(listItem.pointGroup)"
              [displayDefectOption]="false"
              [displayEditRemark]="false"
              [readonly]="readonly"
              [elementName]="listItem.pointGroup.pointGroupName"></app-detail-result-dropdown>
          </td>
          <td class="detail-list-filler"></td>
        </tr>
        <tr
          *ngIf="
            listItem.pointGroup &&
            listItem.pointGroup.pointGroupType === pointGroupTypes.SubheadWithoutDataEntry
          "
          class="detail-list-subhead-point-group">
          <td class="text-center">{{ listItem.pointGroup.pointGroupNumber }}</td>
          <td
            (click)="
              hasDescription(listItem.pointGroup.pointGroupDescription) &&
                (listItem.pointGroup.pointGroupDescriptionDisplayed =
                  !listItem.pointGroup.pointGroupDescriptionDisplayed)
            "
            class="list-point-group-name list-name"
            [ngClass]="{ clickable: hasDescription(listItem.pointGroup.pointGroupDescription) }"
            [attr.aria-expanded]="listItem.pointGroup.pointGroupDescriptionDisplayed"
            [attr.aria-controls]="listItem.pointGroup.pointGroupNumber">
            <span
              *ngIf="hasDescription(listItem.pointGroup.pointGroupDescription)"
              class="description-icon fas fa-info-circle"></span>
            <div>{{ listItem.pointGroup.pointGroupName | i18nText }}</div>
            <div
              id="{{ listItem.pointGroup.pointGroupNumber }}"
              [ngbCollapse]="!listItem.pointGroup.pointGroupDescriptionDisplayed">
              <i class="element-description">{{
                listItem.pointGroup.pointGroupDescription | i18nText
              }}</i>
            </div>
          </td>
          <td class="text-center">
            <app-detail-result-dropdown
              *ngIf="getDependingResultsForPointGroup(listItem.pointGroup).length > 0"
              (resultChange)="rowPointGroupResultChanged(listItem)"
              [result]="listItem.pointGroup.pointGroupResult"
              [resultType]="listItem.pointGroup.pointGroupResult.inspectionResult"
              [dependingResults]="getDependingResultsForPointGroup(listItem.pointGroup)"
              [displayDefectOption]="true"
              [displayEditRemark]="false"
              [readonly]="readonly"></app-detail-result-dropdown>
          </td>
          <td class="detail-list-filler"></td>
        </tr>
        <tr *ngIf="listItem.point" class="detail-list-point">
          <td class="text-center">{{ listItem.point.pointNumber }}</td>
          <td
            class="list-point-name list-name"
            (click)="
              hasDescription(listItem.point.pointDescription) &&
                (listItem.point.pointDescriptionDisplayed =
                  !listItem.point.pointDescriptionDisplayed)
            "
            [ngClass]="{ clickable: hasDescription(listItem.point.pointDescription) }"
            [attr.aria-expanded]="listItem.point.pointDescriptionDisplayed"
            [attr.aria-controls]="listItem.point.pointNumber">
            <span
              *ngIf="hasDescription(listItem.point.pointDescription)"
              class="description-icon fas fa-info-circle"></span>
            <div>{{ listItem.point.pointName | i18nText }}</div>
            <div
              id="{{ listItem.point.pointNumber }}"
              [ngbCollapse]="!listItem.point.pointDescriptionDisplayed">
              <i class="element-description">{{ listItem.point.pointDescription | i18nText }}</i>
            </div>
          </td>
          <td class="text-center detail-list-value">
            <app-detail-list-point
              (resultChange)="onPointResultChanged(listItem)"
              [point]="listItem.point"
              [readonly]="readonly"
              [displayCopyFieldValueNumber]="displayCopyFieldValueNumber(listItem)"
              (copyValue)="onCopyFieldValueNumber(listItem)"></app-detail-list-point>
          </td>
          <td class="detail-list-value-remark">
            <form-control
              *ngIf="displayRemark(listItem.point)"
              (focusout)="onPointResultChanged(listItem)">
              <input
                name="remark"
                [(ngModel)]="listItem.point.pointResult.remark"
                type="text"
                maxlength="2000"
                [disabled]="readonly" />
            </form-control>
          </td>
        </tr>
      </ng-container>
      <tr *ngIf="categoryDetail.displayCategoryResult">
        <td
          colspan="2"
          (click)="
            hasDescription(list.summary.categoryDescription) &&
              (list.summary.categoryDescriptionDisplayed =
                !list.summary.categoryDescriptionDisplayed)
          "
          class="list-category-name list-name"
          [ngClass]="{ clickable: hasDescription(list.summary.categoryDescription) }"
          [attr.aria-expanded]="list.summary.categoryDescriptionDisplayed"
          [attr.aria-controls]="categoryDetail.categoryNumber">
          <span
            *ngIf="hasDescription(list.summary.categoryDescription)"
            class="description-icon fas fa-info-circle"></span>
          <h3>
            <span translate>Gesamtbeurteilung</span
            ><span>&nbsp;{{ categoryDetail.categoryName | i18nText }}</span>
          </h3>
          <div
            id="{{ categoryDetail.categoryNumber }}"
            [ngbCollapse]="!list.summary.categoryDescriptionDisplayed">
            <i class="element-description">{{ list.summary.categoryDescription | i18nText }}</i>
          </div>
        </td>
        <td class="text-center detail-list-value">
          <app-detail-category-result
            (resultChange)="onCategoryResultChanged($event)"
            [result]="list.summary.categoryResult"
            [resultType]="list.summary.categoryResult.inspectionResult"
            [hasDefects]="hasDefects"
            [readonly]="readonly"></app-detail-category-result>
        </td>
        <td class="detail-list-filler"></td>
      </tr>
    </tbody>
  </table>
</div>
