import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { TranslationWidth } from '@angular/common';

// since NgbDatepicker does not yet offer localization files, we take the raw data from bootstrap-datepicker
// see https://github.com/uxsolutions/bootstrap-datepicker/tree/master/js/locales
const languages = {
  en: {
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthsShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    today: 'Today',
    monthsTitle: 'Months',
    clear: 'Clear',
    weekStart: 1,
    format: 'dd/mm/yyyy',
  },
  de: {
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    daysShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    monthsShort: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ],
    today: 'Heute',
    monthsTitle: 'Monate',
    clear: 'Löschen',
    weekStart: 1,
    format: 'dd.mm.yyyy',
  },
  fr: {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'Ma', 'Me', 'J', 'V', 'S'],
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    monthsShort: [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Jui',
      'Jul',
      'Aou',
      'Sep',
      'Oct',
      'Nov',
      'Déc',
    ],
    today: 'Aujourd\'hui',
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 1,
    format: 'dd.mm.yyyy',
  },
  it: {
    days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    daysShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    daysMin: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    monthsShort: [
      'Gen',
      'Feb',
      'Mar',
      'Apr',
      'Mag',
      'Giu',
      'Lug',
      'Ago',
      'Set',
      'Ott',
      'Nov',
      'Dic',
    ],
    today: 'Oggi',
    clear: 'Cancella',
    weekStart: 1,
    format: 'dd.mm.yyyy',
  },
};

// and expose it as NgbDatepicker wants
@Injectable()
export class BedagDatepickerI18n extends NgbDatepickerI18n {
  public constructor(private translate: TranslateService) {
    super();
  }

  public getWeekdayShortName(weekday: number): string {
    return languages[this.translate.currentLang].daysShort[weekday % 7]; // Ngb has Sunday last, bootstrap-datepicker has it first
  }

  public getMonthShortName(month: number): string {
    return languages[this.translate.currentLang].monthsShort[month - 1];
  }

  public getMonthFullName(month: number): string {
    return languages[this.translate.currentLang].months[month - 1];
  }

  public getDayAriaLabel(date: NgbDateStruct): string {
    const lang = this.translate.currentLang;
    const month = languages[lang].months[date.month - 1];
    switch (lang) {
      case 'de':
        return date.day + '. ' + month + ' ' + date.year;
      case 'en':
        return month + ' ' + date.day + ' ' + date.year;
      default:
        return date.day + ' ' + month + ' ' + date.year;
    }
  }

  public getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return languages[this.translate.currentLang].daysShort[weekday % 7];
  }
}
