<div class="result-dropdown-container">
  <select
    #resultType
    class="form-control result-dropdown form-select"
    [(ngModel)]="localResultType"
    (change)="onChangeResult(); $event.stopPropagation()"
    [disabled]="readonly">
    <option [ngValue]="resultTypes.NotDefined"></option>
    <option [ngValue]="null" translate>OK</option>
    <option [ngValue]="resultTypes.NotApplicable" translate>NZ</option>
    <option *ngIf="displayDefectOption" [ngValue]="resultTypes.Defect" translate>M</option>
    <option [ngValue]="resultTypes.NotControlled" translate>NK</option>
  </select>
  <div class="detail-result-remark-container">
    <app-detail-result-remark
      class="btn-ellipsis-link-after-dropdown"
      *ngIf="
        displayEditRemark &&
        (result.inspectionResult === resultTypes.Defect ||
          result.inspectionResult === resultTypes.NotControlled ||
          result.inspectionResult === resultTypes.NotApplicable)
      "
      [result]="result"
      (remarkChange)="onChangeRemark()"
      [readonly]="readonly"
      [elementName]="elementName"
      [defectDescriptionSuggestions]="defectDescriptionSuggestions"></app-detail-result-remark>
  </div>
</div>
