import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InspectionResultFieldValueDto } from '../core/api/model/inspectionResultFieldValueDto';

@Component({
  selector: 'app-detail-result-yes-no-button',
  templateUrl: './detail-result-yes-no-button.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailResultYesNoButtonComponent {
  @Input()
  public result: InspectionResultFieldValueDto;

  @Input()
  public readonly = false;

  @Output()
  public resultChange: EventEmitter<any> = new EventEmitter();

  public onChangeResult(): void {
    if (this.result.yesNoValue == null) {
      this.result.yesNoValue = true;
    } else if (this.result.yesNoValue) {
      this.result.yesNoValue = false;
    } else {
      this.result.yesNoValue = null;
    }
    this.resultChange.emit();
  }
}
