import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class ImageConverterService {
  public getBase64ImageFromRelativeUrl(relativePath: string, format: string): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      const img = this.createImage(relativePath);
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img, format));
          observer.complete();
        };
        img.onerror = (err: any) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img, format));
        observer.complete();
      }
    });
  }

  public getImageSrc(relativePath: string): string {
    const img = this.createImage(relativePath);
    return img.src;
  }

  private createImage(relativePath: string): any {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = relativePath;
    return img;
  }

  private getBase64Image(img: HTMLImageElement, format: string): string {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL(`image/${format}`);
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }
}
