<app-navigation
  [currentPage]="'detail'"
  [inspectionKey]="inspection.inspectionKey"></app-navigation>

<div class="page-content">
  <app-inspection-header [inspection]="inspection"></app-inspection-header>

  <app-mkde-alert *ngIf="isStatusEntered()" type="warning">{{
    'ReadonlyScreenDueToEnteredStatus' | translate
  }}</app-mkde-alert>

  <button
    *ngIf="notApplicableCategoriesDisplayed"
    ngbPopover="{{ 'NZ-Rubriken ausblenden' | translate }}"
    placement="right"
    triggers="mouseenter:mouseleave"
    class="btn btn-link btn-expand btn-category-expand"
    (click)="notApplicableCategoriesDisplayed = !notApplicableCategoriesDisplayed">
    <span class="fas fa-angle-double-left"></span>
  </button>
  <button
    *ngIf="!notApplicableCategoriesDisplayed"
    ngbPopover="{{ 'NZ-Rubriken einblenden' | translate }}"
    placement="right"
    triggers="mouseenter:mouseleave"
    class="btn btn-link btn-expand btn-category-expand"
    (click)="notApplicableCategoriesDisplayed = !notApplicableCategoriesDisplayed">
    <span class="fas fa-angle-double-right"></span>
  </button>
  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-container
      *ngFor="let categoryDetail of getCategoriesToDisplay(); let categoryDetailIndex = index"
      [ngbNavItem]="categoryDetailIndex"
      ngbNavItem>
      <a ngbNavLink>{{ categoryDetail.categoryShortName | i18nText }}</a>
      <ng-template ngbNavContent>
        <app-detail-list
          *ngIf="categoryDetail.presentationType === 'List'"
          (save)="saveInspection()"
          (changeFieldValue)="handlePointFieldValueChange($event)"
          [categoryDetail]="categoryDetail"
          [inspectionCategory]="getInspectionCategory(categoryDetail.categoryNumber)"
          [notApplicableCategoriesDisplayed]="notApplicableCategoriesDisplayed"
          [readonly]="isReadOnly()"></app-detail-list>
        <app-detail-matrix
          *ngIf="categoryDetail.presentationType === 'Matrix'"
          (save)="saveInspection()"
          [categoryDetail]="categoryDetail"
          [inspectionCategory]="getInspectionCategory(categoryDetail.categoryNumber)"
          [notApplicableCategoriesDisplayed]="notApplicableCategoriesDisplayed"
          [readonly]="isReadOnly()"></app-detail-matrix>
      </ng-template>
    </ng-container>
  </nav>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
