import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-administration-config-table-action]',
  templateUrl: './administration-config-table-action.component.html',
  styleUrls: ['./administration-config-table-action.component.scss'],
})
export class AdministrationConfigTableActionComponent {
  @Output()
  public download = new EventEmitter<any>();

  @Output()
  public upload = new EventEmitter<FileList>();

  public onFileSelect(input: HTMLInputElement): void {
    this.upload.emit(input.files);
    input.value = null;
  }
}
