/* eslint-disable @typescript-eslint/naming-convention */

import { ProtocolPdfFrMilkProducersService } from './protocol-pdf-fr-milk-producers-service';
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class ProtocolPdfFrVacherinService extends ProtocolPdfFrMilkProducersService {
  protected pageFooterDateAsText = '07.06.2021';
  protected translationKeyPageHeaderTitle = 'ProtocolPdf.VacherinPageHeader';
  protected prefixFileName = 'VFR';
  protected columnIndexStartCheckboxes = 3;

  protected addTablesToPages(): void {
    this.addTablesToPage1();
  }

  protected getTranslationKeyPageFooterRow2Part1(): string {
    return 'ProtocolPdf.VacherinPageFooterRow2Part1';
  }

  private addTablesToPage1(): void {
    this.addUnitTable();
    this.addAuditorTable();
    this.addTvdTable();
    this.addMilkProducersTable(
      this.translateService.instant('ProtocolPdf.VacherinAndGruyereTextHeader'),
      4
    );
    this.addRemarksForRequirementsTable();
    let legendSpaceY = this.addRequirementsLegend();
    this.addRequirementsTable(legendSpaceY);
    legendSpaceY = this.addRemarksLegend();
    let numberOfRows = 4;
    if (this.isCurrentLanguageFr) {
      numberOfRows = 3;
    }
    this.addRemarksTable(legendSpaceY, numberOfRows);
    this.addConfirmationTable();
  }

  private addTvdTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      columnStyles: {
        0: { cellWidth: 59 },
        1: { cellWidth: 59 },
        2: { cellWidth: 63.5 },
      },
      headStyles: {
        fillColor: ProtocolPdfConstants.whiteColor,
      },
      head: [
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereTVDHeaderCol1'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereTVDHeaderCol2'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereTVDHeaderCol3Part1'),
        ],
      ],
      body: [
        ['', '', ''],
        [this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part1'), '', ''],
      ],
      didParseCell: (data: any) => {
        this.applySpansToTvdTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToTvdTable(data);
      },
    });
  }

  private applySpansToTvdTable(data: any): void {
    if (data.section === 'body' && data.row.index === 1 && data.column.index === 0) {
      data.cell.colSpan = 3;
    }
  }

  private addAcroFieldsToTvdTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 2) {
      const positionX = this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes')
      );
      this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot'),
        { x: positionX }
      );
    }
    if (data.section === 'body') {
      if (data.row.index === 0) {
        const textboxWidth = 50;
        if (data.column.index === 0) {
          this.addAcroTextbox(
            data,
            { width: textboxWidth },
            this.protocolInspectionService.getTvdId()
          );
        } else {
          this.addAcroTextbox(data, { width: textboxWidth });
        }
      }
      if (data.row.index === 1 && data.column.index === 0) {
        let positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part2')
        );
        positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part3'),
          { x: positionX }
        );
        positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part4'),
          { x: positionX }
        );
        this.addAcroTextbox(data, { width: 25, x: positionX });
      }
    }
  }

  private addRequirementsLegend(): number {
    this.setPagePositionY(0.7);
    const firstLineSpace = 3.5;
    let resultLegendSpaceY = firstLineSpace;
    let positionY = this.getPagePositionY() + firstLineSpace;
    let positionX = 16;
    this.document.setFontSize(10);
    this.document.setFont('helvetica', 'bold');
    this.document.text(
      this.translateService.instant('ProtocolPdf.VacherinRequirementsLegendRow1'),
      positionX,
      positionY
    );
    const secondLineSpace = 6.5;
    resultLegendSpaceY += secondLineSpace;
    positionY = this.getPagePositionY() + secondLineSpace;
    this.document.setFontSize(this.fontSize);
    this.document.setFont('helvetica', 'bold');
    const row2Part1 = this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable');
    this.document.text(row2Part1, positionX, positionY);
    positionX += this.document.getTextWidth(row2Part1) + 2;
    this.document.setFont('helvetica', 'normal');
    const row2Part2 = this.translateService.instant(
      'ProtocolPdf.VacherinRequirementsLegendRow2Part2'
    );
    this.document.text(row2Part2, positionX, positionY);
    return resultLegendSpaceY - this.tableSeparationSpace;
  }

  private addRequirementsTable(legendSpaceY: number): void {
    const head = this.getRequirementsTableHeader();
    const body = this.getRequirementsTableBody();
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + legendSpaceY,
      headStyles: { halign: 'center', fontStyle: 'bold' },
      styles: { fontSize: this.fontSize },
      columnStyles: {
        0: {
          cellWidth: this.requirementColumnArticleTextWidth,
          valign: 'middle',
          halign: 'center',
        },
        1: {
          cellWidth: this.requirementColumnArticleNumberWidth,
          valign: 'middle',
          halign: 'center',
        },
        3: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
        4: {
          cellWidth: this.requirementColumnCheckboxNotWidth,
          valign: 'middle',
          halign: 'center',
        },
        5: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
      },
      head,
      body,
      didParseCell: (data: any) => {
        this.applySpansToRequirementsTable(data);
        this.applyAlignmentsToRequirementsTable(data);
        this.applyStylesAndSpansToSpecificArticlesPage1(data);
      },
      didDrawCell: (data: any) => {
        this.addRequirementsCheckboxes(data, 2, [2]);
      },
    });
  }
  private applyStylesAndSpansToSpecificArticlesPage1(data: any): void {
    if (data.section !== 'body') {
      return;
    }

    //Article 6
    const rowIndexArticle6 = 1;
    if (data.row.index === rowIndexArticle6 && data.column.index === 0) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle6 && data.column.index === 1) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === rowIndexArticle6 + 1 && data.column.index === 2) {
      data.cell.styles.lineWidth = {
        top: 0,
        right: this.defaultLineWidth,
        bottom: this.defaultLineWidth,
        left: this.defaultLineWidth,
      };
      data.cell.styles.fontStyle = 'italic';
    }
    if (
      data.row.index === rowIndexArticle6 &&
      data.column.index >= this.columnIndexStartCheckboxes
    ) {
      data.cell.rowSpan = 2;
    }
  }

  private getRequirementsTableHeader(): any[][] {
    return [
      [
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol1'
        ),
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol2'
        ),
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol3'
        ),
        '',
        '',
      ],
      [
        '',
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable'),
      ],
    ];
  }

  private applySpansToRequirementsTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 0) {
      data.cell.colSpan = 2;
    }
    const numberOfColumnsToSpanRequirement = 1;
    this.applySpansToHeaderRequirement(
      data,
      this.columnIndexStartCheckboxes,
      numberOfColumnsToSpanRequirement
    );
  }

  private applyAlignmentsToRequirementsTable(data: any): void {
    this.applyAlignmentInCenterToHeaderRequirement(data);
    this.applyFontsizeToColumnRequirement(data);
    if (data.section === 'body' && data.row.index === 14 && this.isCurrentLanguageFr) {
      data.cell.styles.fontSize = this.requirementFontSize - 0.75;
    }
    if (
      data.section === 'body' &&
      data.row.index > 8 &&
      data.row.index < 12 &&
      data.column.index === 0
    ) {
      data.cell.colSpan = 3;
      data.cell.styles.valign = 'top';
      data.cell.styles.halign = 'left';
      data.cell.styles.fontSize = this.requirementFontSize;
    }
  }

  private getRequirementsTableBody(): any[][] {
    const bodyTable = [
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle2Column1Row1'),
        '2',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle2Column3Row1'),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle6Column1Row1'),
        '6',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle6Column3Row1'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle6Column3Row2'),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle7Column1Row1'),
        '7',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle7Column3Row1'),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8Column1Row1'),
        '8.1',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8Column3Row1'),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle12Column1Row1'),
        '12.1',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle12Column3Row1'),
        '',
        '',
        '',
      ],
    ];
    return bodyTable;
  }
}
