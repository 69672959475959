import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MkdeUserNotificationService } from './mkde-user-notification.service';

@Injectable()
export class MkdeErrorHandler extends ErrorHandler {
  public constructor(
    private translateService: TranslateService,
    private userNotificationService: MkdeUserNotificationService
  ) {
    super();
  }

  public handleError(error: any) {
    this.tellUser(error);
    super.handleError(error);
  }

  public tellUser(error: any) {
    const message = this.buildMessage(this.extractRootCause(error));
    console.warn(message);
    this.userNotificationService.error(message, error);
  }

  public extractRootCause(error): any {
    if (error.rejection) {
      error = error.rejection;
    }
    return error;
  }

  public buildMessage(error): string {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0:
          return this.translateService.instant('Keine Verbindung zum Server vorhanden.');
        default:
          if (error.error && error.error.messageDe) {
            // HttpErrorResponse.error contains the http body of the response
            if (this.translateService.currentLang === 'fr') {
              return error.error.messageFr;
            } else {
              return error.error.messageDe;
            }
          } else {
            return error.message;
          }
      }
    } else {
      return this.translateService.instant('Es ist ein technischer Fehler aufgetreten.');
    }
  }
}
