import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { apiConfigFactory } from './api.config';
import { ApiModule } from './api/api.module';
import { ConnectionStateService } from './connection-state.service';
import { HttpXsrfInterceptor } from './http-xsrf-interceptor';
import { MkdeErrorHandler } from './mkde-error-handler';
import { DBService } from './db/db-service';
import { ClientConfigDBService } from './db/client-config-db-service';
import { SyncService } from './sync/sync-service';
import { InspectionCategoryDBService } from './db/inspection-category-db-service';
import { InspectionDBService } from './db/inspection-db-service';
import { CategoryConfigResolver } from './resolvers/category-config-resolver';
import { PointConfigResolver } from './resolvers/point-config-resolver';
import { InspectionsResolver } from './resolvers/inspections-resolver';
import { InspectionCategoriesResolver } from './resolvers/inspection-categories-resolver';
import { InspectionResolver } from './resolvers/inspection-resolver';
import { InspectionCategoryCacheService } from './cache/inspection-category-cache-service';
import { InspectionStyleDBService } from './db/inspection-style-db-service';
import { EnumCacheService } from './cache/enum-cache-service';
import { FileDBService } from './db/file-db-service';
import { ProtocolDBService } from './db/protocol-db-service';
import { ProtocolResolver } from './resolvers/protocol-resolver';
import { UserResolver } from './resolvers/user-resolver';
import { InspectionStatusDBService } from './db/inspection-status-db-service';
import { InspectionStatesResolver } from './resolvers/inspection-states-resolver';
import { InspectionStylesResolver } from './resolvers/inspection-styles-resolver';
import { PointToCategoryMappingConfigResolver } from './resolvers/point-to-category-mapping-config-resolver';
import { PointToPointGroupMappingConfigResolver } from './resolvers/point-to-point-group-mapping-config-resolver';
import { MkdeUserNotificationService } from './mkde-user-notification.service';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { CommonModule, JsonPipe } from '@angular/common';
import { UserDBService } from './db/user-db-service';
import { InspectionTypeConfigResolver } from './resolvers/inspection-type-config-resolver';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeoutInterceptor } from './timeout-interceptor';
import { ImageConverterService } from './image-converter.service';
import { I18nTextPipe } from './pipes/i18n-text.pipe';
import { I18nTextListPipe } from './pipes/i18n-text-list.pipe';
import { InspectionResultsService } from './protocol-pdf/inspection-results-service';
import { ImageService } from './protocol-pdf/image-service';

/**
 * The core module contributes declarations and services used throughout the application.
 *
 * It is available in two flavors:
 * CoreModule, which can be imported by any module in the application,
 * and CoreModule.forRoot(), which must (only) be imported by the application module.
 *
 * The reason for this split is that angular uses different scoping rules for declarations and providers. Specifically,
 * declarations must be imported in every module, while providers can be inherited from parent injectors - and should,
 * since registering them again would create new independent instances. We therefore put our declarations in the
 * CoreModule, and our singleton providers into the CoreModuleForRoot.
 */
@NgModule({
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, NgbModule, CommonModule],
  declarations: [],
  exports: [FormsModule, ReactiveFormsModule, TranslateModule, NgbModule],
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModuleForRoot> {
    return {
      ngModule: CoreModuleForRoot,
      providers: [TranslateModule.forRoot().providers],
    };
  }
}

@NgModule({
  imports: [CommonModule, ApiModule.forRoot(apiConfigFactory), HttpClientModule, CoreModule],
  exports: [CoreModule],
  providers: [
    ConnectionStateService,
    DBService,
    ClientConfigDBService,
    InspectionDBService,
    InspectionCategoryDBService,
    InspectionStyleDBService,
    ImageConverterService,
    ImageService,
    FileDBService,
    ProtocolDBService,
    InspectionStatusDBService,
    UserDBService,
    SyncService,
    InspectionTypeConfigResolver,
    CategoryConfigResolver,
    PointConfigResolver,
    InspectionsResolver,
    InspectionCategoriesResolver,
    InspectionResolver,
    ProtocolResolver,
    UserResolver,
    InspectionStylesResolver,
    InspectionStatesResolver,
    PointToCategoryMappingConfigResolver,
    PointToPointGroupMappingConfigResolver,
    InspectionCategoryCacheService,
    InspectionResultsService,
    EnumCacheService,
    MkdeAlertHolderDirective,
    MkdeUserNotificationService,
    I18nTextPipe,
    I18nTextListPipe,
    JsonPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: ErrorHandler, useClass: MkdeErrorHandler },
  ],
})
export class CoreModuleForRoot {}
