<div class="modal-header bg-dark">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"
    tabindex="-1"></button>
</div>
<div class="modal-body">{{ message }}</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary btn-dialog-md" (click)="modal.close()" translate>
    Entfernen
  </button>
  <button
    type="button"
    class="btn btn-outline-primary btn-dialog-md"
    (click)="modal.dismiss()"
    appAutofocus
    translate>
    Abbrechen
  </button>
</div>
