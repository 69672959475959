import { Directive } from '@angular/core';

/**
 * Gathers the alerts of all nested components (unless they have their own AlertHolder). The root AlertHolder is also
 * available to services.
 */
@Directive({
  selector: '[appMkdeAlertHolder]',
})
export class MkdeAlertHolderDirective {
  public alerts: MkdeAlert[] = [];

  public success(message: string) {
    this.add({ type: 'success', message, error: null });
  }

  public info(message: string) {
    this.add({ type: 'info', message, error: null });
  }

  public warning(message: string) {
    this.add({ type: 'warning', message, error: null });
  }

  public danger(message: string, error: any) {
    this.add({ type: 'danger', message, error });
  }

  public dismiss(alert: MkdeAlert) {
    setTimeout(() => (this.alerts = this.alerts.filter((a) => a !== alert)), 150);
  }

  private add(alert: MkdeAlert) {
    this.alerts.push(alert);
    if (alert.type === 'success' || alert.type === 'info') {
      setTimeout(() => this.dismiss(alert), 5000);
    }
  }
}

interface MkdeAlert {
  type: string;
  message: string;
  error: any;
}
