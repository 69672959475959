import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InspectionCategoryCacheService } from '../core/cache/inspection-category-cache-service';
import { EnumCacheService } from '../core/cache/enum-cache-service';
import { InspectionDBService } from '../core/db/inspection-db-service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { StatusChangeValidationService } from '../core/status-change-validation.service';
import { StatusChangeDialogComponent } from '../status-change-dialog/status-change-dialog.component';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionElementDto } from '../core/api/model/inspectionElementDto';
import { InspectionStatusDto } from '../core/api/model/inspectionStatusDto';
import { InspectionStyleDto } from '../core/api/model/inspectionStyleDto';
import { InspectionWithResultsDto } from '../core/api/model/inspectionWithResultsDto';
import { InspectionStatus } from '../core/api/model/inspectionStatus';
import { keysToTranslate } from '@core/util/keys-to-translate';

@Component({
  selector: 'app-inspection-dialog',
  templateUrl: './inspection-dialog.component.html',
})
export class InspectionDialogComponent implements OnInit {
  @Input()
  public inspection: InspectionWithResultsDto;

  @Input()
  public inspectionCategories: InspectionElementDto[];

  @ViewChild('form', { static: true })
  public form: NgForm;

  public inspectionCategoryShortNames: I18NText[];
  public inspectionStyles: InspectionStyleDto[];
  public inspectionStates: InspectionStatusDto[];
  public tmpInspectionStatus: InspectionStatusDto;
  public displayStatusDingsbums = false;

  public constructor(
    public modal: NgbActiveModal,
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private enumCacheService: EnumCacheService,
    private inspectionDBService: InspectionDBService,
    private translateService: TranslateService,
    private alerts: MkdeAlertHolderDirective,
    private notDefinedPointsService: StatusChangeValidationService,
    private modalService: NgbModal
  ) {
    keysToTranslate([
      'Kontrolle.Datum',
      'Kontrolle.Typ',
      'Kontrolle.Grund',
      'Kontrolle.Stelle',
      'Kontrolle.Art',
      'Kontrolle.Kontrolleur',
      'Kontrolle.Status',
      'Kontrolle.Bemerkung',
    ]);
  }

  public ngOnInit(): void {
    this.inspectionCategoryShortNames =
      this.inspectionCategoryCacheService.getInspectionCategoryShortNames(
        this.inspection.categoryResults.map((categoryResult) => categoryResult.categoryNumber)
      );
    this.inspectionStyles = this.enumCacheService.getInspectionStyles();
    this.inspectionStates = this.enumCacheService
      .getInspectionStates()
      .filter(
        (status) =>
          status.asEnum === InspectionStatus.Working || status.asEnum === InspectionStatus.Entered
      );
    this.tmpInspectionStatus = this.inspectionStates.find(
      (inspectionStatus) => inspectionStatus.asEnum === this.inspection.inspectionStatus.asEnum
    );
  }

  public saveInspection() {
    if (this.form.valid) {
      this.inspectionDBService.putInspectionWithResults(this.inspection).subscribe(
        (success: string) => {
          console.log(success);
        },
        (err: any) => {
          console.log(err);
          this.alerts.danger(
            this.translateService.instant('Die Grunddaten konnten nicht gespeichert werden.'),
            err
          );
        }
      );
    }
  }

  public changeInspectionStatus() {
    setTimeout(() => {
      if (this.tmpInspectionStatus.asEnum === InspectionStatus.Entered) {
        const notDefinedPointsMap = this.notDefinedPointsService.getNotDefinedPointsMap(
          this.inspection
        );
        if (notDefinedPointsMap.size) {
          this.displayStatusDingsbums = true;
          const statusChangeModal = this.modalService.open(StatusChangeDialogComponent, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
          statusChangeModal.componentInstance.notDefinedPointsMap = notDefinedPointsMap;
          statusChangeModal.result.then(
            () => {
              this.inspection.inspectionStatus = this.tmpInspectionStatus;
              this.displayStatusDingsbums = false;
              this.saveInspection();
            },
            () => {
              this.displayStatusDingsbums = false;
              this.tmpInspectionStatus = this.inspection.inspectionStatus;
            }
          );
        } else {
          this.inspection.inspectionStatus = this.tmpInspectionStatus;
          this.saveInspection();
        }
      } else {
        this.inspection.inspectionStatus = this.tmpInspectionStatus;
        this.saveInspection();
      }
    }, 0);
  }

  public setInspectionDateToToday() {
    const today = new Date();
    const dateString = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.form.form.controls.inspectionDate.setValue(dateString);
    this.saveInspection();
  }

  public sameKey = (a, b) => (a && b ? a.key === b.key : a === b);

  public sameEnumValue = (a, b) => (a && b ? a.asEnum === b.asEnum : a === b);
}
