import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PointToPointGroupMappingConfig } from '../api/model/pointToPointGroupMappingConfig';
import { ClientConfigDBService } from '../db/client-config-db-service';

@Injectable()
export class PointToPointGroupMappingConfigResolver
  implements Resolve<PointToPointGroupMappingConfig>
{
  public constructor(private clientConfigDbService: ClientConfigDBService) {}

  public resolve() {
    return this.clientConfigDbService.getPointToPointGroupMappingConfig();
  }
}
