<nav class="navbar-mkde navbar-default navbar-fixed-top">
  <ul class="nav navbar-nav">
    <li [ngClass]="{ active: currentPage === 'search' }">
      <div
        ngbPopover="{{ 'LoginRequiredForSearch' | translate }}"
        placement="bottom"
        triggers="mouseenter:mouseleave"
        [disablePopover]="connectionStateService.loggedIn || currentPage === 'search'"
        class="popover-left-border">
        <button
          class="btn btn-link btn-nav"
          [routerLink]="['/search']"
          [disabled]="!connectionStateService.loggedIn || currentPage === 'search'"
          translate>
          Kontrollen hinzufügen
        </button>
      </div>
    </li>
    <li [ngClass]="{ active: currentPage === 'dashboard' }">
      <button
        class="btn btn-link btn-nav"
        [routerLink]="['/dashboard']"
        [disabled]="currentPage === 'dashboard'"
        translate>
        Meine Kontrollen
      </button>
    </li>
    <li [ngClass]="{ active: currentPage === 'detail' }">
      <div
        ngbPopover="{{ 'SelectOneInspectionForEditResults' | translate }}"
        placement="bottom"
        triggers="mouseenter:mouseleave"
        [disablePopover]="inspectionKey || currentPage === 'detail'">
        <button
          class="btn btn-link btn-nav"
          [routerLink]="['/detail', inspectionKey]"
          [disabled]="!inspectionKey || currentPage === 'detail'"
          translate>
          Ergebnisse erfassen
        </button>
      </div>
    </li>
    <li [ngClass]="{ active: currentPage === 'protocol' }">
      <div
        ngbPopover="{{ 'SelectOneInspectionForEditProtocol' | translate }}"
        placement="bottom"
        triggers="mouseenter:mouseleave"
        [disablePopover]="inspectionKey || currentPage === 'protocol'">
        <button
          class="btn btn-link btn-nav"
          [routerLink]="['/protocol', inspectionKey]"
          [disabled]="!inspectionKey || currentPage === 'protocol'"
          translate>
          Kontrollprotokoll
        </button>
      </div>
    </li>
    <li
      *ngIf="userIsAdmin"
      [ngClass]="{ active: currentPage === 'administration' }"
      class="ms-auto">
      <div>
        <button
          class="btn btn-link btn-nav"
          [routerLink]="['/administration']"
          [disabled]="currentPage === 'administration'"
          translate>
          Administration
        </button>
      </div>
    </li>
  </ul>
</nav>
