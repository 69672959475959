import { Component } from '@angular/core';
import { MkdeAlertHolderDirective } from './mkde-alert-holder.directive';
import { JsonPipe } from '@angular/common';
import { keysToTranslate } from '@core/util/keys-to-translate';

/**
 * Displays the alerts of the enclosing AlertHolder.
 */
@Component({
  selector: 'app-mkde-alert-list',
  template: `
    <app-mkde-alert
      *ngFor="let alert of alertHolder.alerts"
      [type]="alert.type"
      (dismiss)="alertHolder.dismiss(alert)">
      {{ alert.message }}
      <div style="margin-top:20px" *ngIf="alert.type === 'danger' && alert.error">
        <form-control labelKey="Alert.Error">
          <textarea name="error" rows="4" [ngModel]="getErrorString(alert.error)"></textarea>
        </form-control>
      </div>
    </app-mkde-alert>
  `,
})
export class MkdeAlertListComponent {
  public constructor(public alertHolder: MkdeAlertHolderDirective, public jsonPipe: JsonPipe) {
    keysToTranslate(['Alert.Error']);
  }

  public getErrorString(error: any): string {
    try {
      return this.jsonPipe.transform(error);
    } catch {
      return error;
    }
  }
}
