import { OnInit, Component } from '@angular/core';
import { VersionService, I18NText } from '../core/api';
import { environment } from '../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientConfigDBService } from '../core/db/client-config-db-service';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
})
export class AboutDialogComponent implements OnInit {
  public acmobileAbout: I18NText;
  public acmobileServicesVersion: string;
  public acmobileClientVersion: string;
  public inspectionTypeConfigVersion: string;
  public categoryConfigVersion: string;
  public pointToCategoryMappingConfigVersion: string;
  public pointToPointGroupMappingConfigVersion: string;
  public pointConfigVersion: string;
  public clientConfigVersion: string;
  public versionInfoCollapsed = true;

  public constructor(
    private versionService: VersionService,
    public modal: NgbActiveModal,
    private clientConfigDBService: ClientConfigDBService
  ) {}

  public ngOnInit(): void {
    this.acmobileClientVersion = environment.acmobileClientVersion;
    this.versionService.apiVersionGet().subscribe(
      (version) => {
        this.acmobileServicesVersion = version;
      },
      () => {}
    );
    this.clientConfigDBService.getInspectionTypeConfig().subscribe(
      (config) => {
        if (config) {
          this.inspectionTypeConfigVersion = config.version;
        }
      },
      () => {}
    );
    this.clientConfigDBService.getCategoryConfig().subscribe(
      (config) => {
        if (config) {
          this.categoryConfigVersion = config.version;
        }
      },
      () => {}
    );
    this.clientConfigDBService.getPointToCategoryMappingConfig().subscribe(
      (config) => {
        if (config) {
          this.pointToCategoryMappingConfigVersion = config.version;
        }
      },
      () => {}
    );
    this.clientConfigDBService.getPointToPointGroupMappingConfig().subscribe(
      (config) => {
        if (config) {
          this.pointToPointGroupMappingConfigVersion = config.version;
        }
      },
      () => {}
    );
    this.clientConfigDBService.getPointConfig().subscribe(
      (config) => {
        if (config) {
          this.pointConfigVersion = config.version;
        }
      },
      () => {}
    );
    this.clientConfigDBService.getClientConfig().subscribe(
      (config) => {
        if (config) {
          this.clientConfigVersion = config.version;
          this.acmobileAbout = {
            germanText: config.aCmobileAboutDe,
            frenchText: config.aCmobileAboutFr,
          };
        }
      },
      () => {}
    );
  }
}
