<app-navigation [currentPage]="'administration'" [inspectionKey]="null"></app-navigation>
<div class="page-content">
  <h2 class="title-divider mt-4 mb-4">
    <span translate>Konfigurationsdateien</span>
  </h2>

  <table class="table table-striped table-hover bsg-table config-table">
    <thead>
      <tr>
        <th>
          <span translate>Datei</span>
        </th>
        <th class="config-table-actions-column" translate>Json</th>
        <th class="config-table-actions-column" translate>Excel</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <span translate>Basiskonfiguration Client</span>
        </td>
        <td
          app-administration-config-table-action
          (download)="downloadClientConfig()"
          (upload)="uploadClientConfig($event)"></td>
        <td
          app-administration-config-table-action
          (download)="downloadExcelClientConfig()"
          (upload)="uploadExcelClientConfig($event)"></td>
      </tr>
      <tr>
        <td>
          <span translate>Kontrolltyp-Konfiguration</span>
        </td>
        <td
          app-administration-config-table-action
          (download)="downloadInspectionTypeConfig()"
          (upload)="uploadInspectionTypeConfig($event)"></td>
        <td
          app-administration-config-table-action
          (download)="downloadExcelInspectionTypeConfig()"
          (upload)="uploadExcelInspectionTypeConfig($event)"></td>
      </tr>
      <tr>
        <td>
          <span translate>Rubrik-Konfiguration</span>
        </td>
        <td
          app-administration-config-table-action
          (download)="downloadCategoryConfig()"
          (upload)="uploadCategoryConfig($event)"></td>
        <td
          app-administration-config-table-action
          (download)="downloadExcelCategoryConfig()"
          (upload)="uploadExcelCategoryConfig($event)"></td>
      </tr>
      <tr>
        <td>
          <span translate>Kontrollpunkt-Konfiguration (Mängel- und Massnahmenvorschläge)</span>
        </td>
        <td
          app-administration-config-table-action
          (download)="downloadPointConfig()"
          (upload)="uploadPointConfig($event)"></td>
        <td
          app-administration-config-table-action
          (download)="downloadExcelPointConfig()"
          (upload)="uploadExcelPointConfig($event)"></td>
      </tr>
      <tr>
        <td>
          <span translate>Abhängigkeiten Punkt -> Rubrik</span>
        </td>
        <td
          app-administration-config-table-action
          (download)="downloadPointToCategoryMappingsConfig()"
          (upload)="uploadPointToCategoryMappingsConfig($event)"></td>
        <td
          app-administration-config-table-action
          (download)="downloadExcelPointToCategoryMappingsConfig()"
          (upload)="uploadExcelPointToCategoryMappingsConfig($event)"></td>
      </tr>
      <tr>
        <td>
          <span translate>Abhängigkeiten Punkt -> Punktegruppe</span>
        </td>
        <td
          app-administration-config-table-action
          (download)="downloadPointToPointGroupMappingsConfig()"
          (upload)="uploadPointToPointGroupMappingsConfig($event)"></td>
        <td
          app-administration-config-table-action
          (download)="downloadExcelPointToPointGroupMappingsConfig()"
          (upload)="uploadExcelPointToPointGroupMappingsConfig($event)"></td>
      </tr>
    </tbody>
  </table>
</div>
