import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { DatePipe } from '@angular/common';
import { InspectionWithResultsDto } from '@core/api';
import { TranslateService } from '@ngx-translate/core';

export class ProtocolInspectionService {
  public constructor(
    private translateService: TranslateService,
    private inspectionWithResult: InspectionWithResultsDto,
    private datePipe: DatePipe
  ) {}

  public getName(): string {
    return this.inspectionWithResult.unitName != null ? this.inspectionWithResult.unitName : '';
  }

  public getResponsiblePersonName(): string {
    let result = null;
    if (this.inspectionWithResult.unitResponsiblePersonFirstName != null) {
      result = this.inspectionWithResult.unitResponsiblePersonFirstName;
    }
    if (this.inspectionWithResult.unitResponsiblePersonSurname != null) {
      if (result != null) {
        result += ' ';
      }
      result += this.inspectionWithResult.unitResponsiblePersonSurname;
    }
    return result;
  }

  public getResponsiblePersonPhones(): string {
    let result = null;
    if (this.inspectionWithResult.unitResponsiblePersonHomePhones != null) {
      result = this.inspectionWithResult.unitResponsiblePersonHomePhones;
    }
    if (this.inspectionWithResult.unitResponsiblePersonWorkPhones != null) {
      if (result != null) {
        result += ', ';
      }
      result += this.inspectionWithResult.unitResponsiblePersonWorkPhones;
    }
    return result;
  }

  public getResponsiblePersonAddress(): string {
    let result = null;
    if (this.inspectionWithResult.unitResponsiblePersonStreetName != null) {
      result = this.inspectionWithResult.unitResponsiblePersonStreetName;
    }
    if (this.inspectionWithResult.unitResponsiblePersonStreetNumber != null) {
      if (result != null) {
        result += ' ';
      }
      result += this.inspectionWithResult.unitResponsiblePersonStreetNumber;
    }
    return result;
  }

  public getResponsiblePersonMobilePhone(): string {
    return this.inspectionWithResult.unitResponsiblePersonMobilePhones != null
      ? this.inspectionWithResult.unitResponsiblePersonMobilePhones
      : '';
  }

  public getUnitResponsiblePostalZone(): string {
    return this.inspectionWithResult.unitResponsiblePersonPostalCode != null
      ? this.inspectionWithResult.unitResponsiblePersonPostalCode
      : '';
  }

  public getUnitResponsibleTown(): string {
    return this.inspectionWithResult.unitResponsiblePersonTown != null
      ? this.inspectionWithResult.unitResponsiblePersonTown
      : '';
  }

  public getResponsiblePersonEmail(): string {
    return this.inspectionWithResult.unitResponsiblePersonEmail != null
      ? this.inspectionWithResult.unitResponsiblePersonEmail
      : '';
  }

  public getInspectorName(): string {
    return this.inspectionWithResult.inspectorName != null
      ? this.inspectionWithResult.inspectorName
      : '';
  }

  public getTvdId(): string {
    return this.inspectionWithResult.unitTvdId != null ? this.inspectionWithResult.unitTvdId : '';
  }

  public getCanton(): string {
    return this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitCanton');
  }

  public getCantonId(): string {
    return this.inspectionWithResult.unitCantonId != null
      ? this.inspectionWithResult.unitCantonId
      : '';
  }

  public getControlCenter(): string {
    return this.translateService.instant('ProtocolPdf.VacherinAndGruyereAuditorAuditorRow1Col2');
  }

  public getCurrentDate(): string {
    return this.datePipe.transform(new Date(), ProtocolPdfConstants.dateFormat);
  }

  public getFileName(prefix: string): string {
    return `${prefix}-CL-${this.inspectionWithResult.inspectionId}.pdf`;
  }

  public getLocationAndCurrentDate(): string {
    let result = this.getUnitResponsibleTown();
    if (result != null && result !== '') {
      result += ', ';
    }
    result += this.getCurrentDate();
    return result;
  }
}
