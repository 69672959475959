import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { InspectionStatusDto } from '../api/model/inspectionStatusDto';
import { InspectionStatusDBService } from '../db/inspection-status-db-service';

@Injectable()
export class InspectionStatesResolver implements Resolve<InspectionStatusDto[]> {
  public constructor(private inspectionStatusDBService: InspectionStatusDBService) {}

  public resolve() {
    return this.inspectionStatusDBService.getInspectionStates();
  }
}
