/**
 * MKDE Intermediary API
 * Application internal API, providing resources for the frontend.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SearchMode = 'and' | 'or';

export const SearchMode = {
    And: 'and' as SearchMode,
    Or: 'or' as SearchMode
};

