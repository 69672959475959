import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'underscore';
import { NgForm } from '@angular/forms';
import { InspectionDBService } from '../core/db/inspection-db-service';
import { InspectionListItemDto } from '../dtos/inspectionListItemDto';
import { TranslateService } from '@ngx-translate/core';
import { SyncService } from '../core/sync/sync-service';
import { forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { InspectionListSorter } from '../util/inspectionListSorter';
import { keysToTranslate } from 'src/app/core/util/keys-to-translate';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { ConnectionStateService } from '../core/connection-state.service';
import { InspectionService } from '../core/api';
import { Category } from '../core/api/model/category';
import { CategoryConfig } from '../core/api/model/categoryConfig';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionCategoryResultDto } from '../core/api/model/inspectionCategoryResultDto';
import { InspectionSearchDto } from '../core/api/model/inspectionSearchDto';
import { InspectionSearchResultDto } from '../core/api/model/inspectionSearchResultDto';
import { InspectionSearchResultListDto } from '../core/api/model/inspectionSearchResultListDto';
import { PersonSearchDto } from '../core/api/model/personSearchDto';
import { UnitSearchDto } from '../core/api/model/unitSearchDto';
import { InspectionWithResultsDto } from '../core/api/model/inspectionWithResultsDto';
import { mergeMap } from 'rxjs/operators';
import { InspectionStatus } from '../core/api/model/inspectionStatus';
import { SearchMode } from '../core/api/model/searchMode';
import { I18nTextPipe } from '@core/pipes/i18n-text.pipe';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  @ViewChild('form', { static: true })
  public form: NgForm;
  public inspectionSearchDto: InspectionSearchDto;
  public searchType = 'Betriebe';
  public results: InspectionListItemDto[];
  public resultsSearchType: string;
  public selectableIds = new Array<string>();
  public searching = false;
  public sortedBy: string;
  public classNames;
  public selectAllChecked = false;
  public downloading = false;

  private sortedAscending = true;
  private categoryConfigs: Category[];

  public constructor(
    protected i18nTextPipe: I18nTextPipe,
    private inspectionService: InspectionService,
    private inspectionDbService: InspectionDBService,
    private route: ActivatedRoute,
    private syncService: SyncService,
    private translateService: TranslateService,
    private alerts: MkdeAlertHolderDirective,
    private inspectionListSorter: InspectionListSorter,
    private connectionStateService: ConnectionStateService
  ) {
    keysToTranslate([
      'InvalidNumber',
      'Search.BurNr',
      'Search.DatumBis',
      'Search.DatumVon',
      'Search.KantonId',
      'Search.KantonalePersonennummer',
      'Search.Ort',
      'Search.PersonNachname',
      'Search.PersonVorname',
      'Search.Plz',
      'Search.Sozialversicherungsnummer',
      'Search.TvdNr',
      'Search.UnitName',
    ]);
  }

  public ngOnInit() {
    this.resetSearchForm();
    const categoryConfig = this.route.snapshot.data['categoryConfig'] as CategoryConfig;
    if (categoryConfig) {
      this.categoryConfigs = categoryConfig.categories;
    }
  }

  public canUserSearchOwnInspections(): boolean {
    const isUserOnlyInspector = this.connectionStateService.isUserOnlyInspector();
    return isUserOnlyInspector === false;
  }

  public search(): void {
    this.connectionStateService
      .hasConnection()
      .pipe(
        mergeMap((hasConnection) => {
          if (hasConnection) {
            this.results = null;
            this.searching = true;
            this.sortedBy = null;
            this.sortedAscending = null;
            const searchParameters = Object.assign({}, this.inspectionSearchDto);
            if (this.searchType === 'Betriebe') {
              searchParameters.personSearchArguments = null;
            } else {
              searchParameters.unitSearchArguments = null;
            }
            const ktIdP = searchParameters.personSearchArguments
              ? searchParameters.personSearchArguments.ktId
              : null;
            return forkJoin([
              this.inspectionService.searchInspections(ktIdP, searchParameters),
              this.inspectionDbService.getAllInspectionsWithResults(),
            ]);
          }
        })
      )
      .subscribe(
        (data) => {
          const searchResult = data[0] as InspectionSearchResultListDto;
          const existingInspectionIds = (data[1] as InspectionWithResultsDto[]).map(
            (inspection) => inspection.inspectionKey
          );
          this.results = this.getInspectionListItemDtos(
            searchResult.inspections,
            existingInspectionIds
          );
          this.sortBy('name');
          this.searching = false;
          this.resultsSearchType = this.searchType;
          if (!this.results || !this.results.length) {
            this.alerts.info(this.translateService.instant('Es wurden keine Kontrollen gefunden.'));
          }
        },
        (error) => {
          console.log(error);
          if (error.status === 401) {
            this.alerts.danger(
              this.translateService.instant(
                'Sie sind nicht mehr mit Acontrol verbunden. Bitte melden Sie sich erneut an.'
              ),
              error
            );
          } else {
            this.alerts.danger(
              this.translateService.instant(
                'Bei der Acontrol-Suche trat ein technischer Fehler auf.'
              ),
              error
            );
          }
          this.searching = false;
        }
      );
  }

  public resetSearchForm(): void {
    this.inspectionSearchDto = {
      complaintRemedyDateDueFrom: null,
      complaintRemedyDateDueTo: null,
      hasBPCase: null,
      hasDefect: null,
      hasReduction: null,
      hasSanction: null,
      inspectionCategoryKeys: null,
      inspectionCenterKey: null,
      inspectionDateFrom: null,
      inspectionDateTo: null,
      inspectionReasonKey: null,
      inspectionStatuses: [InspectionStatus.Planned, InspectionStatus.Working],
      inspectionStyleKey: null,
      inspectionTypeKey: null,
      inspectorKey: null,
      livestockOwnerFeedbackDateDueFrom: null,
      livestockOwnerFeedbackDateDueTo: null,
      nameOfInspector: null,
      personSearchArguments: this.getEmptyPersonSearchDto(),
      projectKey: null,
      unitKey: null,
      unitSearchArguments: this.getEmptyUnitSearchDto(),
      withoutBPCaseKey: null,
      onlyOwnInspections: false,
    };
    // Besides of clearing the model, we need to explicitly reset the form controls, especially the date-pickers.
    this.form.form.reset();
    this.results = null;

    // Setting a default value for the "from" date only works like this; directly setting the model results in an exception.
    setTimeout(() => {
      const year = new Date().getFullYear();
      const defaultDate = '' + year + '-01-01';
      if (this.searchType === 'Betriebe') {
        this.form.form.controls.unitDatumVon.setValue(defaultDate);
      } else {
        this.form.form.controls.personDatumVon.setValue(defaultDate);
      }
    }, 0);
  }

  public toggleSelectAll(): void {
    this.results.forEach((searchResult) => {
      searchResult.isSelected = this.selectAllChecked && searchResult.isSelectable;
    });
  }

  public downloadInspections(): void {
    this.connectionStateService.hasConnection().subscribe((hasConnection) => {
      if (hasConnection) {
        this.downloading = true;
        const selectedInspectionKeys = this.results
          .filter((inspection) => inspection.isSelectable && inspection.isSelected)
          .map((inspection) => inspection.inspectionKey);
        this.syncService.syncDown(selectedInspectionKeys).subscribe(
          (success) => {
            console.log(success);
            this.results
              .filter((inspection) => inspection.isSelected)
              .forEach((inspection) => {
                inspection.isSelectable = false;
                inspection.isSelected = false;
              });
            this.selectAllChecked = false;
            this.alerts.success(
              this.translateService.instant(
                'Die ausgewählten Kontrollen wurden erfolgreich heruntergeladen.'
              )
            );
            this.downloading = false;
          },
          (error) => {
            console.log(error);
            if (error.status === 401) {
              this.alerts.danger(
                this.translateService.instant(
                  'Sie sind nicht mehr mit Acontrol verbunden. Bitte melden Sie sich erneut an.'
                ),
                error
              );
            } else {
              this.alerts.danger(
                this.translateService.instant(
                  'Die ausgewählten Kontrollen konnten aufgrund eines technischen Fehlers nicht heruntergeladen werden.'
                ),
                error
              );
            }
            this.downloading = false;
          }
        );
      }
    });
  }

  public canDownloadInspections(): boolean {
    return this.results && this.results.some((r) => r.isSelected && r.isSelectable);
  }

  public sortBy(field: string) {
    if (this.sortedBy === field) {
      this.sortedAscending = !this.sortedAscending;
      this.setOrderedByClassNames();
      this.results.reverse();
    } else {
      this.sortedBy = field;
      this.sortedAscending = true;
      this.results = this.inspectionListSorter.sort(this.results, field);
      this.setOrderedByClassNames();
    }

    return false; // do not follow the href
  }

  protected getInspectionStyleName(inspection: InspectionSearchResultDto) {
    let inspectionStyleName = '';
    if (inspection.inspectionStyle && inspection.inspectionStyle.name) {
      inspectionStyleName = this.i18nTextPipe.transform(inspection.inspectionStyle.name);
    }
    return inspectionStyleName;
  }

  private setOrderedByClassNames() {
    this.classNames = 'fas fa-chevron-';

    if (this.sortedAscending) {
      this.classNames += 'up';
    } else {
      this.classNames += 'down';
    }

    this.classNames += '  text-primary';
  }

  private getEmptyPersonSearchDto(): PersonSearchDto {
    return {
      amicusId: null,
      canton: null,
      community: null,
      dogChipNumber: null,
      firstname: null,
      glnNumber: null,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ktId: null,
      languageKey: null,
      personAttributes: null,
      personCantonProperties: null,
      postalZone: null,
      searchInAmicus: false,
      socialSecurityNumber: null,
      streetName: null,
      streetNumber: null,
      surname: null,
      town: null,
      yearBorn: null,
      searchByAttachedAssigneeUnits: false,
    };
  }

  private getEmptyUnitSearchDto(): UnitSearchDto {
    return {
      agisId: null,
      burId: null,
      cantonTwoLetterName: null,
      commune: null,
      enterpriseAttributes: null,
      name: null,
      nogaCode: null,
      onlyAgis: false,
      postalZone: null,
      ktId: null,
      searchByCantonOfResidence: true,
      searchByCantonOfSite: true,
      searchMode: SearchMode.And,
      town: null,
      tvdId: null,
      uId: null,
      searchByAttachedAssigneeUnits: false,
    };
  }

  private getInspectionListItemDtos(
    searchResultDtos: InspectionSearchResultDto[],
    existingInspectionIds: string[]
  ): InspectionListItemDto[] {
    return searchResultDtos.map((searchResultDto) =>
      this.getInspectionListItemDto(searchResultDto, existingInspectionIds)
    );
  }

  private getInspectionListItemDto(
    searchResultDto: InspectionSearchResultDto,
    existingInspectionIds: string[]
  ): InspectionListItemDto {
    const inspectionListItemDto: InspectionListItemDto = new InspectionListItemDto();
    inspectionListItemDto.inspectionKey = searchResultDto.key;
    inspectionListItemDto.isSelectable = !existingInspectionIds.includes(searchResultDto.key);
    inspectionListItemDto.inspectionDate = searchResultDto.inspectionDate;
    inspectionListItemDto.inspectionStyleName = this.getInspectionStyleName(searchResultDto);
    inspectionListItemDto.inspectorName = searchResultDto.inspectorName;
    if (searchResultDto.inspectionStatus) {
      inspectionListItemDto.inspectionStatus = searchResultDto.inspectionStatus.description;
    }
    if (searchResultDto.inspectionTypeSummary) {
      inspectionListItemDto.inspectionType = searchResultDto.inspectionTypeSummary.name;
    } else if (searchResultDto.inspectionCategoryResults.length) {
      inspectionListItemDto.categoryShortNames = this.getInspectionCategoryShortNames(
        searchResultDto.inspectionCategoryResults
      );
    }

    if (searchResultDto.person) {
      const person = searchResultDto.person;
      inspectionListItemDto.name = person.surname + ' ' + person.firstname;
      inspectionListItemDto.address = person.streetName;
      if (person.streetNumber) {
        inspectionListItemDto.address += ' ' + person.streetNumber;
      }
      inspectionListItemDto.postalZone = person.postalCode;
      inspectionListItemDto.town = person.town;
      inspectionListItemDto.cantonId = person.ktId;
      inspectionListItemDto.isActive = person.isActive;
    } else if (searchResultDto.unit) {
      const unit = searchResultDto.unit;
      inspectionListItemDto.name = unit.name;
      inspectionListItemDto.address = unit.streetName;
      if (unit.streetNumber) {
        inspectionListItemDto.address += ' ' + unit.streetNumber;
      }
      inspectionListItemDto.postalZone = unit.postalZone;
      inspectionListItemDto.town = unit.town;
      inspectionListItemDto.cantonId = unit.ktId;
      inspectionListItemDto.tvdNumber = unit.tvdId;
      inspectionListItemDto.burNumber = unit.burId;
      inspectionListItemDto.isActive = unit.isActive;
    }

    return inspectionListItemDto;
  }

  private getInspectionCategoryShortNames(
    inspectionCategories: InspectionCategoryResultDto[]
  ): I18NText[] {
    if (this.categoryConfigs) {
      return inspectionCategories.map((inspectionCategory) => {
        const category = this.categoryConfigs.find((c) => c.number === inspectionCategory.number);
        if (category) {
          return {
            germanText: category.shortLabelDe,
            frenchText: category.shortLabelFr,
            italianText: null,
          };
        } else {
          return {
            germanText: inspectionCategory.number,
            frenchText: inspectionCategory.number,
            italianText: inspectionCategory.number,
          };
        }
      });
    } else {
      return inspectionCategories.map((inspectionCategory) => inspectionCategory.name);
    }
  }
}
