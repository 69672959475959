import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable } from 'rxjs';
import { MKDEUserDto } from '../api/model/models';

@Injectable()
export class UserDBService {
  private readonly idUser = 'user';

  public constructor(private dbService: DBService) {}

  public getUser(): Observable<MKDEUserDto> {
    return this.dbService.getById<MKDEUserDto>(this.idUser);
  }

  public putUser(user: MKDEUserDto): Observable<string> {
    return this.dbService.put<MKDEUserDto>(user, this.idUser);
  }
}
