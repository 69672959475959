/* eslint-disable @typescript-eslint/member-ordering */

import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class PdfDocumentParagraph {
  public positionY: number;
  public multiLinesPrefix?: string;
  public keepParagraphInSamePage?: boolean;
  public headerText: string;

  private currentParagraphTexts?: string[];
  private currentPrefix?: string;
  private currentMultiLinePrefix?: string;
  private currentHeaderFontSize?: number;
  private currentHeaderFontType?: string;
  private currentBodyFontSize?: number;

  public get bodyFontSize(): number {
    if (typeof this.currentBodyFontSize === 'undefined') {
      this.currentBodyFontSize = ProtocolPdfConstants.defaultFontSize;
    }
    return this.currentBodyFontSize;
  }

  public set bodyFontSize(value: number) {
    if (value < 0) {
      this.currentBodyFontSize = ProtocolPdfConstants.defaultFontSize;
    }
    this.currentBodyFontSize = value;
  }

  public get headerFontSize(): number {
    if (typeof this.currentHeaderFontSize === 'undefined') {
      this.currentHeaderFontSize = ProtocolPdfConstants.defaultFontSize;
    }
    return this.currentHeaderFontSize;
  }

  public set headerFontSize(value: number) {
    if (value < 0) {
      this.currentHeaderFontSize = ProtocolPdfConstants.defaultFontSize;
    }
    this.currentHeaderFontSize = value;
  }

  public get headerFontType(): string {
    if (typeof this.currentHeaderFontType === 'undefined') {
      this.currentHeaderFontType = ProtocolPdfConstants.defaultFontType;
    }
    return this.currentHeaderFontType;
  }

  public set headerFontType(value: string) {
    if (value === null || typeof value === 'undefined') {
      this.currentHeaderFontType = ProtocolPdfConstants.defaultFontType;
    }
    this.currentHeaderFontType = value;
  }

  public get prefix(): string {
    if (typeof this.currentPrefix === 'undefined') {
      this.currentPrefix = '- ';
    }
    return this.currentPrefix;
  }

  public set prefix(value: string) {
    if (value === null || typeof value === 'undefined') {
      this.currentPrefix = '- ';
    }
    this.currentPrefix = value;
  }

  public get multiLinePrefix(): string {
    if (typeof this.currentMultiLinePrefix === 'undefined') {
      this.currentMultiLinePrefix = ' ';
    }
    return this.currentMultiLinePrefix;
  }

  public set multiLinePrefix(value: string) {
    if (value === null || typeof value === 'undefined') {
      this.currentMultiLinePrefix = ' ';
    }
    this.currentMultiLinePrefix = value;
  }

  public get paragraphTexts(): string[] {
    if (typeof this.currentParagraphTexts === 'undefined') {
      this.currentParagraphTexts = [];
    }
    return this.currentParagraphTexts;
  }

  public set paragraphTexts(value: string[]) {
    if (value === null || typeof value === 'undefined') {
      this.currentParagraphTexts = [];
    }
    this.currentParagraphTexts = value;
  }
}
