import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmOverrideDialogComponent } from '../confirm-override-dialog/confirm-override-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { I18NText } from '../core/api/model/models';
import { InspectionResultType } from '../core/api/model/inspectionResultType';

@Component({
  selector: 'app-detail-result-dropdown',
  templateUrl: './detail-result-dropdown.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailResultDropdownComponent implements OnInit, OnChanges {
  @Input()
  public result: FlatInspectionResultDto;

  // We need a separate field for the result value, as the change detection does not work on object properties
  @Input()
  public resultType: InspectionResultType;

  @Input()
  public dependingResults: FlatInspectionResultDto[];

  @Input()
  public displayDefectOption: boolean;

  @Input()
  public displayEditRemark: boolean;

  @Input()
  public readonly = false;

  @Input()
  public elementName: I18NText;

  @Input()
  public defectDescriptionSuggestions: I18NText[];

  @Output()
  public resultChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('resultType', { static: true }) public resultTypeRef: ElementRef;

  public resultTypes = InspectionResultType;

  public localResultType: InspectionResultType;

  public constructor(private modalService: NgbModal, private translateService: TranslateService) {}

  public onChangeResult() {
    this.resultTypeRef.nativeElement.ownerDocument.activeElement.blur();
    if (this.result.inspectionResult !== this.localResultType) {
      if (
        this.dependingResults.some((dependingResult) =>
          this.wouldResultOverrideDependingResult(this.localResultType, dependingResult)
        )
      ) {
        const modal = this.modalService.open(ConfirmOverrideDialogComponent, {
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-dialog-md',
        });
        modal.componentInstance.confirmationText =
          this.translateService.instant('ConfirmOverrideResults');
        modal.result.then(
          () => {
            this.saveUpdatedResult();
          },
          () => {
            setTimeout(() => (this.localResultType = this.result.inspectionResult), 0);
          }
        );
      } else {
        this.saveUpdatedResult();
      }
    }
  }

  public onChangeRemark(): void {
    this.resultChange.emit();
  }

  public ngOnInit(): void {
    this.localResultType = this.result.inspectionResult;
  }

  public ngOnChanges(changes: any): void {
    if (changes.resultType) {
      this.localResultType = this.result.inspectionResult;
    }
  }

  private saveUpdatedResult() {
    this.result.inspectionResult = this.localResultType;
    this.result.remark = null;
    this.result.defectDescription = null;
    this.resultChange.emit();
  }

  private wouldResultOverrideDependingResult(
    inspectionResult: InspectionResultType,
    dependingResult: FlatInspectionResultDto
  ): boolean {
    let wouldOverrideDependingResult = false;
    if (dependingResult) {
      if (!dependingResult.fieldValue) {
        if (
          inspectionResult === InspectionResultType.NotApplicable ||
          inspectionResult === InspectionResultType.NotControlled
        ) {
          wouldOverrideDependingResult =
            dependingResult.inspectionResult !== InspectionResultType.NotDefined &&
            (dependingResult.inspectionResult !== inspectionResult ||
              (dependingResult.remark != null && dependingResult.remark !== ''));
        } else if (inspectionResult === InspectionResultType.Defect) {
          wouldOverrideDependingResult =
            dependingResult.inspectionResult !== InspectionResultType.NotDefined &&
            (dependingResult.inspectionResult !== inspectionResult ||
              (dependingResult.defectDescription != null &&
                dependingResult.defectDescription !== ''));
        } else if (!inspectionResult || inspectionResult === InspectionResultType.NotDefined) {
          wouldOverrideDependingResult =
            dependingResult.inspectionResult !== InspectionResultType.NotDefined &&
            dependingResult.inspectionResult !== inspectionResult;
        }
      } else if (
        inspectionResult === InspectionResultType.NotApplicable ||
        inspectionResult === InspectionResultType.NotControlled
      ) {
        wouldOverrideDependingResult =
          dependingResult.fieldValue.dateValue != null ||
          dependingResult.fieldValue.numberValue != null ||
          (dependingResult.fieldValue.textValue != null &&
            dependingResult.fieldValue.textValue !== '') ||
          dependingResult.fieldValue.yesNoValue != null;
      }
    }
    return wouldOverrideDependingResult;
  }
}
