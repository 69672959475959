import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable } from 'rxjs';
import { FileDBService } from './file-db-service';
import { ProtocolDBService } from './protocol-db-service';
import { InspectionWithResultsDto } from '../api/model/models';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class InspectionDBService {
  private readonly idPrefixInspectionWithResults = 'inspection_with_results_';

  public constructor(
    private dbService: DBService,
    private fileDBService: FileDBService,
    private protocolDBService: ProtocolDBService
  ) {}

  public getInspectionWithResults(inspectionKey: string): Observable<InspectionWithResultsDto> {
    const id = this.getInspectionWithResultsId(inspectionKey);
    return this.dbService.getById<InspectionWithResultsDto>(id);
  }

  public getAllInspectionsWithResults(): Observable<InspectionWithResultsDto[]> {
    return this.dbService.getAll(this.idPrefixInspectionWithResults);
  }

  public putInspectionWithResults(
    inspectionWithResults: InspectionWithResultsDto
  ): Observable<string> {
    const id = this.getInspectionWithResultsId(inspectionWithResults.inspectionKey);
    return this.dbService.put<InspectionWithResultsDto>(inspectionWithResults, id);
  }

  public deleteInspectionWithRelatedData(inspectionKey: string): Observable<string> {
    return new Observable((observer: any) => {
      this.fileDBService
        .deleteFiles(inspectionKey)
        .pipe(
          concatMap((successDeleteFiles: string) => {
            console.log(successDeleteFiles);
            return this.protocolDBService.deleteProtocol(inspectionKey);
          }),
          concatMap((successDeleteProtocol: string) => {
            console.log(successDeleteProtocol);
            const id = this.getInspectionWithResultsId(inspectionKey);
            return this.dbService.delete(id);
          })
        )
        .subscribe((successDeleteInspection: string) => {
          console.log(successDeleteInspection);
          observer.next(
            `Inspection with key ${inspectionKey} and related data deleted from local database.`
          );
          observer.complete();
        });
    });
  }

  private getInspectionWithResultsId(inspectionKey: string): string {
    return `${this.idPrefixInspectionWithResults}${inspectionKey}`;
  }
}
