import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatInspectionCategoryResultDto } from '../core/api/model/flatInspectionCategoryResultDto';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionElementDto } from '../core/api/model/inspectionElementDto';
import { InspectionCategoryCacheService } from '../core/cache/inspection-category-cache-service';
import { InspectionResultType } from '../core/api/model/inspectionResultType';

@Component({
  selector: 'app-status-change-dialog',
  templateUrl: './status-change-dialog.component.html',
})
export class StatusChangeDialogComponent {
  @Input()
  public notDefinedPointsMap: Map<string, FlatInspectionResultDto[]>;

  @Input()
  public categoriesWithMissingDefectSeverity: FlatInspectionCategoryResultDto[];

  @Input()
  public furtherActionSelectionValid: boolean;

  public inspectionCategories: InspectionElementDto[];

  public categoriesWithMissingDefectSeverityDetailsCollapsed = true;

  public undefinedPointsDetailsCollapsed = true;

  public constructor(
    public modal: NgbActiveModal,
    private inspectionCategoryCacheService: InspectionCategoryCacheService
  ) {
    this.inspectionCategories = this.inspectionCategoryCacheService.getInspectionCategories();
  }

  public getNumberOfUndefinedPoints(): number {
    let numberOfUndefinedPoints = 0;
    this.notDefinedPointsMap.forEach((points) => (numberOfUndefinedPoints += points.length));
    return numberOfUndefinedPoints;
  }

  public getCategoryName(categoryNumber: string): I18NText {
    return this.inspectionCategories.find((category) => category.number === categoryNumber).name;
  }

  public getCategoryShortName(categoryNumber: string): I18NText {
    return this.inspectionCategoryCacheService.getInspectionCategoryShortName(categoryNumber);
  }

  public setResultsToOK(): void {
    this.notDefinedPointsMap.forEach((points) =>
      points.forEach((point) => {
        if (point.inspectionResult === InspectionResultType.NotDefined) {
          point.inspectionResult = null;
        }
      })
    );
    this.modal.close();
  }

  public setResultsToNK(): void {
    this.notDefinedPointsMap.forEach((points) =>
      points.forEach((point) => {
        if (point.inspectionResult === InspectionResultType.NotDefined) {
          point.inspectionResult = InspectionResultType.NotControlled;
        }
      })
    );
    this.modal.close();
  }
}
