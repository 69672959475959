import { Injectable } from '@angular/core';
import { InspectionStyleDto, InspectionStatusDto } from '../api/model/models';

@Injectable()
export class EnumCacheService {
  private inspectionStyles: InspectionStyleDto[];
  private inspectionStates: InspectionStatusDto[];

  public constructor() {}

  public setCache(inspectionStyles: InspectionStyleDto[], inspectionStates: InspectionStatusDto[]) {
    this.inspectionStyles = inspectionStyles;
    this.inspectionStates = inspectionStates;
  }

  public getInspectionStyles(): InspectionStyleDto[] {
    return this.inspectionStyles;
  }

  public getInspectionStates(): InspectionStatusDto[] {
    return this.inspectionStates;
  }
}
