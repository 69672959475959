import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmOverrideDialogComponent } from '../confirm-override-dialog/confirm-override-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { YesNoDialogComponent } from '../yes-no-dialog/yes-no-dialog.component';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { InspectionResultType } from '../core/api/model/inspectionResultType';

@Component({
  selector: 'app-detail-category-result',
  templateUrl: './detail-category-result.component.html',
  styleUrls: ['./detail-matrix.component.scss', './detail.component.scss'],
})
export class DetailCategoryResultComponent implements OnInit, OnChanges {
  @Input()
  public result: FlatInspectionResultDto;

  // We need a separate field for the result value, as the change detection does not work on object properties
  @Input()
  public resultType: InspectionResultType;

  @Input()
  public hasDefects = false;

  @Input()
  public readonly = false;

  @Output()
  public resultChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('resultType', { static: true }) public resultTypeRef: ElementRef;

  public resultTypes = InspectionResultType;

  public localResultType: InspectionResultType;

  public constructor(private modalService: NgbModal, private translateService: TranslateService) {}

  public onChangeResult() {
    this.resultTypeRef.nativeElement.ownerDocument.activeElement.blur();
    if (this.result.inspectionResult !== this.localResultType) {
      if (
        !this.localResultType ||
        this.localResultType === InspectionResultType.NotDefined ||
        this.localResultType === null
      ) {
        setTimeout(() => {
          const modal = this.modalService.open(YesNoDialogComponent, { size: 'md' });
          modal.componentInstance.text = this.translateService.instant('ConfirmTransferResults');
          modal.componentInstance.title = this.translateService.instant(
            'ConfirmTransferResultsTitle'
          );
          modal.result.then(
            () => {
              this.result.inspectionResult = this.localResultType;
              this.result.defectSeverity = null;
              this.resultChange.emit(true);
            },
            () => {
              this.result.inspectionResult = this.localResultType;
              this.result.remark = null;
              this.result.defectSeverity = null;
              this.resultChange.emit(false);
            }
          );
        }, 0);
      } else if (this.localResultType === InspectionResultType.Defect) {
        this.result.inspectionResult = this.localResultType;
        this.resultChange.emit(false);
      } else {
        setTimeout(() => {
          const modal = this.modalService.open(ConfirmOverrideDialogComponent, { size: 'sm' });
          modal.componentInstance.confirmationText =
            this.translateService.instant('ConfirmOverrideResults');
          modal.result.then(
            () => {
              this.result.inspectionResult = this.localResultType;
              this.result.defectSeverity = null;
              this.resultChange.emit(true);
            },
            () => {
              this.localResultType = this.result.inspectionResult;
            }
          );
        }, 0);
      }
    }
  }

  public onChangeDefectSeverity(): void {
    this.resultChange.emit(false);
  }

  public ngOnInit(): void {
    this.localResultType = this.result.inspectionResult;
  }

  public ngOnChanges(): void {
    this.localResultType = this.result.inspectionResult;
  }

  public isDefect(): boolean {
    return this.result.inspectionResult === InspectionResultType.Defect;
  }
}
