/**
 * MKDE Intermediary API
 * Application internal API, providing resources for the frontend.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PointGroupType = 'regular' | 'subheadWithoutDataEntry';

export const PointGroupType = {
    Regular: 'regular' as PointGroupType,
    SubheadWithoutDataEntry: 'subheadWithoutDataEntry' as PointGroupType
};

