<div
  class="align-right me-2"
  ngbPopover="{{ 'ScrollToTop' | translate }}"
  placement="left"
  triggers="mouseenter:mouseleave">
  <button
    class="btn btn-outline-primary btn-circle"
    (click)="scrollToTop()"
    [disabled]="!isScrolled">
    <span class="fas fa-chevron-up"></span>
  </button>
</div>
