<select
  class="form-control form-select"
  name="protocolPdfTemplates"
  [appDisable]="readonly"
  [ngModel]="selectedProtocolPdfTemplate"
  (ngModelChange)="onProtocolPdfTemplateSelected($event)">
  <option
    *ngFor="let protocolPdfTemplate of protocolPdfTemplates; asIsOrder"
    value="{{ protocolPdfTemplate }}">
    <span>{{ protocolPdfTemplate | translate }}</span>
  </option>
</select>
