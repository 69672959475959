import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppHeaderComponent } from './app-header.component';
import { AppNavComponent } from './app-nav.component';
import { SearchComponent } from './search/search.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailComponent } from './detail/detail.component';
import { ProtocolComponent } from './protocol/protocol.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InspectionTypeConfigResolver } from './core/resolvers/inspection-type-config-resolver';
import { CategoryConfigResolver } from './core/resolvers/category-config-resolver';
import { PointConfigResolver } from './core/resolvers/point-config-resolver';
import { InspectionsResolver } from './core/resolvers/inspections-resolver';
import { InspectionCategoriesResolver } from './core/resolvers/inspection-categories-resolver';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';
import { InspectionHeaderComponent } from './inspection/inspection-header.component';
import { InspectionResolver } from './core/resolvers/inspection-resolver';
import { DetailMatrixComponent } from './detail/detail-matrix.component';
import { DetailListComponent } from './detail/detail-list.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InspectionDialogComponent } from './inspection/inspection-dialog.component';
import { InspectionStylesResolver } from './core/resolvers/inspection-styles-resolver';
import { DetailResultDropdownComponent } from './detail/detail-result-dropdown.component';
import { DetailResultButtonComponent } from './detail/detail-result-button.component';
import { DetailResultRemarkComponent } from './detail/detail-result-remark.component';
import { DetailResultFieldValueComponent } from './detail/detail-result-field-value.component';
import { DetailCategoryResultComponent } from './detail/detail-category-result.component';
import { FileDialogComponent } from './file-dialog/file-dialog.component';
import { ProtocolResolver } from './core/resolvers/protocol-resolver';
import { UserResolver } from './core/resolvers/user-resolver';
import { DetailListPointComponent } from './detail/detail-list-point.component';
import { ConfirmUploadDialogComponent } from './confirm-upload-dialog/confirm-upload-dialog.component';
import { DefectDialogComponent } from './protocol/defect-dialog.component';
import { InspectionStatesResolver } from './core/resolvers/inspection-states-resolver';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { PointToCategoryMappingConfigResolver } from './core/resolvers/point-to-category-mapping-config-resolver';
import { PointToPointGroupMappingConfigResolver } from './core/resolvers/point-to-point-group-mapping-config-resolver';
import { MkdeAlertComponent } from './alert/mkde-alert.component';
import { MkdeAlertListComponent } from './alert/mkde-alert-list.component';
import { MkdeAlertHolderDirective } from './alert/mkde-alert-holder.directive';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { DetailBaseComponent } from './detail/detail-base.component';
import { DetailResultYesNoButtonComponent } from './detail/detail-result-yes-no-button.component';
import { DetailResultNumberFieldValueComponent } from './detail/detail-result-number-field-value.component';
import { NavigationComponent } from './navigation/navigation.component';
import { StatusChangeDialogComponent } from './status-change-dialog/status-change-dialog.component';
import { ConfirmOverrideDialogComponent } from './confirm-override-dialog/confirm-override-dialog.component';
import { AdministrationComponent } from './administration/administration.component';
import { AdministrationConfigTableActionComponent } from './administration/administration-config-table-action/administration-config-table-action.component';
import { FileSaverModule } from 'ngx-filesaver';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FormModule } from './form/form.module';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { BedagDatepickerI18n } from './datepicker/datepicker-i18n';
import { BedagDateParserFormatter } from './datepicker/date-parser-formatter';
import {
  MkdeDatepickerComponent,
  RenderNextToMkdeDatePickerDirective,
} from './datepicker/mkde-datepicker.component';
import { YesNoDialogComponent } from './yes-no-dialog/yes-no-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownTextComponent } from './dropdown-text/dropdown-text.component';
import { OkCancelDialogComponent } from './ok-cancel-dialog/ok-cancel-dialog.component';
import { I18nTextListPipe } from './core/pipes/i18n-text-list.pipe';
import { I18nTextPipe } from './core/pipes/i18n-text.pipe';
import { ProtocolSelectorComponent } from './protocol-selector/protocol-selector.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    resolve: {
      categoryConfig: CategoryConfigResolver,
      inspections: InspectionsResolver,
      inspectionCategories: InspectionCategoriesResolver,
    },
  },
  {
    path: 'detail/:inspectionKey',
    component: DetailComponent,
    resolve: {
      inspection: InspectionResolver,
      categoryConfig: CategoryConfigResolver,
      pointToCategoryMappingConfig: PointToCategoryMappingConfigResolver,
      pointToPointGroupMappingConfig: PointToPointGroupMappingConfigResolver,
      pointConfig: PointConfigResolver,
      inspectionCategories: InspectionCategoriesResolver,
      inspectionStyles: InspectionStylesResolver,
      inspectionStates: InspectionStatesResolver,
    },
  },
  {
    path: 'protocol/:inspectionKey',
    component: ProtocolComponent,
    resolve: {
      inspection: InspectionResolver,
      inspectionTypeConfig: InspectionTypeConfigResolver,
      categoryConfig: CategoryConfigResolver,
      inspectionCategories: InspectionCategoriesResolver,
      inspectionStyles: InspectionStylesResolver,
      inspectionStates: InspectionStatesResolver,
      pointConfig: PointConfigResolver,
      protocol: ProtocolResolver,
      user: UserResolver,
    },
  },
  {
    path: 'search',
    component: SearchComponent,
    resolve: {
      categoryConfig: CategoryConfigResolver,
    },
  },
  {
    path: 'administration',
    component: AdministrationComponent,
  },
];

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FileSaverModule,
    RouterModule.forRoot(routes, {}),
    CoreModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppNavComponent,
    NavigationComponent,
    AdministrationComponent,
    AdministrationConfigTableActionComponent,
    ConfirmUploadDialogComponent,
    DashboardComponent,
    DetailComponent,
    SearchComponent,
    ProtocolComponent,
    InspectionHeaderComponent,
    DetailBaseComponent,
    DetailMatrixComponent,
    DetailListComponent,
    DetailListPointComponent,
    DetailResultDropdownComponent,
    DetailResultButtonComponent,
    DetailResultYesNoButtonComponent,
    DetailResultNumberFieldValueComponent,
    DetailResultRemarkComponent,
    DetailResultFieldValueComponent,
    DetailCategoryResultComponent,
    I18nTextPipe,
    I18nTextListPipe,
    ConfirmDeleteDialogComponent,
    ScrollToTopComponent,
    InspectionDialogComponent,
    FileDialogComponent,
    DefectDialogComponent,
    AutofocusDirective,
    MkdeAlertComponent,
    MkdeAlertListComponent,
    MkdeAlertHolderDirective,
    AboutDialogComponent,
    StatusChangeDialogComponent,
    ConfirmOverrideDialogComponent,
    YesNoDialogComponent,
    OkCancelDialogComponent,
    MkdeDatepickerComponent,
    RenderNextToMkdeDatePickerDirective,
    DropdownTextComponent,
    ProtocolSelectorComponent,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    { provide: NgbDatepickerI18n, useClass: BedagDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: BedagDateParserFormatter },
  ],
})
export class AppModule {}
