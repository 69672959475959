import { InspectionResultAccessor } from '@core/protocol-pdf/inspection-result-accessor';
import { DefectTableParams } from '@core/protocol-pdf/protocol-defect-table-params';
import { ProtocolPdfResultDto } from './protocolPdfResultDto';

export class DefectForCategoryDto {
  public isDefectCorrectionTable: boolean;
  public isFirstPathForCategory: boolean;
  public displayInspectionDataOnTopOfProtocolPdf: boolean;
  public defectTableParams: DefectTableParams;
  public inspectionResultAccessor: InspectionResultAccessor;
  public defectsMap: Map<string, Map<string, ProtocolPdfResultDto[]>>;
  public sortedPaths: string[];
  public hasDefectsOnCategory: boolean;
}
