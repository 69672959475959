import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Helps with canceling subscriptions when a component or directive is destroyed.
 *
 * Simply have your component or directive extend this class, and use
 * <pre>
 *     .takeUntil(this.destroyed)
 * </pre>
 * on all long running subscriptions.
 */
@Injectable()
export abstract class RxjsSubscriber implements OnDestroy {
  protected readonly destroyed = new Subject();

  public ngOnDestroy() {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
