/**
 * MKDE Intermediary API
 * Application internal API, providing resources for the frontend.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Role = 'acontrolAdminB' | 'acontrolAdminK' | 'acontrolSupervisingCenter' | 'acontrolExecutionCenter' | 'acontrolInspectionCenter' | 'acontrolInspector' | 'asanClerk' | 'asanSupervisingCenter' | 'asanExecutionPersonResponsible' | 'asanAdminB' | 'asanAdminK' | 'asanMasterdataAdmin' | 'asanExternalEmployee' | 'asanAlisL' | 'asanAlisK' | 'asanAdminL' | 'asanSystemAlis' | 'acontrolHealthCheck' | 'asanExternalInspector' | 'asanCantonalChemist' | 'asanMasterdataAdminFederation' | 'asanExecutionPersonResponsibleBlv' | 'asanExternalAssignee' | 'asanSystemArcGis';

export const Role = {
    AcontrolAdminB: 'acontrolAdminB' as Role,
    AcontrolAdminK: 'acontrolAdminK' as Role,
    AcontrolSupervisingCenter: 'acontrolSupervisingCenter' as Role,
    AcontrolExecutionCenter: 'acontrolExecutionCenter' as Role,
    AcontrolInspectionCenter: 'acontrolInspectionCenter' as Role,
    AcontrolInspector: 'acontrolInspector' as Role,
    AsanClerk: 'asanClerk' as Role,
    AsanSupervisingCenter: 'asanSupervisingCenter' as Role,
    AsanExecutionPersonResponsible: 'asanExecutionPersonResponsible' as Role,
    AsanAdminB: 'asanAdminB' as Role,
    AsanAdminK: 'asanAdminK' as Role,
    AsanMasterdataAdmin: 'asanMasterdataAdmin' as Role,
    AsanExternalEmployee: 'asanExternalEmployee' as Role,
    AsanAlisL: 'asanAlisL' as Role,
    AsanAlisK: 'asanAlisK' as Role,
    AsanAdminL: 'asanAdminL' as Role,
    AsanSystemAlis: 'asanSystemAlis' as Role,
    AcontrolHealthCheck: 'acontrolHealthCheck' as Role,
    AsanExternalInspector: 'asanExternalInspector' as Role,
    AsanCantonalChemist: 'asanCantonalChemist' as Role,
    AsanMasterdataAdminFederation: 'asanMasterdataAdminFederation' as Role,
    AsanExecutionPersonResponsibleBlv: 'asanExecutionPersonResponsibleBlv' as Role,
    AsanExternalAssignee: 'asanExternalAssignee' as Role,
    AsanSystemArcGis: 'asanSystemArcGis' as Role
};

