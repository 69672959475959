<nav id="header" class="navbar navbar-expand navbar-dark bg-dark p-0 fixed-top">
  <a class="navbar-brand" routerLink="/dashboard">
    <img src="../assets/logo.png" class="applogo ms-1" alt="" />
  </a>
  <a class="navbar-brand d-xl-none" routerLink="/dashboard">
    {{ 'ACmobileShort' | translate }}
  </a>
  <a class="navbar-brand d-none d-xl-block" routerLink="/dashboard">
    {{ 'ACmobile' | translate }}
  </a>

  <ul class="navbar-nav ms-auto header-list d-none d-xl-block">
    <button type="button" class="btn btn-dark" style="cursor: unset">
      <ng-container *ngIf="connectionStateService.loggedIn">
        <span class="fas fa-user-circle fa-fw fa-lg me-2"></span>
        <span class="username"
          >{{ connectionStateService.user?.givenName }}
          {{ connectionStateService.user?.surname }}</span
        >
      </ng-container>
    </button>
  </ul>

  <ul class="navbar-nav ms-auto header-list d-none d-xl-block">
    <button type="button" class="btn btn-dark" style="cursor: unset">
      <ng-container *ngIf="isUserInTrainingMode()">
        <span class="fas fa-exclamation-triangle fa-fw fa-lg me-2"></span>
        <span class="username" translate>Verbunden mit Acontrol-Schulungsmodus!</span>
      </ng-container>
    </button>
  </ul>

  <ul class="navbar-nav ms-auto header-list">
    <div ngbDropdown placement="bottom-right">
      <button type="button" class="btn btn-dark" ngbDropdownToggle>
        <span>{{ shortLanguageName() }}</span>
      </button>
      <div class="dropdown-menu mt-1" ngbDropdownMenu>
        <a
          class="dropdown-item"
          *ngFor="let language of languages"
          href="javascript:"
          (click)="translateService.use(language)">
          {{ languageName(language) }}
        </a>
      </div>
    </div>

    <button type="button" class="btn btn-dark" (click)="connectionStateService.toggleLogin()">
      <ng-container *ngIf="connectionStateService.loggedIn">
        <span
          *ngIf="connectionStateService.onlineCheckInProgress"
          class="fas fa-spinner fa-spin fa-fw fa-lg me-2"></span>
        <span
          *ngIf="!connectionStateService.onlineCheckInProgress"
          class="fas fa-sign-out-alt fa-fw fa-lg me-2"></span>
        <span class="username" translate>Von Acontrol trennen</span>
      </ng-container>
      <ng-container *ngIf="!connectionStateService.loggedIn">
        <span
          *ngIf="connectionStateService.onlineCheckInProgress"
          class="fas fa-spinner fa-spin fa-fw fa-lg me-2"></span>
        <span
          *ngIf="!connectionStateService.onlineCheckInProgress"
          class="fas fa-sign-in-alt fa-fw fa-lg me-2"></span>
        <span class="username" translate>Mit Acontrol verbinden</span>
      </ng-container>
    </button>

    <button type="button" class="btn btn-dark" (click)="openAboutDialog()">
      <ng-container><span class="far fa-question-circle fa-fw fa-lg me-3"></span></ng-container>
    </button>
  </ul>
</nav>
