import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
})
export class YesNoDialogComponent {
  @Input()
  public title: string;

  @Input()
  public text: string;

  public constructor(public modal: NgbActiveModal) {}
}
