import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PointToCategoryMappingConfig } from '../api/model/pointToCategoryMappingConfig';
import { ClientConfigDBService } from '../db/client-config-db-service';

@Injectable()
export class PointToCategoryMappingConfigResolver implements Resolve<PointToCategoryMappingConfig> {
  public constructor(private clientConfigDbService: ClientConfigDBService) {}

  public resolve() {
    return this.clientConfigDbService.getPointToCategoryMappingConfig();
  }
}
