import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { jsPDF } from 'jspdf';
import { InspectionWithResultsDto } from '../api';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfStandardService } from './protocol-pdf-standard.service';
import { PdfDocumentCheckbox } from './pdf-document-checkbox';
import { PdfDocumentParagraph } from './pdf-document-paragraph';
import { DefectTableParams } from './protocol-defect-table-params';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { Constants } from '@core/constants';

export class ProtocolPdfAgService extends ProtocolPdfStandardService {
  protected showDocumentationCheckboxes = true;
  protected headerAddress =
    'Mönchmattweg 6, 5035 Unterentfelden; Telefon: 062 835 29 70; E-Mail: veterinaerdienst@ag.ch';

  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto) {
    const originalLanguage = this.translateService.currentLang;
    this.translateService.use(protocolPdfDto.language);
    this.pdfDocument = new jsPDF();
    let baseY = this.addPageHeader() + this.getNewLine(2, 2);
    baseY = this.addInspectionTitle(baseY, 'ProtocolPdf.HeaderReport');
    baseY = this.addInspectionHeader(protocolPdfDto.inspection, protocolPdfDto.protocol, baseY);
    baseY =
      this.addInspectionPersonInformation(protocolPdfDto.inspection, baseY, true) +
      this.getNewLine(1, 3);
    baseY = this.addInspectionResultsWithoutDefect(protocolPdfDto, baseY);
    const inspectionResults = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.defectOrFieldValue,
    });
    baseY = this.addInspectionResults(protocolPdfDto, inspectionResults, baseY);
    if (inspectionResults.size) {
      baseY += 2 * ProtocolPdfConstants.lineHeight;
      baseY = this.addDocumentationCheckboxes(baseY, this.showDocumentationCheckboxes, this.showDocumentationCheckboxes);
    }
    baseY += this.getNewLine(1);
    baseY = this.addCommentOfDepartment(
      protocolPdfDto,
      baseY,
      'ProtocolPdf.LabelCommentOfDepartmentShort'
    );
    baseY = this.addFurtherAction(protocolPdfDto.protocol, baseY);
    baseY = this.addFees(protocolPdfDto.protocol.fees, baseY);
    baseY = this.addSignatureBlock(protocolPdfDto.inspection, baseY) + this.getNewLine(2);
    baseY = this.addFurtherProcedureDetails(baseY);
    if (protocolPdfDto.protocol.furtherAction.feedback) {
      this.addInspectionResultsCorrections(
        protocolPdfDto.inspection,
        protocolPdfDto.protocol,
        inspectionResults
      );
    }
    this.addFooter(protocolPdfDto.inspection);
    this.pdfDocument.save('kontrolle-' + protocolPdfDto.inspection.inspectionId + '.pdf');
    this.translateService.use(originalLanguage);
  }

  protected addPageHeader(): number {
    const marginHeaderColumn = 150;
    const lineHeight = 4;
    const textPositionX = marginHeaderColumn - 15;
    const normalTextHeightSeparation = 1.5;
    const boldTextHeightSeparation = 2;
    let textPositionY = ProtocolPdfConstants.marginYFirstPage + boldTextHeightSeparation;
    const imageData = this.getImageData(Constants.imageAg);
    this.addImage(
      imageData,
      'jpg',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage,
      30.4,
      15.2
    );
    this.pdfDocument.setFont('helvetica', ProtocolPdfConstants.boldFontType);
    this.pdfDocument.setFontSize(13.8);
    this.pdfDocument.text('Departement', textPositionX, textPositionY);
    textPositionY = textPositionY + boldTextHeightSeparation + lineHeight;
    this.pdfDocument.text('Gesundheit und Soziales', textPositionX, textPositionY);

    this.pdfDocument.setFontSize(11.9);
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    textPositionY = textPositionY + normalTextHeightSeparation + lineHeight;
    this.pdfDocument.text('Amt für Verbraucherschutz', textPositionX, textPositionY);
    textPositionY = textPositionY + normalTextHeightSeparation + lineHeight;
    this.pdfDocument.text('Veterinärdienst', textPositionX, textPositionY);

    textPositionY = textPositionY + lineHeight;
    this.addLine(textPositionY);
    textPositionY = textPositionY + lineHeight;
    this.pdfDocument.setFontSize(8);
    this.pdfDocument.text(
      this.headerAddress,
      ProtocolPdfConstants.marginX,
      textPositionY
    );
    return 36;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    const margin2ndColumn = ProtocolPdfConstants.marginX + 70;

    const signatureBlockHeight = 11 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }

    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    const dateText = this.datePipe.transform(
      inspection.inspectionDate,
      ProtocolPdfConstants.dateFormat
    );
    this.writeText(
      dateText,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX + 17,
      baseY,
      1
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;

    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureInspectionPerson'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureResponsiblePerson'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight / 2;
    const labelRejectedWidth = 20;

    const checkBox = new PdfDocumentCheckbox();
    checkBox.maxTextWidth = labelRejectedWidth;
    checkBox.currentPositionY = baseY;
    checkBox.positionX = ProtocolPdfConstants.maxXValue - labelRejectedWidth;
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelSignatureRejected');
    this.addCheckbox(checkBox);

    baseY = this.addAdditionalInformationCheckboxes(baseY);
    return baseY;
  }

  protected addFurtherProcedureDetails(baseY: number): number {
    const signatureBlockHeight = 3 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    const pdfDocumentParagraph = new PdfDocumentParagraph();
    pdfDocumentParagraph.headerFontSize = ProtocolPdfConstants.smallFontSize;
    pdfDocumentParagraph.bodyFontSize = ProtocolPdfConstants.smallFontSize;
    pdfDocumentParagraph.positionY = baseY;
    pdfDocumentParagraph.prefix = '-';
    pdfDocumentParagraph.multiLinesPrefix = ' ';
    pdfDocumentParagraph.headerText = this.translateService.instant(
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsAg'
    );
    pdfDocumentParagraph.paragraphTexts = [
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg1'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg2'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg3'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg4'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsAg5'),
    ];
    pdfDocumentParagraph.keepParagraphInSamePage = true;
    return this.writeParagraph(pdfDocumentParagraph);
  }

  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = new DefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineAg';
    defectTableParams.addControlledArea = true;
    defectTableParams.defectCorrectionOnOddNumberedPage = true;
    defectTableParams.tvdIdOnHeader = true;
    defectTableParams.defaultTablesContentFontSize -= 1;
    return defectTableParams;
  }

  private addAdditionalInformationCheckboxes(baseY: number): number {
    baseY += this.getNewLine(5, 0);

    const checkBox = new PdfDocumentCheckbox();
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitCopy');
    checkBox.currentPositionY = baseY;
    checkBox.checkboxNumber = 1;
    this.addCheckbox(checkBox);

    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitCopyPending');
    checkBox.checkboxNumber = 2;
    this.addCheckbox(checkBox);

    checkBox.text = this.translateService.instant('ProtocolPdf.LabelAbsent') + ':';
    checkBox.checkboxNumber = 3;
    checkBox.extraSizePositionX = 16;
    this.addCheckbox(checkBox);

    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitAbsentLetterSimple');
    checkBox.checkboxNumber = 4;
    checkBox.extraSizePositionX = 22;
    this.addCheckbox(checkBox);

    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitAbsentDepositSimple');
    checkBox.checkboxNumber = 5;
    checkBox.extraSizePositionX -= 10;
    this.addCheckbox(checkBox);
    return baseY;
  }
}
