<app-navigation
  [currentPage]="'dashboard'"
  [inspectionKey]="selectedInspectionKey"></app-navigation>

<div class="page-content" id="inspections">
  <table class="table table-striped table-hover bsg-table">
    <thead>
      <tr>
        <th>
          <div class="form-check form-check-indented">
            <label class="checkbox">
              <input
                name="selectAll"
                type="checkbox"
                [disabled]="!inspections?.length"
                [(ngModel)]="selectAllChecked"
                (change)="toggleSelectAll()" />
              <span class="checkmark"></span>
            </label>
          </div>
        </th>
        <th>
          <a href (click)="sortBy('name')">
            <span translate>Name der Einheit</span>
            <span *ngIf="sortedBy === 'name'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('address')">
            <span translate>Strasse</span>
            <span *ngIf="sortedBy === 'address'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('postalZone')">
            <span translate>PLZ</span>
            <span *ngIf="sortedBy === 'postalZone'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('town')">
            <span translate>Ort</span>
            <span *ngIf="sortedBy === 'town'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectionType')">
            <span translate>Kontrolle</span>
            <span
              *ngIf="sortedBy === 'inspectionType'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectionDate')">
            <span translate>Datum</span>
            <span
              *ngIf="sortedBy === 'inspectionDate'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectionStyleName')">
            <span translate>Kontrollart</span>
            <span
              *ngIf="sortedBy === 'inspectionStyleName'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectionStatus')">
            <span translate>Status</span>
            <span
              *ngIf="sortedBy === 'inspectionStatus'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('tvdNumber')">
            <span translate>TVD-Nr.</span>
            <span *ngIf="sortedBy === 'tvdNumber'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('burNumber')">
            <span translate>BUR-Nr.</span>
            <span *ngIf="sortedBy === 'burNumber'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('cantonId')">
            <span translate>Kant. ID</span>
            <span *ngIf="sortedBy === 'cantonId'" [class]="classNames" aria-hidden="true"></span>
          </a>
        </th>
        <th>
          <a href (click)="sortBy('inspectorName')">
            <span translate>Kontrolleur</span>
            <span
              *ngIf="sortedBy === 'inspectorName'"
              [class]="classNames"
              aria-hidden="true"></span>
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let inspection of inspections"
        [class.selected-row]="inspection.isSelected"
        style="cursor: pointer"
        [ngClass]="{ highlightedRow: !inspection.isActive }">
        <td>
          <div class="form-check form-check-indented">
            <label class="checkbox">
              <input
                name="selectRow"
                type="checkbox"
                [(ngModel)]="inspection.isSelected"
                (change)="selectionChanged()" />
              <span class="checkmark"></span>
            </label>
          </div>
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.name }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.address }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.postalZone }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.town }}
        </td>
        <td
          *ngIf="inspection.inspectionType"
          routerLink="/detail/{{ inspection.inspectionKey }}"
          style="cursor: pointer">
          {{ inspection.inspectionType | i18nText }}
        </td>
        <td
          *ngIf="!inspection.inspectionType"
          routerLink="/detail/{{ inspection.inspectionKey }}"
          style="cursor: pointer">
          {{ inspection.categoryShortNames | i18nTextList }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.inspectionDate | date : 'dd.MM.yyyy' }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.inspectionStyleName }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.inspectionStatus | i18nText }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.tvdNumber }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.burNumber }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.cantonId }}
        </td>
        <td routerLink="/detail/{{ inspection.inspectionKey }}" style="cursor: pointer">
          {{ inspection.inspectorName }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div
  *ngIf="inspections?.length"
  id="actionpane"
  class="p-2"
  style="display: flex; flex-direction: row; justify-content: flex-end">
  <div
    ngbPopover="{{ 'LoginRequiredForUpload' | translate }}"
    placement="right"
    triggers="mouseenter:mouseleave"
    disablePopover="{{ connectionStateService.loggedIn }}">
    <button
      class="btn btn-primary me-2"
      (click)="openUploadInspectionsConfirmation()"
      [disabled]="!canUploadInspections() || !connectionStateService.loggedIn">
      <span class="fas fa-circle-notch fa-spin fa-fw" *ngIf="uploading"></span>
      <span class="fas fa-upload"></span>
      <span translate>Hochladen</span>
    </button>
  </div>
  <div>
    <button
      class="btn btn-primary mr2"
      (click)="openDeleteConfirmation()"
      [disabled]="!hasSelectedItems()">
      <span class="fas fa-minus-circle"></span>
      <span translate>Entfernen</span>
    </button>
  </div>
  <app-scroll-to-top class="align-right me-2"></app-scroll-to-top>
</div>
