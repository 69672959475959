import { jsPDF } from 'jspdf';
import { InspectionWithResultsDto } from '../api';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfStandardService } from './protocol-pdf-standard.service';
import { DefectTableParams } from './protocol-defect-table-params';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { Constants } from '@core/constants';

export class ProtocolPdfFlService extends ProtocolPdfStandardService {
  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto) {
    const originalLanguage = this.translateService.currentLang;
    this.translateService.use(protocolPdfDto.language);
    this.pdfDocument = new jsPDF();
    let baseY = this.addPageHeader() + this.getNewLine(2, 2);
    baseY = this.addInspectionTitle(baseY, 'ProtocolPdf.HeaderVetReport2');
    baseY = this.addInspectionPersonInformation(protocolPdfDto.inspection, baseY);
    baseY =
      this.addInspectionHeader(protocolPdfDto.inspection, protocolPdfDto.protocol, baseY) +
      this.getNewLine(1, 3);
    const inspectionResults = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.defectOrFieldValue,
    });

    baseY = this.addInspectionResultsDisplayedOnTop(protocolPdfDto, baseY);
    baseY += this.getNewLine(1, 3);

    baseY = this.addInspectionResultsWithoutDefect(protocolPdfDto, baseY);
    baseY = this.addInspectionResults(protocolPdfDto, inspectionResults, baseY);
    baseY = this.addSpaceBetweenResultsAndCheckboxes(inspectionResults.size, baseY);
    baseY += inspectionResults.size ? 10 : ProtocolPdfConstants.lineHeight;
    if (inspectionResults.size) {
      baseY = this.addDocumentationCheckboxes(baseY);
    } else {
      baseY += this.getNewLine(1);
    }
    baseY = this.addCommentOfDepartment(protocolPdfDto, baseY);
    baseY = this.addStatement(protocolPdfDto.protocol, baseY);
    baseY = this.addFurtherAction(protocolPdfDto.protocol, baseY);
    baseY = this.addFees(protocolPdfDto.protocol.fees, baseY);
    baseY = this.addSignatureBlock(protocolPdfDto.inspection, baseY) + this.getNewLine(2);
    if (inspectionResults.size) {
      baseY = this.addFurtherProcedureDetails(baseY);
      baseY += ProtocolPdfConstants.lineHeight;
    }
    if (protocolPdfDto.protocol.furtherAction.feedback) {
      this.addInspectionResultsCorrections(
        protocolPdfDto.inspection,
        protocolPdfDto.protocol,
        inspectionResults
      );
    }
    this.addFooter(protocolPdfDto.inspection);
    this.pdfDocument.save('kontrolle-' + protocolPdfDto.inspection.inspectionId + '.pdf');
    this.translateService.use(originalLanguage);
  }

  protected addPageHeader(): number {
    const imageData = this.getImageData(Constants.imageFl);
    this.addImage(
      imageData,
      'jpg',
      ProtocolPdfConstants.marginX - 1,
      ProtocolPdfConstants.marginYFirstPage - 5,
      39,
      25
    );
    return 28;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    const multiLineTextResponsiblePersonPresent = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      this.translateService.instant('ProtocolPdf.TextResponsiblePersonPresent'),
      ProtocolPdfConstants.maxLineWidth
    );
    const signatureBlockHeight =
      (multiLineTextResponsiblePersonPresent.length + 11) * ProtocolPdfConstants.lineHeight;
    const margin2ndColumn = ProtocolPdfConstants.marginX + 80;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    baseY = this.writeMultiLineText(
      multiLineTextResponsiblePersonPresent,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.marginX,
      baseY,
      0
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    const dateText = this.datePipe.transform(
      inspection.inspectionDate,
      ProtocolPdfConstants.dateFormat
    );
    this.writeText(
      dateText,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX + 17,
      baseY,
      1
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureInspectionPerson'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureResponsiblePerson'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    baseY += 5 * ProtocolPdfConstants.lineHeight;
    this.writeDottedLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginX,
      baseY
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected getTextHeaderFurtherProcedureDetails(): string {
    return this.translateService.instant('ProtocolPdf.TextHeaderFurtherProcedureDetailsFl');
  }

  protected getTextOptionFurtherProcedureDetails1(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsFl1');
  }

  protected getTextOptionFurtherProcedureDetails2(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsFl2');
  }

  protected getTextOptionFurtherProcedureDetails3(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsFl3');
  }

  protected getTextOptionFurtherProcedureDetails4(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsFl4');
  }

  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = new DefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineFl';
    defectTableParams.ktIdOnHeader = true;
    defectTableParams.defectCorrectionTitleFontSize = ProtocolPdfConstants.bigFontSize;
    defectTableParams.maxLinesRemarks = 3;
    return defectTableParams;
  }

  protected addSpaceBetweenResultsAndCheckboxes(size: number, baseY: number): number {
    baseY += size ? 10 : ProtocolPdfConstants.lineHeight;
    if (size === 0) {
      baseY += this.getNewLine(1);
    }
    return baseY;
  }
}
