import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmOverrideDialogComponent } from '../confirm-override-dialog/confirm-override-dialog.component';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { InspectionResultType } from '../core/api/model/inspectionResultType';
import { I18NText } from '../core/api/model/i18NText';

@Component({
  selector: 'app-detail-result-button',
  templateUrl: './detail-result-button.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailResultButtonComponent {
  @Input()
  public result: FlatInspectionResultDto;

  @Input()
  public readonly = false;

  @Input()
  public elementName: I18NText;

  @Input()
  public isFkp: boolean;

  @Input()
  public defectDescriptionSuggestions: I18NText[];

  @Output()
  public resultChange: EventEmitter<any> = new EventEmitter();

  public resultTypes = InspectionResultType;

  public constructor(private modalService: NgbModal, private translateService: TranslateService) {}

  public onChangeResult(): void {
    if (this.result.remark || this.result.defectDescription) {
      const modal = this.modalService.open(ConfirmOverrideDialogComponent, { size: 'sm' });
      modal.componentInstance.confirmationText = this.translateService.instant(
        'ConfirmOverrideDescription'
      );
      modal.result.then(
        () => {
          this.changeResult();
        },
        () => {}
      );
    } else {
      this.changeResult();
    }
  }

  public onChangeRemark(): void {
    this.resultChange.emit();
  }

  private changeResult(): void {
    switch (this.result.inspectionResult) {
      case InspectionResultType.NotDefined:
        this.result.inspectionResult = null;
        break;
      case null:
        this.result.inspectionResult = InspectionResultType.NotApplicable;
        break;
      case InspectionResultType.NotApplicable:
        this.result.inspectionResult = InspectionResultType.Defect;
        break;
      case InspectionResultType.Defect:
        this.result.inspectionResult = InspectionResultType.NotControlled;
        break;
      case InspectionResultType.NotControlled:
        this.result.inspectionResult = InspectionResultType.NotDefined;
        break;
    }
    this.result.remark = null;
    this.result.defectDescription = null;
    this.resultChange.emit();
  }
}
