import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-upload-dialog',
  templateUrl: './confirm-upload-dialog.component.html',
})
export class ConfirmUploadDialogComponent {
  public constructor(public modal: NgbActiveModal) {}
}
