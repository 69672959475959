import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { InspectionStyleDto } from '../api/model/inspectionStyleDto';
import { InspectionStyleDBService } from '../db/inspection-style-db-service';

@Injectable()
export class InspectionStylesResolver implements Resolve<InspectionStyleDto[]> {
  public constructor(private inspectionStylesDBService: InspectionStyleDBService) {}

  public resolve() {
    return this.inspectionStylesDBService.getInspectionStyles();
  }
}
