import { Component, OnChanges, OnInit } from '@angular/core';
import { InspectionResultMatrixDto } from '../dtos/inspectionResultMatrixDto';
import { InspectionResultMatrixColumnDto } from '../dtos/inspectionResultMatrixColumnDto';
import { InspectionResultMatrixRowDto } from '../dtos/inspectionResultMatrixRowDto';
import { InspectionResultPointDto } from '../dtos/inspectionResultPointDto';
import { InspectionResultSummaryDto } from '../dtos/inspectionResultSummaryDto';
import { InspectionResultMatrixRowPointDto } from '../dtos/inspectionResultMatrixRowPointDto';
import { InspectionResultMatrixPointGroupDto } from '../dtos/inspectionResultMatrixPointGroupDto';
import { DetailBaseComponent } from './detail-base.component';
import { I18nTextPipe } from '../core/pipes/i18n-text.pipe';
import { ActivatedRoute } from '@angular/router';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionElementDto } from '../core/api/model/inspectionElementDto';
import { InspectionResultType } from '../core/api/model/inspectionResultType';
import { InspectionResultElementType } from '../core/api/model/inspectionResultElementType';
import { PointGroupType } from '../core/api/model/pointGroupType';
import { InspectionElementFieldType } from '../core/api/model/inspectionElementFieldType';
import { ClientConfigDBService } from '../core/db/client-config-db-service';

@Component({
  selector: 'app-detail-matrix',
  templateUrl: './detail-matrix.component.html',
  styleUrls: ['./detail-matrix.component.scss', './detail.component.scss'],
})
export class DetailMatrixComponent extends DetailBaseComponent implements OnInit, OnChanges {
  public matrix: InspectionResultMatrixDto;

  public notApplicablePointGroupsDisplayed = false;

  public fieldValueTypes = InspectionElementFieldType;

  public constructor(
    i18nTextPipe: I18nTextPipe,
    route: ActivatedRoute,
    clientConfigDBService: ClientConfigDBService
  ) {
    super(i18nTextPipe, route, clientConfigDBService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.notApplicablePointGroupsDisplayed = this.notApplicableCategoriesDisplayed;
    this.buildMatrix();
  }

  public ngOnChanges(changes: any): void {
    if (
      changes.notApplicableCategoriesDisplayed &&
      changes.notApplicableCategoriesDisplayed.currentValue
    ) {
      this.notApplicablePointGroupsDisplayed = true;
    }
  }

  public categoryResultChanged(propagate: boolean) {
    const result = this.getResult(this.inspectionCategory.key);
    if (propagate) {
      this.propagateResultToChildren(this.inspectionCategory, result.inspectionResult);
      this.resetAllRowResultModels();
      this.resetAllPointGroupResultModels();
    }
    this.save.emit();
    this.handleDefects();
  }

  public columnResultChanged(column: InspectionResultMatrixColumnDto) {
    const pointGroup = this.getInspectionElement(
      this.inspectionCategory,
      column.pointGroupResult.inspectionElementKey
    );
    this.propagateResultToChildren(pointGroup, column.pointGroupResult.inspectionResult, true);
    this.resetCategoryResult(column.pointGroupResult);
    this.resetAllRowResultModels();
    this.resetAllPointGroupResultModels();
    this.handleDefects();
    this.save.emit();
  }

  public rowResultChanged(row: InspectionResultMatrixRowDto) {
    this.propagateResultsToRowPoints(row);
    this.resetCategoryResult(row.pointResultModel);
    this.resetAllPointGroupResultModels();
    this.handleDefects();
    this.save.emit();
  }

  public rowPointGroupResultChanged(pointGroup: InspectionResultMatrixPointGroupDto) {
    const pointGroupElement = this.getPointGroupElement(pointGroup);
    this.propagateResultToChildren(
      pointGroupElement,
      pointGroup.pointGroupResult.inspectionResult,
      true
    );
    this.resetCategoryResult(pointGroup.pointGroupResult);
    this.resetAllRowResultModels();
    this.handleDefects();
    this.save.emit();
  }

  public rowPointResultChanged(rowPoint: InspectionResultMatrixRowPointDto, pointNumber: string) {
    this.resetPointGroupResult(rowPoint.pointGroupKey, rowPoint.result);
    this.resetCategoryResult(rowPoint.result);
    this.resetRowResultModel(pointNumber, rowPoint.result);
    this.handleDefects();
    this.save.emit();
  }

  public rowPointResultFieldValueChanged(rowPoint: InspectionResultMatrixRowPointDto) {
    this.resetPointGroupResult(rowPoint.pointGroupKey, rowPoint.result);
    this.resetCategoryResult(rowPoint.result);
    this.handleDefects();
    this.save.emit();
  }

  public pointResultChanged(point: FlatInspectionResultDto) {
    this.resetCategoryResult(point);
    this.handleDefects();
    this.save.emit();
  }

  public pointResultFieldValueChanged() {
    this.handleDefects();
    this.save.emit();
  }

  public getColumnsToDisplay(): InspectionResultMatrixColumnDto[] {
    return this.matrix.columns.filter(
      (c) =>
        this.notApplicablePointGroupsDisplayed ||
        c.pointGroupResult.inspectionResult !== InspectionResultType.NotApplicable
    );
  }

  public getPointsToDisplay(
    row: InspectionResultMatrixRowDto
  ): InspectionResultMatrixRowPointDto[] {
    const columnsToDisplay = this.getColumnsToDisplay();
    return row.points.filter(
      (p) =>
        this.notApplicablePointGroupsDisplayed ||
        columnsToDisplay.some((c) => c.pointGroupResult.inspectionElementKey === p.pointGroupKey)
    );
  }

  public getDependingResultsForColumn(
    column: InspectionResultMatrixColumnDto
  ): FlatInspectionResultDto[] {
    const pointGroup = this.getInspectionElement(
      this.inspectionCategory,
      column.pointGroupResult.inspectionElementKey
    );
    const points = this.getAllPoints(pointGroup);
    return points.map((point) => this.getResult(point.key)).filter((result) => result != null);
  }

  public getDependingResultsForRow(row: InspectionResultMatrixRowDto): FlatInspectionResultDto[] {
    return this.getPointsToDisplay(row)
      .filter((point) => point.result)
      .map((point) => point.result);
  }

  public getPointGroupsOfRow(
    row: InspectionResultMatrixRowDto
  ): InspectionResultMatrixPointGroupDto[] {
    const displayedColumns = this.getColumnsToDisplay();
    return this.matrix.pointGroups.filter(
      (a) =>
        a.rowNumber === row.number &&
        displayedColumns.find((c) => c.pointGroupNumber === a.columnNumber)
    );
  }

  public getDependingResultsForPointGroup(
    pointGroup: InspectionResultMatrixPointGroupDto
  ): FlatInspectionResultDto[] {
    const pointGroupElement = this.getPointGroupElement(pointGroup);
    const points = this.getAllPoints(pointGroupElement);
    return points.map((point) => this.getResult(point.key)).filter((result) => result != null);
  }

  public displayRowPointTemplateColumn(): boolean {
    return (
      this.getColumnsToDisplay().length > 1 &&
      this.matrix.rows.some((row) => !row.isFieldValuePoint)
    );
  }

  public getPointsOnCategoryLevelColSpan(): number {
    return Math.max(
      1,
      this.getColumnsToDisplay().length + (this.displayRowPointTemplateColumn() ? 1 : 0)
    );
  }

  private buildMatrix(): void {
    const pointsOnCategoryLevel = this.inspectionCategory.childElements.filter(
      (childElement) => childElement.type === InspectionResultElementType.Point
    );
    const type0PointGroups = this.inspectionCategory.childElements.filter(
      (childElement) =>
        childElement.type === InspectionResultElementType.PointGroup &&
        childElement.pointGroupType === PointGroupType.Regular
    );
    const matrixRows = this.getMatrixRows(type0PointGroups);
    const matrixColumns = this.getMatrixColumns(type0PointGroups);
    const matrixPointGroups = this.getMatrixPointGroups(type0PointGroups);
    this.matrix = {
      columns: matrixColumns,
      rows: matrixRows,
      pointGroups: matrixPointGroups,
      pointsOnCategoryLevel: this.getMatrixPoints(pointsOnCategoryLevel),
      summary: this.getSummary(),
    };
  }

  private getDistinctPointNumbers(pointGroups: InspectionElementDto[]): Array<string> {
    const distinctPointNumbers = new Set<string>();
    pointGroups.forEach((pointGroup) => {
      if (pointGroup) {
        pointGroup.childElements.forEach((pointGroupChild) => {
          if (pointGroupChild.type === InspectionResultElementType.Point) {
            distinctPointNumbers.add(pointGroupChild.number);
          }
        });
      }
    });
    return Array.from(distinctPointNumbers).sort((a, b) => (a > b ? 1 : -1));
  }

  private getDistinctType1PointGroupNumbers(pointGroups: InspectionElementDto[]): Array<string> {
    const distinctPointGroupNumbers = new Set<string>();
    pointGroups.forEach((pointGroup) => {
      if (pointGroup) {
        pointGroup.childElements.forEach((pointGroupChild) => {
          if (
            pointGroupChild.type === InspectionResultElementType.PointGroup &&
            pointGroupChild.pointGroupType === PointGroupType.SubheadWithoutDataEntry
          ) {
            distinctPointGroupNumbers.add(pointGroupChild.number);
          }
        });
      }
    });
    return Array.from(distinctPointGroupNumbers).sort((a, b) => (a > b ? 1 : -1));
  }

  private getMatrixColumns(pointGroups: InspectionElementDto[]): InspectionResultMatrixColumnDto[] {
    return pointGroups.map((pointGroup) => ({
      pointGroupNumber: pointGroup.number,
      pointGroupName: pointGroup.name,
      pointGroupDescription: pointGroup.description,
      pointGroupResult: this.categoryDetail.categoryResult.results.find(
        (r) => r.inspectionElementKey === pointGroup.key
      ),
    }));
  }

  private getMatrixPointGroups(
    pointGroups: InspectionElementDto[]
  ): InspectionResultMatrixPointGroupDto[] {
    const matrixPointGroups = new Array<InspectionResultMatrixPointGroupDto>();
    const type1PointGroupNumbers = this.getDistinctType1PointGroupNumbers(pointGroups);
    type1PointGroupNumbers.forEach((row) => {
      matrixPointGroups.push.apply(
        matrixPointGroups,
        pointGroups.map((column) => ({
          columnNumber: column.number,
          columnName: column.name,
          rowNumber: row,
          rowName: row,
          pointGroupResult: this.getDefaultPointResultModel(),
        }))
      );
    });
    return matrixPointGroups;
  }

  private getMatrixRows(pointGroups: InspectionElementDto[]): InspectionResultMatrixRowDto[] {
    const distinctPointNumbers = this.getDistinctPointNumbers(pointGroups);
    const matrixRows = distinctPointNumbers.map((pointNumber) =>
      this.getMatrixRow(pointGroups, pointNumber)
    );
    const type1PointGroupNumbers = this.getDistinctType1PointGroupNumbers(pointGroups);
    type1PointGroupNumbers.forEach((pointGroupNumber) => {
      matrixRows.push.apply(
        matrixRows,
        this.getMatrixRowsForType1PointGroup(pointGroups, pointGroupNumber)
      );
    });
    return matrixRows;
  }

  private getMatrixRowsForType1PointGroup(
    pointGroups: InspectionElementDto[],
    pointGroupNumber: string
  ): InspectionResultMatrixRowDto[] {
    const matrixRows = new Array<InspectionResultMatrixRowDto>();
    let type1PointGroupDefinition: InspectionElementDto = null;
    for (let i = 0; i < pointGroups.length && type1PointGroupDefinition == null; i++) {
      type1PointGroupDefinition = pointGroups[i].childElements.find(
        (childElement) =>
          childElement.number === pointGroupNumber &&
          childElement.pointGroupType === PointGroupType.SubheadWithoutDataEntry
      );
    }
    if (type1PointGroupDefinition) {
      matrixRows.push({
        isPointGroup: true,
        description: type1PointGroupDefinition.description,
        // eslint-disable-next-line id-blacklist
        number: type1PointGroupDefinition.number,
        name: type1PointGroupDefinition.name,
        descriptionDisplayed: false,
        isFieldValuePoint: false,
        pointResultModel: null,
        points: null,
        defectDescriptionSuggestions: null,
        textFieldValueSuggestions: null,
      });
      const type1PointGroups = pointGroups.map((pointGroup) =>
        pointGroup.childElements.find(
          (childElement) =>
            childElement.number === pointGroupNumber &&
            childElement.pointGroupType === PointGroupType.SubheadWithoutDataEntry
        )
      );
      const distinctPointNumbers = this.getDistinctPointNumbers(type1PointGroups);
      distinctPointNumbers.forEach((pointNumber) => {
        matrixRows.push(
          this.getMatrixRowForType1PointGroups(pointGroups, pointGroupNumber, pointNumber)
        );
      });
    }
    return matrixRows;
  }

  private getMatrixRow(
    pointGroups: InspectionElementDto[],
    pointNumber: string
  ): InspectionResultMatrixRowDto {
    const point = this.getPointDefinition(pointGroups, pointNumber);
    const rowPoints = pointGroups.map((pointGroup) => {
      const pointInPointGroup = this.getPoint(pointGroup, pointNumber);
      let rowPoint: InspectionResultMatrixRowPointDto = null;
      if (pointInPointGroup) {
        rowPoint = {
          pointGroupKey: pointGroup.key,
          result: this.categoryDetail.categoryResult.results.find(
            (r) => r.inspectionElementKey === pointInPointGroup.key
          ),
          defectDescriptionSuggestions: this.getDefectDescriptionSuggestions(
            pointGroup.number,
            pointInPointGroup.number
          ),
          textFieldValueSuggestions: this.getTextFieldValueSuggestions(
            pointGroup.number,
            pointInPointGroup.number
          ),
          isFkp: pointInPointGroup.isFkp,
          displayRemarkOfNumberFieldValue: this.getDisplayRemarkOfNumberFieldValue(
            pointGroup.number,
            pointInPointGroup.number
          ),
        };
      } else {
        rowPoint = {
          pointGroupKey: pointGroup.key,
          result: null,
          defectDescriptionSuggestions: null,
          textFieldValueSuggestions: null,
          isFkp: pointGroup.isFkp,
          displayRemarkOfNumberFieldValue: false,
        };
      }
      return rowPoint;
    });
    return {
      isPointGroup: false,
      name: point.name,
      description: point.description,
      descriptionDisplayed: false,
      // eslint-disable-next-line id-blacklist
      number: pointNumber,
      pointResultModel: this.getDefaultPointResultModel(),
      isFieldValuePoint: point.fieldType ? true : false,
      points: rowPoints,
      defectDescriptionSuggestions: this.getDefectDescriptionSuggestions(null, pointNumber),
      textFieldValueSuggestions: this.getTextFieldValueSuggestions(null, pointNumber),
    };
  }

  private getMatrixRowForType1PointGroups(
    pointGroups: InspectionElementDto[],
    type1PointGroupNumber: string,
    pointNumber: string
  ): InspectionResultMatrixRowDto {
    const type1PointGroups = pointGroups.map((pointGroup) =>
      pointGroup.childElements.find(
        (childElement) =>
          childElement.number === type1PointGroupNumber &&
          childElement.pointGroupType === PointGroupType.SubheadWithoutDataEntry
      )
    );
    const point = this.getPointDefinition(type1PointGroups, pointNumber);
    const rowPoints = pointGroups.map((pointGroup) => {
      const type1PointGroup = pointGroup.childElements.find(
        (childElement) =>
          childElement.number === type1PointGroupNumber &&
          childElement.pointGroupType === PointGroupType.SubheadWithoutDataEntry
      );
      let rowPoint: InspectionResultMatrixRowPointDto = null;
      if (type1PointGroup) {
        const pointInPointGroup = this.getPoint(pointGroup, pointNumber);
        let defectDescriptionSuggestions: I18NText[] = null;
        let textFieldValueSuggestions: I18NText[] = null;
        if (pointInPointGroup) {
          defectDescriptionSuggestions = this.getDefectDescriptionSuggestions(
            pointGroup.number,
            pointInPointGroup.number
          );
          textFieldValueSuggestions = this.getTextFieldValueSuggestions(
            pointGroup.number,
            pointInPointGroup.number
          );
          const displayRemarkOfNumberFieldValue = this.getDisplayRemarkOfNumberFieldValue(
            pointGroup.number,
            pointInPointGroup.number
          );
          rowPoint = {
            pointGroupKey: pointGroup.key,
            result: this.categoryDetail.categoryResult.results.find(
              (r) => r.inspectionElementKey === pointInPointGroup.key
            ),
            defectDescriptionSuggestions,
            textFieldValueSuggestions,
            isFkp: pointInPointGroup.isFkp,
            displayRemarkOfNumberFieldValue,
          };
        } else {
          rowPoint = {
            pointGroupKey: pointGroup.key,
            result: null,
            defectDescriptionSuggestions,
            textFieldValueSuggestions,
            isFkp: pointGroup.isFkp,
            displayRemarkOfNumberFieldValue: false,
          };
        }
      } else {
        rowPoint = {
          pointGroupKey: pointGroup.key,
          result: null,
          defectDescriptionSuggestions: null,
          textFieldValueSuggestions: null,
          isFkp: pointGroup.isFkp,
          displayRemarkOfNumberFieldValue: false,
        };
      }

      return rowPoint;
    });
    return {
      isPointGroup: false,
      name: point.name,
      description: point.description,
      descriptionDisplayed: false,
      // eslint-disable-next-line id-blacklist
      number: pointNumber,
      pointResultModel: this.getDefaultPointResultModel(),
      isFieldValuePoint: point.fieldType ? true : false,
      points: rowPoints,
      defectDescriptionSuggestions: this.getDefectDescriptionSuggestions(null, pointNumber),
      textFieldValueSuggestions: this.getTextFieldValueSuggestions(null, pointNumber),
    };
  }

  private getMatrixPoints(points: InspectionElementDto[]): InspectionResultPointDto[] {
    return points.map((point) => ({
      pointName: point.name,
      pointDescription: point.description,
      pointDescriptionDisplayed: false,
      pointNumber: point.number,
      pointResult: this.categoryDetail.categoryResult.results.find(
        (r) => r.inspectionElementKey === point.key
      ),
      defectDescriptionSuggestions: this.getDefectDescriptionSuggestions(null, point.number),
      textFieldValueSuggestions: this.getTextFieldValueSuggestions(null, point.number),
      isFkp: point.isFkp,
      displayRemarkOfNumberFieldValue: this.getDisplayRemarkOfNumberFieldValue(null, point.number),
      previousFieldValue: null,
    }));
  }

  private getSummary(): InspectionResultSummaryDto {
    return {
      categoryDescription: this.inspectionCategory.description,
      categoryDescriptionDisplayed: false,
      categoryResult: this.categoryDetail.categoryResult.results.find(
        (r) => r.inspectionElementKey === this.inspectionCategory.key
      ),
    };
  }

  private getPointDefinition(
    pointGroups: InspectionElementDto[],
    pointNumber: string
  ): InspectionElementDto {
    let point: InspectionElementDto = null;
    let pointFound = false;
    let index = 0;
    while (!pointFound && index < pointGroups.length) {
      point = this.getPoint(pointGroups[index], pointNumber);
      if (point) {
        pointFound = true;
      }
      index++;
    }

    return point;
  }

  private getPoint(pointGroup: InspectionElementDto, pointNumber: string): InspectionElementDto {
    const pointsOfPointGroup = this.getAllPoints(pointGroup);
    return pointsOfPointGroup.find((p) => p.number === pointNumber);
  }

  private getAllPoints(pointGroup: InspectionElementDto): InspectionElementDto[] {
    let points = new Array<InspectionElementDto>();
    if (pointGroup) {
      points = pointGroup.childElements.filter(
        (pointGroupChild) => pointGroupChild.type === InspectionResultElementType.Point
      );
      const childPointGroups = pointGroup.childElements.filter(
        (pointGroupChild) => pointGroupChild.type === InspectionResultElementType.PointGroup
      );
      childPointGroups.forEach(
        (childPointGroup) => (points = points.concat(this.getAllPoints(childPointGroup)))
      );
    }
    return points;
  }

  private getDefaultPointResultModel(): FlatInspectionResultDto {
    return {
      amountAffected: null,
      complaintRemedyDateDue: null,
      defectDescription: null,
      defectRepetition: null,
      defectSeverity: null,
      fieldValue: null,
      inspectionElementKey: null,
      inspectionResult: InspectionResultType.NotDefined,
      livestockOwnerFeedbackDateDue: null,
      remark: null,
    };
  }

  private propagateResultsToRowPoints(row: InspectionResultMatrixRowDto) {
    row.points.forEach((point) => {
      if (point.result && this.isPointDisplayed(point, row)) {
        if (!point.result.fieldValue) {
          point.result.inspectionResult = row.pointResultModel.inspectionResult;
          point.result.remark = row.pointResultModel.remark;
          point.result.defectDescription = row.pointResultModel.defectDescription;
        } else {
          this.resetFieldValue(point.result);
        }
        this.resetPointGroupResult(point.pointGroupKey, point.result);
      }
    });
  }

  private resetPointGroupResult(key: string, childResult: FlatInspectionResultDto) {
    const result = this.getResult(key);
    if (
      result &&
      result.inspectionResult !== InspectionResultType.NotDefined &&
      result.inspectionResult !== childResult.inspectionResult
    ) {
      result.inspectionResult = InspectionResultType.NotDefined;
      result.remark = null;
      result.defectDescription = null;
    }
  }

  private resetCategoryResult(childResult: FlatInspectionResultDto) {
    const result = this.getResult(this.inspectionCategory.key);
    const resultChanged = this.setCategoryResultIfAllPointsAreSame(
      result,
      childResult.inspectionResult
    );
    if (resultChanged) {
      return;
    }
    if (
      result &&
      this.isResultNotApplicableOrNotControlled(result) &&
      result.inspectionResult !== childResult.inspectionResult
    ) {
      result.inspectionResult = InspectionResultType.NotDefined;
      result.remark = null;
      result.defectDescription = null;
      result.defectSeverity = null;
    }
  }

  private resetRowResultModel(pointNumber: string, result: FlatInspectionResultDto) {
    const row = this.matrix.rows.find((r) => r.number === pointNumber);
    if (result && result.inspectionResult !== row.pointResultModel.inspectionResult) {
      row.pointResultModel.inspectionResult = InspectionResultType.NotDefined;
      row.pointResultModel.remark = null;
      row.pointResultModel.defectDescription = null;
    } else if (
      result &&
      result.inspectionResult === row.pointResultModel.inspectionResult &&
      (result.remark !== row.pointResultModel.remark ||
        result.defectDescription !== row.pointResultModel.defectDescription)
    ) {
      row.pointResultModel.remark = null;
      row.pointResultModel.defectDescription = null;
    }
  }

  private resetAllRowResultModels(): void {
    this.matrix.rows.forEach((row) => {
      if (!row.isPointGroup) {
        row.pointResultModel.inspectionResult = InspectionResultType.NotDefined;
        row.pointResultModel.remark = null;
        row.pointResultModel.defectDescription = null;
      }
    });
  }

  private resetAllPointGroupResultModels(): void {
    this.matrix.pointGroups.forEach((pointGroup) => {
      pointGroup.pointGroupResult.inspectionResult = InspectionResultType.NotDefined;
      pointGroup.pointGroupResult.remark = null;
      pointGroup.pointGroupResult.defectDescription = null;
    });
  }

  private isPointDisplayed(
    point: InspectionResultMatrixRowPointDto,
    row: InspectionResultMatrixRowDto
  ) {
    return this.getPointsToDisplay(row).some(
      (p) => p.result && p.result.inspectionElementKey === point.result.inspectionElementKey
    );
  }

  private getPointGroupElement(
    pointGroup: InspectionResultMatrixPointGroupDto
  ): InspectionElementDto {
    const categoryPoints = this.getInspectionElement(
      this.inspectionCategory,
      this.inspectionCategory.key
    );
    const pointGroupElement = categoryPoints.childElements
      .find(
        (column) =>
          column.type === InspectionResultElementType.PointGroup &&
          column.number === pointGroup.columnNumber
      )
      .childElements.find(
        (r) =>
          r.type === InspectionResultElementType.PointGroup && r.number === pointGroup.rowNumber
      );
    return pointGroupElement;
  }
}
