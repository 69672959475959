<div class="alert p-2 inspection-header border border-secondary">
  <div class="inspection-header-left-img">
    <div
      ngbPopover="{{ 'Dokumente' | translate }}"
      placement="bottom"
      triggers="mouseenter:mouseleave">
      <button class="btn btn-outline-primary btn-sm btn-with-badge" (click)="openFileDialog()">
        <span class="far fa-file fa-2x me-1 ms-1"></span>
        <br />
        <span class="badge badge-lg rounded-pill bg-primary">{{ fileCount }}</span>
      </button>
    </div>
  </div>
  <div class="row outer-row">
    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="row">
        <div
          translate
          class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-3 col-xl-2">
          Name:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-9 col-xl-10">
          {{ unitSummary }}
        </div>
      </div>
    </div>
    <div *ngIf="!inspection.unitIsPerson" class="col-sm-12 col-md-12 col-lg-2">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          Kant. ID:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.unitCantonId }}
        </div>
      </div>
    </div>
    <div *ngIf="inspection.unitIsPerson" class="col-sm-12 col-md-12 col-lg-2">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          Kant. ID:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.unitCantonId }}
        </div>
      </div>
    </div>
    <div *ngIf="!inspection.unitIsPerson" class="col-sm-12 col-md-12 col-lg-2">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          TVD-Nr.:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.unitTvdId }}
        </div>
      </div>
    </div>
    <div *ngIf="inspection.unitIsPerson" class="col-sm-12 col-md-12 col-lg-3">
      <div class="text-secondary"></div>
    </div>
    <div *ngIf="!inspection.unitIsPerson" class="col-sm-12 col-md-12 col-lg-3">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          BUR-NR.:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.unitBurId }}
        </div>
      </div>
    </div>
    <div *ngIf="inspection.unitIsPerson" class="col-sm-12 col-md-12 col-lg-2">
      <div class="text-secondary"></div>
    </div>
    <div *ngIf="inspection.inspectionTypeName" class="col-sm-12 col-md-12 col-lg-5">
      <div class="row">
        <div
          translate
          class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-3 col-xl-2">
          Kontrolle:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-9 col-xl-10">
          {{ inspection.inspectionTypeName | i18nText }}
        </div>
      </div>
    </div>
    <div *ngIf="!inspection.inspectionTypeName" class="col-sm-12 col-md-12 col-lg-5">
      <div class="row">
        <div
          translate
          class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-3 col-xl-2">
          Kontrolle:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-9 col-xl-10">
          {{ inspectionCategoryShortNames | i18nTextList }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-2">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          Datum:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.inspectionDate | date : 'dd.MM.yyyy' }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-2">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          Art:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.inspectionStyle?.name | i18nText }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          Status:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.inspectionStatus.description | i18nText }}
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-12 col-lg-5">
      <div class="row">
        <div
          translate
          class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-3 col-xl-2">
          Grund:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-9 col-xl-10">
          {{ getInspectionReason(inspection?.inspectionReasonName) }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-2">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          Asan:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ inspection.caseId }}
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3 offset-sm-0 offset-lg-2">
      <div class="row">
        <div translate class="text-secondary inspection-header-col col-sm-2 col-md-2 col-lg-5">
          SPK:
        </div>
        <div class="inspection-header-col col-sm-10 col-md-10 col-lg-7">
          {{ getInspectionIsPriority() }}
        </div>
      </div>
    </div>
  </div>
</div>
