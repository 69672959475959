import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { InspectionWithResultsDto } from '@core/api';
import { ProtocolPdfZhService } from './protocol-pdf-zh.service';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolDto } from 'src/app/dtos/protocolDto';
import { ProtocolPdfResultDto } from 'src/app/dtos/protocolPdfResultDto';

export class ProtocolPdfZhPrivatePharmaciesService extends ProtocolPdfZhService {
  protected getInspectionHeaderSecondColumnTexts(
    inspection: InspectionWithResultsDto,
    extendedVersion: boolean,
    protocol: ProtocolDto
  ): Map<string, string> {
    const columnTexts = new Map<string, string>();
    columnTexts.set(this.translateService.instant('ProtocolPdf.LabelBURNr'), inspection.unitBurId);
    if (extendedVersion) {
      columnTexts.set(
        this.translateService.instant('ProtocolPdf.LabelPhone'),
        inspection.unitWorkPhones
      );
    }
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      this.getInspectionDate(inspection)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelDuration'),
      this.getInspectionDuration(protocol)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelType'),
      this.getInspectionStyleName(inspection)
    );
    return columnTexts;
  }

  protected getProtocolFileName(protocolPdfDto: ProtocolPdfDto): string {
    return `kontrolle-${protocolPdfDto.inspection.inspectionId}-tieraerztliche-privatapotheken.pdf`;
  }

  protected addSpaceBetweenResultsAndCheckboxes(_size: number, baseY: number): number {
    baseY += ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addDocumentationCheckboxes(baseY: number): number {
    return baseY;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    baseY = this.addSignatureDate(baseY, inspection);
    return this.addSignaturePersons(baseY);
  }

  protected addLabelSignatureInspectionPerson(baseY: number): number {
    baseY = super.addLabelSignatureInspectionPerson(baseY);
    baseY += ProtocolPdfConstants.lineHeight;
    const maxWidth =
      ProtocolPdfConstants.signatureBlockWidthLabel +
      ProtocolPdfConstants.signatureBlockWidthDottedLine -
      15;
    const multilineText = this.getMultiLineText(
      ProtocolPdfConstants.tinyFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant(
        'ProtocolPdf.LabelSignatureInspectionPersonDescriptionPrivatePharmaciesZh'
      ),
      maxWidth
    );
    return this.writeMultiLineText(
      multilineText,
      ProtocolPdfConstants.tinyFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX,
      baseY
    );
  }

  protected addLabelSignatureResponsiblePerson(baseY: number): number {
    baseY = super.addLabelSignatureResponsiblePerson(baseY);
    baseY += ProtocolPdfConstants.lineHeight;
    const margin2ndColumn = ProtocolPdfConstants.marginX + 80;
    const maxWidth =
      ProtocolPdfConstants.signatureBlockWidthLabel +
      ProtocolPdfConstants.signatureBlockWidthDottedLine;
    const multilineText = this.getMultiLineText(
      ProtocolPdfConstants.tinyFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant(
        'ProtocolPdf.LabelSignatureResponsiblePersonDescriptionPrivatePharmaciesZh'
      ),
      maxWidth
    );
    baseY = this.writeMultiLineText(
      multilineText,
      ProtocolPdfConstants.tinyFontSize,
      ProtocolPdfConstants.defaultFontType,
      margin2ndColumn,
      baseY
    );
    return baseY;
  }

  protected getTextOptionFurtherProcedureDetails1(): string {
    return this.translateService.instant(
      `ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh1`
    );
  }

  protected getTextOptionFurtherProcedureDetails2(): string {
    return this.translateService.instant(
      `ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh2`
    );
  }

  protected getTextOptionFurtherProcedureDetails3(): string {
    return this.translateService.instant(
      `ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh3`
    );
  }

  protected getTextOptionFurtherProcedureDetails4(): string {
    return this.translateService.instant(
      `ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh4`
    );
  }

  protected getInspectionPersonTranslationKey(): string {
    return this.translateService.instant(
      'ProtocolPdf.LabelSignatureInspectionPersonTitlePrivatePharmaciesZh'
    );
  }

  protected getInspectionResponsiblePersonTranslationKey(): string {
    return this.translateService.instant(
      'ProtocolPdf.LabelSignatureResponsiblePersonTitlePrivatePharmaciesZh'
    );
  }

  protected addFurtherProcedureDetailsAbbreviations(baseY: number): number {
    return baseY;
  }

  protected addInspectionResultsWithOnTop(protocolPdfDto: ProtocolPdfDto, baseY: number): number {
    return this.addInspectionResultsDisplayedOnTop(protocolPdfDto, baseY);
  }

  protected getPointNumber(defect: ProtocolPdfResultDto): string {
    const groupElementText = defect.groupElementNumber ? `${defect.groupElementNumber}- ` : '';
    return `${groupElementText}${defect.pointNumber}`;
  }
}
