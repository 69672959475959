<div class="modal-header bg-dark">
  <h4 class="modal-title" translate>Mangel</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"
    [appDisable]="defectForm.invalid"></button>
</div>
<div class="modal-body">
  <div class="container-fluid ms-0 ps-0 me-0 pe-0">
    <form
      column-classes="col-md-2,col-md-10"
      [formGroup]="defectForm"
      (focusout)="onDefectChange()">
      <ng-container formGroupName="result">
        <form-row column-classes="col-md-2,col-md-4">
          <form-control labelKey="Mangel.Schweregrad">
            <select
              name="defectSeverity"
              formControlName="defectSeverity"
              [appDisable]="readonly || !showDefectSeveritySelection"
              appAutofocus
              [enableAutofocus]="showDefectSeveritySelection"
              class="form-select">
              <option [ngValue]="null"></option>
              <option [ngValue]="1" translate>Geringfügig</option>
              <option [ngValue]="2" translate>Wesentlich</option>
              <option [ngValue]="3" translate>Schwerwiegend</option>
            </select>
          </form-control>
          <form-control labelKey="Mangel.RueckmeldungBis">
            <app-mkde-datepicker
              name="livestockOwnerFeedbackDateDue"
              formControlName="livestockOwnerFeedbackDateDue"
              appAutofocus
              [enableAutofocus]="!showDefectSeveritySelection"
              [appDisable]="readonly">
            </app-mkde-datepicker>
          </form-control>
        </form-row>

        <form-row column-classes="col-md-2,col-md-4">
          <form-control labelKey="Mangel.Umfang">
            <input
              amountAffected
              type="number"
              formControlName="amountAffected"
              [appDisable]="readonly" />
          </form-control>
          <form-control labelKey="Mangel.Wiederholung">
            <select
              name="defectRepetition"
              formControlName="defectRepetition"
              [appDisable]="readonly"
              class="form-select">
              <option [ngValue]="0">0</option>
              <option [ngValue]="1">1</option>
              <option [ngValue]="2">2</option>
            </select>
          </form-control>
        </form-row>

        <form-row column-classes="col-md-2,col-md-10">
          <form-control labelKey="Mangel.Beschreibung">
            <textarea
              name="defectDescription"
              type="text"
              rows="5"
              maxlength="2000"
              formControlName="defectDescription"
              [appDisable]="readonly"></textarea>
          </form-control>
        </form-row>
      </ng-container>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="modal.dismiss()"
    [appDisable]="defectForm.invalid"
    translate>
    Schliessen
  </button>
</div>
