import { Component, HostListener } from '@angular/core';
import { keysToTranslate } from 'src/app/core/util/keys-to-translate';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
})
export class ScrollToTopComponent {
  public isScrolled = document.documentElement.scrollTop !== 0 || document.body.scrollTop !== 0;

  public constructor() {
    keysToTranslate(['ScrollToTop']);
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll(event: Event): void {
    this.isScrolled = document.documentElement.scrollTop !== 0 || document.body.scrollTop !== 0;
  }

  public scrollToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
