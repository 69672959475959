import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionStateService } from './core/connection-state.service';
import { MkdeAlertHolderDirective } from './alert/mkde-alert-holder.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
})
export class AppHeaderComponent {
  public languages: string[] = ['de', 'fr'];

  private languageNames = {
    de: 'Deutsch',
    fr: 'Français',
  };

  public constructor(
    public alerts: MkdeAlertHolderDirective,
    public translateService: TranslateService,
    public connectionStateService: ConnectionStateService,
    public modalService: NgbModal
  ) {
    document.body.classList.add('close-navigation');
  }

  public shortLanguageName(): string {
    return this.translateService.currentLang.toUpperCase();
  }

  public languageName(language: string): string {
    return this.languageNames[language] || language;
  }

  public isUserInTrainingMode(): boolean {
    return (
      this.connectionStateService.loggedIn &&
      this.connectionStateService.user !== undefined &&
      this.connectionStateService.user.isTrainingMode
    );
  }

  public openAboutDialog(): void {
    this.modalService.open(AboutDialogComponent, { size: 'md' });
  }
}
