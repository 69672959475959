import { Injectable } from '@angular/core';
import { DBService } from './db-service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';

/**
 * A file is identified by the filename and the inspection key.
 * The files and the filenames (of one inspection) are stored separately.
 */
@Injectable()
export class FileDBService {
  private readonly idPrefixFile = 'file_';
  private readonly idPrefixFileNames = 'file_names_';

  public constructor(private dbService: DBService) {}

  public getFileNames(inspectionKey: string): Observable<string[]> {
    return this.dbService.getById<string[]>(this.getFileNamesId(inspectionKey));
  }

  public getFile(fileName: string, inspectionKey: string): Observable<File> {
    return this.dbService.getFileById(this.getFileId(fileName, inspectionKey));
  }

  public putFile(file: File, inspectionKey: string): Observable<string> {
    return new Observable((observer) => {
      const fileNamesId = this.getFileNamesId(inspectionKey);
      this.dbService
        .getById<string[]>(fileNamesId)
        .pipe(
          concatMap((fileNames: string[]) => {
            const fileNamesToSave = this.addFileName(fileNames, file.name);
            return this.dbService.put<string[]>(fileNamesToSave, fileNamesId);
          }),
          concatMap((successPutFileNames: string) => {
            console.log(successPutFileNames);
            return this.dbService.putFile(file, this.getFileId(file.name, inspectionKey));
          })
        )
        .subscribe(
          (successPutFile: string) => {
            console.log(successPutFile);
            observer.next(
              `File ${file.name} saved in local database. File size: ${file.size} bytes`
            );
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  public deleteFile(fileName: string, inspectionKey: string): Observable<string> {
    return new Observable((observer) => {
      const fileNamesId = this.getFileNamesId(inspectionKey);
      this.dbService
        .delete(this.getFileId(fileName, inspectionKey))
        .pipe(
          concatMap((successDeleteFile: string) => {
            console.log(successDeleteFile);
            return this.dbService.getById<string[]>(fileNamesId);
          }),
          concatMap((fileNames: string[]) => {
            const fileNamesToSave = this.removeFileName(fileNames, fileName);
            return this.dbService.put(fileNamesToSave, fileNamesId);
          })
        )
        .subscribe(
          (successPutFileNames: string) => {
            console.log(successPutFileNames);
            observer.next(`File ${fileName} deleted from local database.`);
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  public deleteFiles(inspectionKey: string): Observable<string> {
    return new Observable((observer) => {
      const fileNamesId = this.getFileNamesId(inspectionKey);
      this.dbService
        .getById<string[]>(fileNamesId)
        .pipe(
          concatMap((fileNames: string[]) => {
            if (fileNames && fileNames.length > 0) {
              const dbCallObservables = fileNames.map((fileName: string) => {
                const fileId = this.getFileId(fileName, inspectionKey);
                return this.dbService.delete(fileId);
              });
              return forkJoin(dbCallObservables).pipe(
                mergeMap((successDeleteFiles: string[]) => {
                  console.log(successDeleteFiles);
                  return this.dbService.delete(fileNamesId).pipe(
                    catchError((error: any) => {
                      observer.next(
                        `Could not delete files of inspection ${inspectionKey} from local database. Error: ${error}`
                      );
                      observer.complete();
                      return of(observer);
                    })
                  );
                })
              );
            } else if (fileNames) {
              return this.dbService.delete(fileNamesId);
            } else {
              observer.next(
                `Inspection ${inspectionKey} has no files to delete from local database.`
              );
              observer.complete();
              return of(observer);
            }
          })
        )
        .subscribe(
          (successDeleteFileNames: string) => {
            console.log(successDeleteFileNames);
            observer.next(
              `Inspection ${inspectionKey} has no files to delete from local database.`
            );
            observer.complete();
          },
          (err: any) => {
            observer.error(err);
          }
        );
    });
  }

  private addFileName(fileNames: string[], fileName: string): string[] {
    if (fileNames) {
      const existingFileName = fileNames.find((n) => n === fileName);
      if (!existingFileName) {
        fileNames.push(fileName);
      }
    } else {
      fileNames = [fileName];
    }
    return fileNames;
  }

  private removeFileName(fileNames: string[], fileName: string): string[] {
    if (fileNames) {
      const i = fileNames.indexOf(fileName, 0);
      if (i > -1) {
        fileNames.splice(i, 1);
      }
    }
    return fileNames;
  }

  private getFileId(fileName: string, inspectionKey: string): string {
    return `${this.idPrefixFile}${inspectionKey}_${fileName}`;
  }

  private getFileNamesId(inspectionKey: string): string {
    return `${this.idPrefixFileNames}${inspectionKey}`;
  }
}
