import { Component, OnInit } from '@angular/core';
import { InspectionListItemDto } from '../dtos/inspectionListItemDto';
import { ActivatedRoute, Router } from '@angular/router';
import { InspectionDBService } from '../core/db/inspection-db-service';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionStateService } from '../core/connection-state.service';
import { keysToTranslate } from 'src/app/core/util/keys-to-translate';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InspectionListSorter } from '../util/inspectionListSorter';
import { InspectionCategoryCacheService } from '../core/cache/inspection-category-cache-service';
import { forkJoin, Observable, Observer } from 'rxjs';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmUploadDialogComponent } from '../confirm-upload-dialog/confirm-upload-dialog.component';
import { InspectionUploadPreparationService } from '../core/sync/inspection-upload-preparation-service';
import { UploadInspectionDto } from '../dtos/uploadInspectionDto';
import { SyncService } from '../core/sync/sync-service';
import { MkdeAlertHolderDirective } from '../alert/mkde-alert-holder.directive';
import { CategoryConfig } from '../core/api/model/categoryConfig';
import { InspectionElementDto } from '../core/api/model/inspectionElementDto';
import { InspectionWithResultsDto } from '../core/api/model/models';
import { I18nTextPipe } from '@core/pipes/i18n-text.pipe';

@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  public inspections: InspectionListItemDto[];
  public sortedBy: string;
  public classNames;
  public selectAllChecked = false;
  public uploading = false;
  public selectedInspectionKey = '';
  private sortedAscending = true;
  private allCategories: InspectionElementDto[];
  private inspectionsWithResults: InspectionWithResultsDto[];

  public constructor(
    protected i18nTextPipe: I18nTextPipe,
    private route: ActivatedRoute,
    private alerts: MkdeAlertHolderDirective,
    private router: Router,
    private inspectionDbService: InspectionDBService,
    private translateService: TranslateService,
    public connectionStateService: ConnectionStateService,
    private modalService: NgbModal,
    private inspectionListSorter: InspectionListSorter,
    private inspectionCategoryCacheService: InspectionCategoryCacheService,
    private inspectionUploadPreparationService: InspectionUploadPreparationService,
    private syncService: SyncService
  ) {
    keysToTranslate(['LoginRequiredForUpload']);
  }

  public ngOnInit(): void {
    const categoryConfig = this.route.snapshot.data['categoryConfig'] as CategoryConfig;
    this.allCategories = this.route.snapshot.data['inspectionCategories'] as InspectionElementDto[];
    this.inspectionCategoryCacheService.setCache(categoryConfig, this.allCategories);
    this.inspectionsWithResults = this.route.snapshot.data[
      'inspections'
    ] as InspectionWithResultsDto[];
    this.inspections = this.getInspectionListItemDtos(
      this.route.snapshot.data['inspections'] as InspectionWithResultsDto[]
    );
    this.sortBy('name');
  }

  public openUploadInspectionsConfirmation(): void {
    this.connectionStateService.hasConnection().subscribe((hasConnection) => {
      if (hasConnection) {
        const modal = this.modalService.open(ConfirmUploadDialogComponent, { size: 'md' });
        const selectedInspections = this.inspectionsWithResults.filter((inspectionWithResults) =>
          this.inspections.some(
            (inspection) =>
              inspection.isSelected &&
              inspection.inspectionKey === inspectionWithResults.inspectionKey
          )
        );
        const inspectionsToUpload =
          this.inspectionUploadPreparationService.prepareInspectionsForUpload(
            selectedInspections,
            this.allCategories
          );
        modal.componentInstance.inspectionsToUpload = inspectionsToUpload;
        modal.componentInstance.inspectionCategories = this.allCategories;
        modal.result.then(
          () => this.uploadInspections(inspectionsToUpload),
          () => {}
        );
      }
    });
  }

  public canUploadInspections(): boolean {
    return this.inspections.some((inspection) => inspection.isSelected);
  }

  public showDetails(inspectionKey: string) {
    this.router.navigate(['/detail', inspectionKey]);
  }

  public toggleSelectAll(): void {
    this.inspections.forEach((inspection) => {
      inspection.isSelected = this.selectAllChecked;
    });
  }

  public selectionChanged(): void {
    const selectedInspections = this.inspections.filter((inspection) => inspection.isSelected);
    if (this.selectAllChecked && selectedInspections.length !== this.inspections.length) {
      this.selectAllChecked = false;
    } else if (!this.selectAllChecked && selectedInspections.length === this.inspections.length) {
      this.selectAllChecked = true;
    }
    if (selectedInspections.length === 1) {
      this.selectedInspectionKey = selectedInspections[0].inspectionKey;
    } else {
      this.selectedInspectionKey = null;
    }
  }

  public openDeleteConfirmation(): void {
    const modal = this.modalService.open(ConfirmDeleteDialogComponent, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal-dialog-md',
    });
    modal.componentInstance.title = this.translateService.instant(
      'Entfernen der Kontrollen bestätigen.'
    );
    modal.componentInstance.message = this.translateService.instant(
      'Wollen Sie wirklich die ausgewählten Kontrollen vollständig vom lokalen Gerät entfernen? Sofern allfällig erfasste Ergebnisse nicht ins Acontrol hochgeladen worden sind, gehen diese damit verloren.'
    );
    modal.result.then(
      () => this.deleteInspections(),
      () => {}
    );
  }

  public scrollToTop(): void {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  public hasSelectedItems(): boolean {
    return this.inspections && this.inspections.some((inspection) => inspection.isSelected);
  }

  public sortBy(field: string) {
    if (this.sortedBy === field) {
      this.sortedAscending = !this.sortedAscending;
      this.setOrderedByClassNames();
      this.inspections.reverse();
    } else {
      this.sortedBy = field;
      this.sortedAscending = true;
      this.inspections = this.inspectionListSorter.sort(this.inspections, field);
      this.setOrderedByClassNames();
    }

    return false; // do not follow the href
  }

  protected getInspectionStyleName(inspection: InspectionWithResultsDto) {
    let inspectionStyleName = '';
    if (inspection.inspectionStyle && inspection.inspectionStyle.name) {
      inspectionStyleName = this.i18nTextPipe.transform(inspection.inspectionStyle.name);
    }
    return inspectionStyleName;
  }

  private setOrderedByClassNames() {
    this.classNames = 'fas fa-chevron-';

    if (this.sortedAscending) {
      this.classNames += 'up';
    } else {
      this.classNames += 'down';
    }

    this.classNames += '  text-primary';
  }

  private deleteInspections(): void {
    const inspectionKeysToDelete = this.inspections
      .filter((inspection) => inspection.isSelected)
      .map((inspection) => inspection.inspectionKey);
    const dbCallObservables = inspectionKeysToDelete.map((inspectionKey) =>
      this.inspectionDbService.deleteInspectionWithRelatedData(inspectionKey)
    );
    forkJoin(dbCallObservables).subscribe(
      (success) => {
        console.log(success);
        this.removeInspectionsFromList(inspectionKeysToDelete);
        this.selectedInspectionKey = null;
        this.alerts.success(
          this.translateService.instant('Die Kontrollen wurden erfolgreich entfernt.')
        );
      },
      (error) => {
        console.log(error);
        this.alerts.danger(
          this.translateService.instant('Die Kontrollen konnten nicht entfernt werden.'),
          error
        );
      }
    );
  }

  private getInspectionListItemDtos(
    inspectionWithResultsDtos: InspectionWithResultsDto[]
  ): InspectionListItemDto[] {
    if (inspectionWithResultsDtos && inspectionWithResultsDtos.length) {
      return inspectionWithResultsDtos.map((inspectionWithResultsDto) =>
        this.getInspectionListItemDto(inspectionWithResultsDto)
      );
    } else {
      return new Array<InspectionListItemDto>();
    }
  }

  private getInspectionListItemDto(
    inspectionWithResultsDto: InspectionWithResultsDto
  ): InspectionListItemDto {
    const inspectionListItemDto: InspectionListItemDto = new InspectionListItemDto();
    inspectionListItemDto.inspectionKey = inspectionWithResultsDto.inspectionKey;
    inspectionListItemDto.inspectionStatus = inspectionWithResultsDto.inspectionStatus.description;
    inspectionListItemDto.inspectionDate = inspectionWithResultsDto.inspectionDate;
    inspectionListItemDto.inspectionStyleName =
      this.getInspectionStyleName(inspectionWithResultsDto);
    inspectionListItemDto.inspectionType = inspectionWithResultsDto.inspectionTypeName;
    if (
      !inspectionListItemDto.inspectionType &&
      inspectionWithResultsDto.categoryResults &&
      inspectionWithResultsDto.categoryResults.length
    ) {
      inspectionListItemDto.categoryShortNames =
        this.inspectionCategoryCacheService.getInspectionCategoryShortNames(
          inspectionWithResultsDto.categoryResults.map(
            (categoryResult) => categoryResult.categoryNumber
          )
        );
    }
    inspectionListItemDto.name = inspectionWithResultsDto.unitName;
    inspectionListItemDto.address = inspectionWithResultsDto.unitStreetName;
    if (inspectionWithResultsDto.unitStreetNumber) {
      inspectionListItemDto.address += ' ' + inspectionWithResultsDto.unitStreetNumber;
    }
    inspectionListItemDto.isActive = inspectionWithResultsDto.unitIsActive;
    if (
      inspectionWithResultsDto.unitIsActive === null ||
      inspectionWithResultsDto.unitIsActive === undefined
    ) {
      inspectionListItemDto.isActive = true;
    }
    inspectionListItemDto.postalZone = inspectionWithResultsDto.unitPostalZone;
    inspectionListItemDto.town = inspectionWithResultsDto.unitTown;
    inspectionListItemDto.inspectorName = inspectionWithResultsDto.inspectorName;
    inspectionListItemDto.tvdNumber = inspectionWithResultsDto.unitTvdId;
    inspectionListItemDto.burNumber = inspectionWithResultsDto.unitBurId;
    inspectionListItemDto.cantonId = inspectionWithResultsDto.unitCantonId;

    return inspectionListItemDto;
  }

  private uploadInspections(inspections: UploadInspectionDto[]): void {
    this.connectionStateService.hasConnection().subscribe((hasConnection) => {
      if (hasConnection) {
        this.uploading = true;
        let countSuccessful = 0;
        let countSuccessfulWithDelete = 0;
        let countFailed = 0;
        const errorMessages = new Array<string>();
        const deletedInspectionKeys = new Array<string>();
        forkJoin(
          inspections.map(
            (uploadInspectionDto) =>
              new Observable((observer: Observer<any>) => {
                this.syncService.syncUp(uploadInspectionDto.uploadInspection).subscribe(
                  (success) => {
                    countSuccessful++;
                    if (success === this.syncService.uploadOkWithDelete) {
                      countSuccessfulWithDelete++;
                      deletedInspectionKeys.push(
                        uploadInspectionDto.uploadInspection.inspectionKey
                      );
                    }
                    observer.next(null);
                    observer.complete();
                  },
                  (error) => {
                    countFailed++;
                    const inspectionDetails = this.inspections.find(
                      (inspection) =>
                        inspection.inspectionKey ===
                        uploadInspectionDto.uploadInspection.inspectionKey
                    );
                    errorMessages.push(this.getUploadErrorMessage(inspectionDetails, error));
                    observer.next(null);
                    observer.complete();
                  }
                );
              })
          )
        ).subscribe(
          () => {
            this.displayUploadSummary(
              countSuccessful,
              countSuccessfulWithDelete,
              countFailed,
              errorMessages
            );
            this.uploading = false;
            this.removeInspectionsFromList(deletedInspectionKeys);
          },
          (error) => {
            console.log(error);
            this.alerts.danger(
              this.translateService.instant('Es ist ein technischer Fehler aufgetreten.'),
              error
            );
          }
        );
      }
    });
  }

  private getUploadErrorMessage(inspectionDetails: InspectionListItemDto, error: any): string {
    let errorMessage = this.translateService.instant('UploadError', {
      name: inspectionDetails.name,
      date: new Date(inspectionDetails.inspectionDate).toLocaleDateString(
        this.translateService.currentLang
      ),
    });
    if (error.status === 400) {
      errorMessage += ' (' + this.translateErrorMessage(error) + ')';
    } else if (error.status === 401) {
      errorMessage +=
        ' (' +
        this.translateService.instant(
          'Sie sind nicht mehr mit Acontrol verbunden. Bitte melden Sie sich erneut an.'
        ) +
        ')';
    } else {
      errorMessage +=
        ' (' + this.translateService.instant('Es ist ein technischer Fehler aufgetreten.') + ')';
    }
    return errorMessage;
  }

  private translateErrorMessage(error: any): string {
    if (error.error) {
      return this.translateErrorMessage(error.error);
    } else if (error.messageDe) {
      switch (this.translateService.currentLang) {
        case 'de':
          return error.messageDe;
        case 'fr':
          return error.messageFr;
        case 'it':
          return error.messageIt;
      }
    } else {
      return error.toString();
    }
  }

  private displayUploadSummary(
    countSuccessful: number,
    countSuccessfulWithDelete: number,
    countFailed: number,
    errorMessages: string[]
  ) {
    if (!countFailed) {
      let successMessage = this.translateService.instant('UploadSuccessful');
      successMessage +=
        ' (' +
        this.translateService.instant('UploadSuccessfulCount', {
          countAll: countSuccessful,
          countDeleted: countSuccessfulWithDelete,
        }) +
        ')';
      this.alerts.success(successMessage);
    } else if (countFailed && countSuccessful) {
      let partialSuccessMessage = this.translateService.instant('UploadPartiallySuccessful');
      partialSuccessMessage +=
        ' (' +
        this.translateService.instant('UploadSuccessfulCount', {
          countAll: countSuccessful,
          countDeleted: countSuccessfulWithDelete,
        });
      partialSuccessMessage +=
        ', ' + this.translateService.instant('UploadFailedCount', { count: countFailed }) + ')';
      this.alerts.warning(partialSuccessMessage);
      errorMessages.forEach((errorMessage) => this.alerts.danger(errorMessage, null));
    } else if (countFailed) {
      let failedMessage = this.translateService.instant('UploadFailed');
      failedMessage +=
        ' (' + this.translateService.instant('UploadFailedCount', { count: countFailed }) + ')';
      this.alerts.danger(failedMessage, null);
      errorMessages.forEach((errorMessage) => this.alerts.danger(errorMessage, null));
    }
  }

  private removeInspectionsFromList(inspectionKeys: string[]) {
    inspectionKeys.forEach((inspectionKey) => {
      const index = this.inspections.findIndex(
        (inspection) => inspection.inspectionKey === inspectionKey
      );
      if (index > -1) {
        this.inspections.splice(index, 1);
      }
    });
  }
}
