import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ProtocolDto } from 'src/app/dtos/protocolDto';
import { ProtocolDBService } from '../db/protocol-db-service';

@Injectable()
export class ProtocolResolver implements Resolve<ProtocolDto> {
  public constructor(private protocolDbService: ProtocolDBService) {}

  public resolve(route: ActivatedRouteSnapshot) {
    return this.protocolDbService.getProtocol(route.paramMap.get('inspectionKey'));
  }
}
