/**
 * MKDE Intermediary API
 * Application internal API, providing resources for the frontend.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InspectionResultType = 'notDefined' | 'defect' | 'notControlled' | 'notApplicable' | 'defectInactive';

export const InspectionResultType = {
    NotDefined: 'notDefined' as InspectionResultType,
    Defect: 'defect' as InspectionResultType,
    NotControlled: 'notControlled' as InspectionResultType,
    NotApplicable: 'notApplicable' as InspectionResultType,
    DefectInactive: 'defectInactive' as InspectionResultType
};

