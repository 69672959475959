<button class="btn btn-link btn-field-value-link btn-with-badge" (click)="openDialog(fieldValue)">
  <span class="fas fa-edit fa-1-5x"></span>
  <span *ngIf="hasFieldValue()" class="badge fas fa-check"></span>
</button>

<ng-template #fieldValue>
  <div class="modal-header bg-dark">
    <h4 class="modal-title" translate>Wert erfassen</h4>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="modal.dismiss()"
      [disabled]="form.invalid"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid ms-0 ps-0 me-0 pe-0">
      <form #form="ngForm" (focusout)="!form.invalid && saveResult()">
        <form-control *ngIf="result.fieldValue.inspectionElementFieldType === fieldTypes.Text">
          <input
            name="textValue"
            appAutofocus
            [(ngModel)]="result.fieldValue.textValue"
            type="text"
            maxlength="255"
            [disabled]="readonly" />
        </form-control>
        <form-control
          *ngIf="result.fieldValue.inspectionElementFieldType === fieldTypes.Number"
          invalidKey="InvalidNumber">
          <input
            name="numberValue"
            appAutofocus
            [(ngModel)]="result.fieldValue.numberValue"
            type="number"
            [disabled]="readonly" />
        </form-control>
        <form-control *ngIf="result.fieldValue.inspectionElementFieldType === fieldTypes.YesNo">
          <select
            appAutofocus
            name="yesNoValue"
            [(ngModel)]="result.fieldValue.yesNoValue"
            [disabled]="readonly"
            class="form-select">
            <option [ngValue]="null"></option>
            <option [ngValue]="true" translate>Ja</option>
            <option [ngValue]="false" translate>Nein</option>
          </select>
        </form-control>
        <form-control *ngIf="result.fieldValue.inspectionElementFieldType === fieldTypes.Date">
          <app-mkde-datepicker
            appAutofocus
            name="dateValue"
            [(ngModel)]="result.fieldValue.dateValue"
            [disabled]="readonly"></app-mkde-datepicker>
        </form-control>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.dismiss()"
      [disabled]="form.invalid"
      translate>
      Schliessen
    </button>
  </div>
</ng-template>
