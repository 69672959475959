import {
  Component,
  forwardRef,
  ViewChild,
  Optional,
  ViewContainerRef,
  Input,
  TemplateRef,
  Directive,
  ContentChild,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ControlValueAccessor,
  Validator,
  AbstractControl,
  NgModel,
} from '@angular/forms';
import { NgbInputDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControlComponent } from '../form/form-control.component';

@Component({
  selector: 'app-mkde-datepicker',
  host: {
    class: 'form-control-component',
  },
  template: `
    <input
      #datepickerInput
      ngbDatepicker
      registerFormControlInput
      class="form-control bsg-form-control" />
    <ng-template appRenderNextToMkdeDatePicker>
      <div>
        <button
          type="button"
          class="btn btn-white {{ showTodayButton ? '' : 'rounded-end' }}"
          (click)="togglePopup()"
          [attr.aria-label]="'Select date' | translate"
          [disabled]="datepicker.disabled || isDisabled">
          <i class="far fa-calendar-alt" aria-hidden="true"></i>
        </button>
      </div>
      <div
        *ngIf="showTodayButton"
        ngbPopover="{{ 'SetToday' | translate }}"
        placement="left"
        triggers="mouseenter:mouseleave">
        <button
          type="button"
          class="btn btn-white rounded-end"
          (click)="setToday()"
          [attr.aria-label]="'SetToday' | translate"
          [disabled]="datepicker.disabled || isDisabled">
          <i class="fas fa-calendar-day" aria-hidden="true"></i>
        </button>
      </div>
    </ng-template>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MkdeDatepickerComponent),
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MkdeDatepickerComponent), multi: true },
  ],
})
export class MkdeDatepickerComponent implements ControlValueAccessor, Validator {
  @ViewChild(NgbInputDatepicker, { static: true }) public datepicker: NgbInputDatepicker;

  @ContentChild(NgModel, { static: true }) public datepickerInput: NgModel;

  @Input()
  public showTodayButton = false;

  @Input()
  public isDisabled = false;

  @Output()
  public valueChanged = new EventEmitter<NgbDateStruct>();
  // We need this state for validation (because we don't write invalid dates to the model)
  // NgbInputDatePicker represents valid dates as NgbDateStruct, invalid dates as strings, and empty dates as null
  // We expose valid dates as iso string, and empty or invalid dates as null
  public date: NgbDateStruct | string | null;

  public constructor(
    @Optional() public formControl: FormControlComponent,
    public afterMeContainer: ViewContainerRef
  ) {
    this.formControl.displayAsCheckboxLabel = false;
  }

  public togglePopup() {
    this.datepicker.toggle();
    if (this.formControl) {
      this.formControl.supressPopover = this.datepicker.isOpen();
    }
  }

  public setToday() {
    const today = new Date();
    setTimeout(() => {
      this.writeValue(today);
      this.valueChanged.emit({
        year: today.getFullYear(),
        month: today.getMonth(),
        day: today.getDate(),
      });
    }, 0);
  }

  public writeValue(obj): void {
    if (obj instanceof Date) {
      const objDate = obj as Date;
      this.date = {
        year: objDate.getFullYear(),
        month: objDate.getMonth() + 1,
        day: objDate.getDate(),
      };
    } else {
      this.date = this.fromIso(obj);
    }
    this.datepicker.writeValue(this.date);
  }

  public setDisabledState(isDisabled: boolean): void {
    return this.datepicker.setDisabledState(isDisabled);
  }

  public registerOnChange(onChange): void {
    this.datepicker.registerOnChange((date) => {
      this.date = date;
      onChange(this.toIso(this.date));
    });
  }

  public registerOnTouched(fn): void {
    this.datepicker.registerOnTouched(fn);
  }

  public validate(c: AbstractControl) {
    return this.datepicker.validate({
      value: this.date,
    } as AbstractControl);
  }

  private fromIso(iso: string | null) {
    if (iso) {
      const date = new Date(iso);
      return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
    } else {
      return null;
    }
  }

  private toIso(d: NgbDateStruct | string | null) {
    return d == null || typeof d === 'string'
      ? null
      : [d.year, d.month, d.day].map(this.leftPad).join('-');
  }

  // no, we aren't going to import an npm package for this ;-)
  private leftPad(n: number) {
    return n < 10 ? '0' + n : '' + n;
  }
}

@Directive({
  selector: '[appRenderNextToMkdeDatePicker]',
})
export class RenderNextToMkdeDatePickerDirective {
  public constructor(d: MkdeDatepickerComponent, templateRef: TemplateRef<void>) {
    d.afterMeContainer.createEmbeddedView(templateRef);
  }
}
